import React, { useState, useEffect } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import {
  approveSchool,
  removeSchool,
  editSchool,
  getCreateTokenService,
  getSchoolService,
  getAnalitycsInformation,
} from "../../services/schoolServices";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import blankImage from "../../utils/images/blank_user.png";

import { approvalStatus } from "../../utils/constants";
import SchoolDetails from "./SchoolDetails";

const SchoolList = ({
  dataList,
  updateInfo,
  handleModalOpen,
  setInformation,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleViewDetails = (item) => {
    setInformation({
      title: item.name,
      descriptionComponent: <SchoolDetails schoolInformation={item} />,
      witouthButtons: true,
    });
  };

  const handleDataInformation = (data) => {
    
    const orderedData = {
      name: data.name,
      country: data.country,
      state: data.state,
      city: data.city,
      zipCode: data.zipCode,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,

      mainPictureUrl: data.mainPictureUrl,
    };

    const newData = Object.keys(orderedData).map((key) => {
      return (
        key !== "id" &&
        key !== "schoolId" &&
        key !== "approvalStatus" && (
          <div
            key={key}
            className={`input-container w-50 ${
              key === "name" || key === "mainPictureUrl" ? "w-100" : ""
            }`}
          >
            <div className="edit-input-label">{key}</div>
            {typeof data[key] === "boolean" ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={data[key]}
                      onChange={() => handleChange(key, !data[key], data)}
                    />
                  }
                />
              </FormGroup>
            ) : key === "mainPictureUrl" ? (
              <label className="main-image-container">
                <input
                  name={key}
                  id={key}
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    handleChange(key, e.target.files[0], data);
                  }}
                />
                <img
                  id="main-image"
                  className={`${data[key] ? "" : "blank-image"}`}
                  src={data[key] ? data[key] : blankImage}
                  alt="main"
                />
              </label>
            ) : (
              <input
                className="text-input"
                name={key}
                onChange={(e) => handleChange(key, e.target.value, data)}
                placeholder={key}
                defaultValue={data[key]}
              />
            )}
          </div>
        )
      );
    });
    return <div className="edit-container">{newData}</div>;
  };

  const handleChange = (item, value, data) => {
    const newEdit = Object.assign(data);
    if (item === "mainPictureUrl") {
      if (value) {
        const file = value;
        if (file && file.size > 15 * 1024 * 1024) return false;
        let reader = new FileReader();
        // it's onload event and you forgot (parameters)
        reader.onload = function (e) {
          let image = document.getElementById("main-image");
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
        newEdit["MainPicture"] = value;
      }
    } else {
      newEdit[item] = value;
    }
  };

  const displayEditModal = (item) => {
    setInformation({
      title: "School Edit",
      descriptionComponent: handleDataInformation(item),
      acceptAction: (e) => {
        e.target.disabled = true;
        getCreateTokenService({ PermissionRequired: 2 }).then((response) =>
          editSchool(item, response.data.body)
            .then((res) => {
              updateInfo();
              if (res.data.success) {
                handleModalOpen({
                  title: `School Edit`,
                  description: `The School ${item.name} was edited`,
                });
              } else {
                handleModalOpen({
                  title: "There was an error while editing",
                  description: `Try again later`,
                });
              }
            })
            .catch((err) => {
              handleModalOpen({
                title: "There was an error while editing",
                description: `Try again later`,
              });
            })
        );
      },
    });
  };

  const handleSchoolDetails = (item) => {
    setIsLoading(true);
    getAnalitycsInformation({ id: item.id }).then((res) => {
      setIsLoading(false);
      handleViewDetails(res.data.body);
    });
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={"dashboard-list-container"}
    >
      {dataList.length === 0 && <div>No information available</div>}
      {dataList.length > 0 && (
        <ListItem className={`dashboard-list-item`}>
          <ListItemText className="list-item-text" primary={"Name"} />
          <ListItemText className="list-item-text" primary={"Country"} />
          <ListItemText className="list-item-text" primary={"State"} />

          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Approved"}
          />
          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Delete"}
            button
          />
          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Edit"}
            button
          />
        </ListItem>
      )}

      {dataList.map((item) => (
        <ListItem className={`dashboard-list-item`}>
          <ListItemText
            button
            onClick={() => handleSchoolDetails(item)}
            className="list-item-text list-item-icon"
            primary={item.name}
          />
          <ListItemText className="list-item-text" primary={item.country} />
          <ListItemText className="list-item-text" primary={item.state} />

          <ListItemText
            onClick={() => {
              handleModalOpen({
                title: "Are you sure?",
                description: `You want to ${
                  item.approvalStatus === approvalStatus.DENIED ||
                  item.approvalStatus === approvalStatus.PENDING
                    ? "Approve"
                    : "Reject"
                } ${item.name}?`,
                acceptAction: (e) => {
                  e.target.disabled = true;
                  approveSchool({
                    SchoolId: item.id,
                    Approved:
                      item.approvalStatus === approvalStatus.DENIED ||
                      item.approvalStatus === approvalStatus.PENDING,
                  })
                    .then((res) => {
                      updateInfo();

                      if (res.data.success) {
                        handleModalOpen({
                          title: `School ${
                            item.approvalStatus !== approvalStatus.APPROVED
                              ? "Approved"
                              : "Rejected"
                          }`,
                          description: `The School ${item.name} was approved`,
                        });
                      } else {
                        handleModalOpen({
                          title: "There was an error while approving",
                          description: `Try again later`,
                        });
                      }
                    })
                    .catch((err) => {
                      handleModalOpen({
                        title: "There was an error while approving",
                        description: `Try again later`,
                      });
                    });
                },
              });
            }}
            className="list-item-icon"
            primary={
              item.approvalStatus === approvalStatus.PENDING
                ? "Pending"
                : item.approvalStatus === approvalStatus.DENIED
                ? "Rejected"
                : "Approved"
            }
            button
          />

          <ListItemText
            onClick={() => {
              handleModalOpen({
                title: "Are you sure?",
                description: `You want to delete ${item.title} school?`,
                acceptAction: (e) => {
                  e.target.disabled = true;
                  removeSchool({ schoolId: item.id })
                    .then((res) => {
                      if (res.data.success) {
                        handleModalOpen({
                          title: "School Delete",
                          description: `The school ${item.title} was deleted`,
                        });
                      } else {
                        handleModalOpen({
                          title: "There was an error while deleting",
                          description: `Try again later`,
                        });
                      }
                      updateInfo();
                    })
                    .catch((err) => {
                      handleModalOpen({
                        title: "There was an error while deleting",
                        description: `Try again later`,
                      });
                    });
                },
              });
            }}
            className="list-item-icon"
            primary={"Delete"}
            button
          />

          <ListItemText
            onClick={() => {
              setIsLoading(true);
              getSchoolService({ id: item.id })
                .then((res) => {
                  setIsLoading(false);
                  displayEditModal(res.data.body);
                })
                .catch(() => {
                  handleModalOpen({
                    title: "Error",
                    description: `Try again later`,
                  });
                  setIsLoading(false);
                });
            }}
            className="list-item-icon"
            primary={"Edit"}
            button
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SchoolList;
