import React, { useState, useEffect, useCallback } from "react";

import {
  getCompetency,
  chapterAddQuestion,
  chapterAddPreQuestion,
  removeChapterQuestion,
  removeChapterPreQuestion,
  getQuestions,
  getPreQuestions,
  createAnswer,
  getAllAnswers,
  deleteAnswer,
  getAnswer,
  editAnswer,
} from "../../services/chapterServices";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";

const QuestionResponses = ({ chapterId, handleModalOpen }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [competencies, setCompetencies] = useState([]);
  const [responses, setResponses] = useState({});
  const [allAnswer, setAllAnswer] = useState([]);
  const [answers, setAnswers] = useState({});
  const [answersToSave, setAnswersToSave] = useState({});

  useEffect(() => {
    getCompetency({})
      .then((res) => {
        const competencies = res.data.body;
        const newResponses = Object.assign(responses, {});
        const newAnswers = Object.assign(answers, {});
        competencies.forEach((item) => {
          newResponses[item.name] = { question: "", competencyId: item.id };
          newAnswers[item.name] = { text: "", id: "", value: 0 };
        });
        getAllAnswers({ chapterId, isBaseAnswer: chapterId ? false : true }).then((res) => {
          console.log('chapterId inside QuestionResponses: ', chapterId)
          const answersNotFiltered = res.data.body;
          let answers;
          if (chapterId) {
            answers = answersNotFiltered.filter(answer => answer.chapterId === chapterId);
          } else {
            answers = answersNotFiltered;
          }
          const competencyArray = {};
          competencies.forEach((competency) => {
            const competencyAnwers = {};
            answers.forEach((answer) => {
              if (answer.competencyId === competency.id) {
                competencyAnwers[answer.value] = {
                  text: answer.text,
                  value: answer.value,
                  id: answer.id,
                };
              }
            });
            competencyArray[competency.name] = competencyAnwers;
          });

          setAllAnswer(competencyArray);
          setAnswers(newAnswers);
        });
        // debugger
        if (chapterId) {
          getQuestions({ id: chapterId }).then((response) => {
            const chapterQuestions = response.data.body;
            chapterQuestions.forEach((chapter) => {
              competencies.forEach((competency) => {
                if (chapter.competencyId === competency.id) {
                  newResponses[competency.name] = {
                    question: chapter.question,
                    competencyId: competency.id,
                  };
                }
              });
            });
            setIsLoading(false);
          });
        } else {
          getPreQuestions().then((response) => {
            const chapterQuestions = response.data.body;
            chapterQuestions.forEach((chapter) => {
              competencies.forEach((competency) => {
                if (chapter.competencyId === competency.id) {
                  newResponses[competency.name] = {
                    question: chapter.question,
                    competencyId: competency.id,
                  };
                }
              });
            });
            setIsLoading(false);
          });
        }

        setCompetencies(res.data.body);
        setResponses(newResponses);
      })
      .catch(() => setIsLoading(false));
  }, [chapterId]);

  const handleChange = (value, name, id) => {
    const newResponses = Object.assign(responses, {
      [name]: { question: value, competencyId: id },
    });
    setResponses(newResponses);
  };

  const handleAnswerChange = (text, name, competencyId, value, exist, competencyName, id) => {
    const newResponses = Object.assign(answers, {
      [name]: { [value]: { text, competencyId, value } },
    });
    setAnswers(newResponses);

    let competencyIdObject = {};

    if (answersToSave && answersToSave[competencyId]) {
      competencyIdObject = { ...answersToSave[competencyId] }
    }
     
    competencyIdObject[value] = {
      competencyId,
      id,
      value,
      competencyName,
      exist,
      saved: false,
      text
    }

    let newAnswersToSave = { ...answersToSave };
    newAnswersToSave[competencyId] = competencyIdObject;

    setAnswersToSave(newAnswersToSave)
    //debounceHandler(exist, competencyName, value, id, competencyId)
    //debouncedAddAnswer(exist, competencyName, value, id, competencyId, 2000);
  };

  const debounceHandler = useCallback(

    _.debounce((exist, competencyName, value, id, competencyId) => {
      console.log('exist, competencyName, value, id, competencyId', exist, competencyName, value, id, competencyId)
      addAnswer(exist, competencyName, value, id, competencyId);
    }, 2000),
    []
  );

  const debouncedAddAnswer = (exist, competencyName, value, id, competencyId) => {
    console.log('in debouncedAddAnswer')
    _.debounce(addAnswer(exist, competencyName, value, id, competencyId), 2000)
  }

  const addAnswer = (exist, competencyName, value, id, competencyId, text = null) => {
    console.log('in addAnswer')
    console.log('exist, competencyName, value, id, competencyId', exist, competencyName, value, id, competencyId)
    if (exist) {
      editAnswer({
        id,
        chapterId,
        competencyId,
        text: text ? text : answers[competencyName][value].text,
        value,
        isBaseAnswer: chapterId ? false : true,
      })
        .then((res) => {
          console.log(res)
          if (res.data.success) {
            handleModalOpen({
              title: "Edit Answer",
              description: `Answer edited successfully!`,
            });
          } else {
            handleModalOpen({
              title: "Edit Answer",
              description: `Something went wrong, try again later!`,
            });
          }
        })
        .catch((err) => {
          console.log(err)
          handleModalOpen({
            title: "Edit Answer",
            description: `Something went wrong, try again later!`,
          });
        });
    } else {
      createAnswer({
        chapterId,
        competencyId,
        text: text ? text : answers[competencyName][value].text,
        value,
        isBaseAnswer: chapterId ? false : true,
      })
        .then((res) => {
          if (res.data.success) {
            handleModalOpen({
              title: "Create Answer",
              description: `Answer Created successfully!`,
            });
          } else {
            handleModalOpen({
              title: "Create Answer",
              description: `Something went wrong, try again later!`,
            });
          }
        })
        .catch((err) => {
          handleModalOpen({
            title: "Create Answer",
            description: `Something went wrong, try again later!`,
          });
        });
    }
  };
  const renderQuestions = () => {
    const arrayResponses = Object.keys(responses);
    return arrayResponses.map((item) => {
      return (
        <div
          key={item}
          className={`input-container question-input-container w-100`}
        >
          <div className="question-title">{item}</div>
          <div className="questions-container w-100">
            <div className="edit-input-label">question</div>
            <input
              className="text-input"
              name={`${item}`}
              onChange={(e) => {
                handleChange(
                  e.target.value,
                  item,
                  responses[item].competencyId
                );
              }}
              defaultValue={responses[item].question}
            />
          </div>
          {/* <span>Strongly Disagree</span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="2" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span>Disagree</span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="3" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span>Neutral</span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="4" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span>Agree</span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="5" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span>Strongly Agree</span> */}
          <div className="questions-container">
            <div className="edit-input-label"> Answer 1</div>
            <div className="answer-input-button-container">
              <input
                className="text-input"
                name={`${item}`}
                placeholder="Strongly Disagree"
                onChange={(e) => {
                  handleAnswerChange(
                    e.target.value,
                    item,
                    responses[item].competencyId,
                    1,
                    allAnswer[item] && allAnswer[item][1] !== undefined,
                    item,
                    allAnswer[item] &&
                    allAnswer[item][1] &&
                    allAnswer[item][1].id
                  );
                }}
                defaultValue={
                  allAnswer[item] && allAnswer[item][1]
                    ? allAnswer[item][1].text
                    : ""
                }
              />
            </div>
          </div>
          <div className="questions-container">
            <div className="edit-input-label"> Answer 2</div>
            <div className="answer-input-button-container">
              <input
                className="text-input"
                name={`${item}`}
                placeholder="Disagree"
                onChange={(e) => {
                  handleAnswerChange(
                    e.target.value,
                    item,
                    responses[item].competencyId,
                    2,
                    allAnswer[item] && allAnswer[item][2] !== undefined,
                    item,
                    allAnswer[item] &&
                    allAnswer[item][2] &&
                    allAnswer[item][2].id
                  );
                }}
                defaultValue={
                  allAnswer[item] && allAnswer[item][2]
                    ? allAnswer[item][2].text
                    : ""
                }
              />
            </div>
          </div>
          <div className="questions-container">
            <div className="edit-input-label"> Answer 3</div>
            <div className="answer-input-button-container">
              <input
                className="text-input"
                name={`${item}`}
                placeholder="Neutral"
                onChange={(e) => {
                  handleAnswerChange(
                    e.target.value,
                    item,
                    responses[item].competencyId,
                    3,
                    allAnswer[item] && allAnswer[item][3] !== undefined,
                    item,
                    allAnswer[item] &&
                    allAnswer[item][3] &&
                    allAnswer[item][3].id
                  );
                }}
                defaultValue={
                  allAnswer[item] && allAnswer[item][3]
                    ? allAnswer[item][3].text
                    : ""
                }
              />
            </div>
          </div>
          <div className="questions-container">
            <div className="edit-input-label"> Answer 4</div>
            <div className="answer-input-button-container">
              <input
                className="text-input"
                name={`${item}`}
                placeholder="Agree"
                onChange={(e) => {
                  handleAnswerChange(
                    e.target.value,
                    item,
                    responses[item].competencyId,
                    4,
                    allAnswer[item] && allAnswer[item][4] !== undefined,
                    item,
                    allAnswer[item] &&
                    allAnswer[item][4] &&
                    allAnswer[item][4].id
                  );
                }}
                defaultValue={
                  allAnswer[item] && allAnswer[item][4]
                    ? allAnswer[item][4].text
                    : ""
                }
              />
            </div>
          </div>
          <div className="questions-container">
            <div className="edit-input-label"> Answer 5</div>
            <div className="answer-input-button-container">
              <input
                className="text-input"
                name={`${item}`}
                placeholder="Strongly Agree"
                onChange={(e) => {
                  handleAnswerChange(
                    e.target.value,
                    item,
                    responses[item].competencyId,
                    5,
                    allAnswer[item] && allAnswer[item][5] !== undefined,
                    item,
                    allAnswer[item] &&
                    allAnswer[item][5] &&
                    allAnswer[item][5].id
                  );
                }}
                defaultValue={
                  allAnswer[item] && allAnswer[item][5]
                    ? allAnswer[item][5].text
                    : ""
                }
              />
            </div>
          </div>
        </div>
      );
    });
  };

  const saveQuestions = async () => {

    Object.keys(answersToSave).forEach(async (competency) => {
      Object.keys(answersToSave[competency]).forEach(async (value) => {
        const answer = { ...answersToSave[competency][value]};
        if (!answer.saved) {
          try {
            addAnswer(
              answer.exist, 
              answer.competencyName, 
              answer.value, 
              answer.id, 
              answer.competencyId, 
              answer.text)
          }
          catch (e) {
            console.log(e)
          }
        }
      })
    })

    const generateQuestionBody = [];
    const arrayResponses = Object.keys(responses);
    const competenciesToRemove = [];
    competencies.forEach((item) => competenciesToRemove.push(item.id));
    arrayResponses.forEach((item) => {
      generateQuestionBody.push(responses[item]);
    });
    if (chapterId) {
      removeChapterQuestion({
        chapterId,
        competenciesIds: competenciesToRemove,
      })
        .then((response) => {
          chapterAddQuestion({
            chapterId,
            questions: generateQuestionBody,
          })
            .then((res) => {
              if (res.data.success) {
                handleModalOpen({
                  title: "Save Questions",
                  description: `Questions saved successfully!`,
                });
              } else {
                handleModalOpen({
                  title: "Save Questions",
                  description: `Something went wrong, try again later!`,
                });
              }
            })
            .catch((err) => {
              handleModalOpen({
                title: "Save Questions",
                description: `Something went wrong, try again later!`,
              });
            });
        })
        .catch((err) => {
          handleModalOpen({
            title: "Save Questions",
            description: `Something went wrong, try again later!`,
          });
        });
    } else {
      removeChapterPreQuestion({ competenciesIds: competenciesToRemove })
        .then((response) => {
          chapterAddPreQuestion({
            questions: generateQuestionBody,
          })
            .then((res) => {
              if (res.data.success) {
                handleModalOpen({
                  title: "Save Questions",
                  description: `Questions saved successfully!`,
                });
              } else {
                handleModalOpen({
                  title: "Save Questions",
                  description: `Something went wrong, try again later!`,
                });
              }
            })
            .catch((err) => {
              handleModalOpen({
                title: "Save Questions",
                description: `Something went wrong, try again later!`,
              });
            });
        })
        .catch((err) => {
          handleModalOpen({
            title: "Save Questions",
            description: `Something went wrong, try again later!`,
          });
        });
    }
  };
  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
      <div>
        {renderQuestions()}
        <div className="save-question-button" onClick={saveQuestions}>
          Save
      </div>
      </div>
    );
};

export default QuestionResponses;
