import axios from "./axiosInstance";
import queryString from "query-string";

// /Create/Chapter
// Form:
// string Name
// string Description
// int? Order
// bool Activate
// IFormFile Picture

export const createChapter = (params) => {
  const formData = new FormData();

  for (let key in params) {
    if (key !== "chapterTestTemplates") {
      formData.append(
        key,
        key === "chapterTestTemplates"
          ? JSON.stringify(params[key])
          : params[key]
      );
    }
  }
  params.chapterTestTemplates.forEach((item, index) => {
    formData.append(
      `chapterTestTemplates[${index}].CompetencyId`,
      item.CompetencyId
    );
    formData.append(
      `chapterTestTemplates[${index}].TemplateId`,
      item.TemplateId
    );
  });

  return axios.post("/Chapter/Create", formData);
};

export const editChapter = (params) => {
  const formData = new FormData();

  for (let key in params) {
    if (
      key !== "ChapterTestTemplatesToAdd" &&
      key !== "ChapterTestTemplatesToRemoveIds"
    ) {
      formData.append(key, params[key]);
    }
  }
  params.ChapterTestTemplatesToAdd.forEach((item, index) => {
    formData.append(
      `ChapterTestTemplatesToAdd[${index}].CompetencyId`,
      item.CompetencyId
    );
    formData.append(
      `ChapterTestTemplatesToAdd[${index}].TemplateId`,
      item.TemplateId
    );
  });

  params.ChapterTestTemplatesToRemoveIds.forEach((item, index) => {
    formData.append(`ChapterTestTemplatesToRemoveIds[${index}]`, item);
  });

  return axios.post("/Chapter/Edit", formData);
};

export const getCompetency = (params) => {
  return axios.post("/Competency/GetAll", params);
};

export const getAllChapters = (params) => axios.post("/Chapter/GetAll", params);

export const getChapter = (params) =>
  axios.post(`/Chapter/Get?${queryString.stringify(params)}`);

export const deleteChapter = (params) =>
  axios.post(`/Chapter/Delete?${queryString.stringify(params)}`);

export const orderChapters = (params) => {
  return axios.post("/Chapter/SetOrder", params);
};

export const forceStartChapter = (params) =>
  axios.post("/Chapter/ForceStartChapter", params);

export const chapterAddQuestion = (params) =>
  axios.post("/Chapter/AddQuestions", params);

export const getQuestions = (params) =>
  axios.post(`/Chapter/GetQuestions?${queryString.stringify(params)}`);

export const chapterAddPreQuestion = (params) =>
  axios.post("/PreChapterQuestions/SetPreChapterQuestions", params);

export const removeChapterPreQuestion = (params) =>
  axios.post("/PreChapterQuestions/RemovePreChapterQuestions", params);

export const removeChapterQuestion = (params) =>
  axios.post("/Chapter/RemoveQuestions", params);

export const getPreQuestions = (params) =>
  axios.post("/PreChapterQuestions/GetPreChapterQuestions", params);

export const getAnswers = (params) => 
  axios.post("Answer/GetAll", params);
// export const getCounselor = (params) =>
//   axios.post(`counselor/Get?${queryString.stringify(params)}`);



export const createAnswer = (params) => axios.post("/Answer/Create", params);

export const editAnswer = (params) => axios.post("/Answer/Edit", params);

export const getAllAnswers = (params) => axios.post("/Answer/GetAll", params);

export const getAnswer = (params) =>
  axios.post(`/Answer/Get?${queryString.stringify(params)}`);

export const deleteAnswer = (params) =>
  axios.post(`/Answer/Delete?${queryString.stringify(params)}`);

export const getStudentChapterProgress = (params) =>
  axios.post(`/Student/GetStudentChapterProgress?${queryString.stringify(params)}`);


 


// /Answer/GetAll
// Body:
// string TextContains
// double? ValueFrom
// double? ValueTo
// DateTime? From
// DateTime? To
// bool? IsBaseAnswer
// Guid? ChapterId
// Guid? CompetencyId
