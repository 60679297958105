import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loginAttempt,
  registerAttempt,
  setError,
  workShopRegisterAttempt,
} from "../../redux/actions/auth";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./Register.css";
import { roles } from "../../utils/constants";
import Select from "react-select";

import MobileLogo from "../../utils/images/BE-Texture-White2-1.png";
import { isMobile } from "react-device-detect";

const Schema1 = Yup.object().shape({
  Password: Yup.string().required("You must add a password!"),
  ConfirmPassword: Yup.string()
    .required("You must confirm your password!")
    .oneOf([Yup.ref("Password"), null], "Does not match with Email!"),
  UserName: Yup.string().required("You must add your username!!"),
});

const initialValues1 = {
  UserName: "",
  Password: "",
  ConfirmPassword: "",
};

const Schema = Yup.object().shape({
  Email: Yup.string().email("Email invalid!").required("You must add a email!"),
  Password: Yup.string().required("You must add a password!"),
  ConfirmPassword: Yup.string()
    .required("You must confirm your password!")
    .oneOf([Yup.ref("Password"), null], "Does not match with Password!"),
  UserName: Yup.string().required("You must add your username!!"),
  Role: Yup.number()
    .min(1, "You must select a role!")
    .required("You must select a role!"),
});

const initialValues = {
  Email: "",
  //ConfirmEmail: "",
  Password: "",
  //ConfirmPassword: "",
  //  Country: "",
  //  State: "",
  //  City: "",
  //  AddressLine1: "",
  // // AddressLine2: "",
  //  ZipCode: "",
  ConfirmPassword: "",
  UserName: "",
  //  FirstName: "",
  //  LastName: "",
  //  Phone: "",
  Role: 1,
  //  ProfilePicture: null
};

const rolLabelConstructor = (rol) => {
  const roles = {
    STUDENT: "Student",
    SCHOOL_COUNSELOR: "School Counselor",
    SCHOOL_ADMINISTRATOR: "School Admin",
    SUPER_ADMIN: "Super Admin",
  };
  return roles[rol];
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

class Register extends Component {
  state = {
    roles: null,
  };

  handleRegister = (values) => {
    if (values.ProfilePicture !== null) {
      if(this.props.isWorkshop){
        this.props.workShopRegisterAttempt(values, this.props.token, this.props.onRegister)
      } else {
        this.props.registerAttempt(values);
      }
    } else {
      this.setState({ imageError: true });
    }
  };



  handleCreateRoles = () => {
    const options = [];
    Object.keys(roles).forEach((item, index) => {
      if (item !== "SUPER_ADMIN") {
        options.push({ value: index + 1, label: rolLabelConstructor(item) });
      }
    });
    this.setState({ roles: options });
  };

  componentDidMount() {
    this.handleCreateRoles();
  }

  handleInputs = (values, errors, touched, handleChange, handleBlur) => {
    const valuesArray = Object.keys(
      this.props.isWorkshop ? initialValues1 : initialValues
    );
    return valuesArray.map(
      (item) =>
        item !== "Role" &&
        item !== "ProfilePicture" && (
          <div
            key={item}
            className={
              isMobile
                ? "register-input-container-mobile"
                : "register-input-container"
            }
          >
            <input
              className="register-text-input"
              name={`${item}`}
              onChange={(e) => {
                handleChange(e);
                this.props.setError(null);
              }}
              onBlur={handleBlur}
              type={
                item === "Password"
                  ? "password"
                  : item === "ConfirmPassword"
                  ? "password"
                  : "text"
              }
              value={values[item]}
              placeholder={
                item === "Email"
                  ? "E-mail"
                  : item === "UserName"
                  ? "Username"
                  : item === "ConfirmPassword"
                  ? "Confirm Password"
                  : "Password"
              }
            />
            <div className="error-span">
              {this.props.login.error && this.props.login.error.type === item
                ? this.props.login.error.message
                : errors[item] && touched[item] && errors[item]}
            </div>
          </div>
        )
    );
  };

  renderDesktop = () => {
    return (
      <Formik
        initialValues={this.props.isWorkshop ? initialValues1 : initialValues}
        validationSchema={this.props.isWorkshop ? Schema1 : Schema}
        onSubmit={this.handleRegister}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="register-form" onSubmit={handleSubmit}>
            <div className="register-content-container">
              <div className="register-left-container"></div>

              <div className="register-rigth-container">
                {this.props.login.isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <>
                    <div className="register-title-container">
                      <div className="register-title">Let’s start!</div>
                      <p className="register-paragraph">
                        Create a Username and Password to sign up
                      </p>
                    </div>

                    {this.handleInputs(
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur
                    )}
                    {/* <div className="input-container select-container">
                      Role
                      <Select options={this.state.roles} onChange={item => setFieldValue("Role", item.value)}/>
                      {errors.Role && touched.Role && errors.Role}
                    </div>
                    <input name="ProfilePicture " id="ProfilePicture " accept="image/*" type="file" onChange={(e) => {
                      this.setState({imageError: false})
                      setFieldValue('ProfilePicture', e.target.files[0]);
                    }}/>
                */}
                    {/* Choose role hidden, role by default = 1 */}
                    {!this.props.isWorkshop && false && (
                      <div className="register-input-container">
                        <Select
                          styles={customStyles}
                          className="register-select"
                          placeholder="I'm a"
                          options={this.state.roles}
                          onChange={(item) => setFieldValue("Role", item.value)}
                        />
                        <div className="error-span">
                          {errors.Role && touched.Role && errors.Role}
                        </div>
                      </div>
                    )}

                    {this.props.login.error &&
                      this.props.login.error.type !== "Username" &&
                      this.props.login.error.type !== "Password" &&
                      this.props.login.error.type !== "Email" && (
                        <div className="error-container">
                          <div className="error-span">
                            {this.props.login.error.message}
                          </div>
                        </div>
                      )}

                    <div className="register-input-container">
                      <button className="register-submit-button" type="submit">
                        Sign up
                      </button>
                    </div>

                    {!this.props.isWorkshop && (
                      <p className="register-first-time">
                        Already a User?
                        <Link className="sign-up" to="/login">
                          Log in
                        </Link>
                      </p>
                    )}

                    <p className="register-first-time terms-and-conditions">
                      By signing up you agree all the
                      <span className="sign-up">Terms & Conditions</span>
                    </p>
                  </>
                )}
              </div>
            </div>
            <Link className="home-button" to="/"></Link>
          </form>
        )}
      </Formik>
    );
  };

  renderMobile = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={this.handleRegister}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="register-form" onSubmit={handleSubmit}>
            <div className="register-content-container">
              <div className="register-mobile-container">
                {this.props.login.isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <>
                    <img className="mobile-logo" src={MobileLogo} alt="Logo" />
                    <div className="register-title-container-mobile">
                      <div className="register-title">Let’s start!</div>
                      <p className="register-paragraph">
                        Create a Username and Password to sign up
                      </p>
                    </div>

                    {this.handleInputs(
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur
                    )}
                    {/* <div className="input-container select-container">
                      Role
                      <Select options={this.state.roles} onChange={item => setFieldValue("Role", item.value)}/>
                      {errors.Role && touched.Role && errors.Role}
                    </div>
                    <input name="ProfilePicture " id="ProfilePicture " accept="image/*" type="file" onChange={(e) => {
                      this.setState({imageError: false})
                      setFieldValue('ProfilePicture', e.target.files[0]);
                    }}/>
                */}
                    <div className="register-input-container-mobile">
                      <Select
                        styles={customStyles}
                        className="register-select"
                        placeholder="I'm a"
                        options={this.state.roles}
                        onChange={(item) => setFieldValue("Role", item.value)}
                      />
                      <div className="error-span">
                        {errors.Role && touched.Role && errors.Role}
                      </div>
                    </div>

                    {this.props.login.error &&
                      this.props.login.error.type !== "Username" &&
                      this.props.login.error.type !== "Password" &&
                      this.props.login.error.type !== "Email" && (
                        <div className="error-container">
                          <div className="error-span">
                            {this.props.login.error.message}
                          </div>
                        </div>
                      )}

                    <div className="register-input-container-mobile">
                      <button className="register-submit-button" type="submit">
                        Sign up
                      </button>
                    </div>

                    <p className="register-first-time register-first-time-mobile">
                      Already a User?
                      <Link className="sign-up" to="/login">
                        Log in
                      </Link>
                    </p>

                    <p className="register-first-time register-first-time-mobile terms-and-conditions">
                      By signing up you agree all the
                      <span className="sign-up">Terms & Conditions</span>
                    </p>
                  </>
                )}
              </div>
            </div>
            <Link className="home-button" to="/"></Link>
          </form>
        )}
      </Formik>
    );
  };

  render() {
    if (isMobile) {
      return this.renderMobile();
    } else {
      return this.renderDesktop();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
    login: state.loginReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginAttempt: (user) => {
      dispatch(loginAttempt(user));
    },
    registerAttempt: (user) => {
      dispatch(registerAttempt(user));
    },
    workShopRegisterAttempt: (user, token, callback) => {
      dispatch(workShopRegisterAttempt(user, token, callback));
    },
    setError: (error) => {
      dispatch(setError(error));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
