import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./Profile.css";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMobile } from "react-device-detect";
import blankImage from "../../utils/images/blank_user.png";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Modal from "@material-ui/core/Modal";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {
  editStudent,
  editCounselor,
  editSchoolAdmin,
} from "../../services/schoolServices";
import { editUser, getMyData } from "../../services/securityServices";

const token = localStorage.getItem("token");

const Schema = Yup.object().shape({
  // Country: Yup.string().required("You must add a country!"),
  // State: Yup.string().required("You must add a state!"),
  // City: Yup.string().required("You must add a city!"),
  // AddressLine1: Yup.string().required("You must add a addressLine1!"),
  // AddressLine2: Yup.string().required("You must add an addressLine2!"),
  // ZipCode: Yup.string().required("You must add a ZipCode!"),
  // UserName: Yup.string().required("You must add your username!!"),
  // FirstName: Yup.string().required("You must add your first name!"),
  // LastName: Yup.string().required("You must add your last name!"),
  // Phone: Yup.string().required("You must add your phone!"),
});

const Profile = (data) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState({});

  useEffect(() => {
    setIsLoading(true);
    getMyData().then((res) => {
      setIsLoading(false);
      setUser(res.data.body);
      console.log(res.data.body, "res user new");
    });
  }, []);


  const tokenDate = token && jwt_decode(token);

  console.log(user, "user profile");

  const renderPlaceHolder = (name) => {
    const placeholder = {
      Email: "Email",
      UserName: "UserName",
      FirstName: "First Name",
      LastName: "Last Name",
      Phone: "Phone",
      Country: "Country",
      State: "State",
      City: "City",
      ZipCode: "ZIP Code",
      AddressLine1: "Address Line 1",
      AddressLine2: "Address Line 2",
    };
    return placeholder[name];
  };

  const handleInputs = (values, errors, touched, handleChange, handleBlur) => {
    const inputValues = {
      UserName: user.userName || "",
      Email: user.email || "",
      FirstName: user.firstName || "",
      LastName: user.lastName || "",
      Phone: user.phone || "",
      Country: user.country || "",
      State: user.state || "",
      City: user.city || "",
      ZipCode: user.zipCode || "",
      AddressLine1: user.addressLine1 || "",
      AddressLine2: user.addressLine2 || "",
      ProfilePicture: null,
    };
    const valuesArray = Object.keys(inputValues);

    return valuesArray.map(
      (item) =>
        item !== "ProfilePicture" && (
          <div
            key={item}
            className={`${
              (item === "Country" ||
                item === "State" ||
                item === "City" ||
                item === "ZipCode") &&
              ""
            } ${
              isMobile
                ? "register-input-container-mobile"
                : "register-input-container"
            }`}
          >
            <input
              className="register-text-input"
              name={`${item}`}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              type={item === "Password" ? "password" : "text"}
              defaultValue={inputValues[item]}
              placeholder={renderPlaceHolder(item)}
              disabled={item === 'UserName' || item === "Email"}
            />
            <div className="error-span">
              {errors[item] && touched[item] && errors[item]}
            </div>
          </div>
        )
    );
  };

  const onProfileImageChanged = (value) => {
    const file = value;
    if (file && file.size > 15 * 1024 * 1024) return false;
    let reader = new FileReader();
    // it's onload event and you forgot (parameters)
    reader.onload = function (e) {
      let image = document.getElementById("ProfilePicture-img");
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleEdit = (values) => {
    setIsLoading(true);
    setUser(null);
    editUser(values)
      .then((res) => {
        getMyData().then((res) => {
          setIsLoading(false);
          setUser(res.data.body);
          setIsLoading(false);
          handleModalOpen({
            title: "Save Profile",
            description: `Profile saved successfully`,
          });
        });
      })
      .catch((err) => {
        setIsLoading(false);
        handleModalOpen({
          title: "Save Profile",
          description: `Something went wrong, try again later!`,
        });
      });
  };

  const rendenModalBody = ({
    title,
    description,
    descriptionComponent,
    acceptAction,
    cancelAction,
  }) =>
    descriptionComponent ? (
      <div className="modal-body-container">
        {descriptionComponent}
        <div className="modal-button-container">
          {acceptAction && (
            <button className="modal-button" onClick={acceptAction}>
              yes
            </button>
          )}
          <div
            className="modal-button"
            onClick={() => {
              cancelAction && cancelAction();
              setModalOpen(false);
            }}
          >
            close
          </div>
        </div>
      </div>
    ) : (
      <div className="modal-body">
        <div className="modal-title">{title}</div>
        <div>{description}</div>
        <div className="modal-button-container">
          {acceptAction && (
            <button className="modal-button" onClick={acceptAction}>
              yes
            </button>
          )}
          <div
            className="modal-button"
            onClick={() => {
              cancelAction && cancelAction();
              setModalOpen(false);
            }}
          >
            close
          </div>
        </div>
      </div>
    );

  const handleModalOpen = (modalBody) => {
    setModalBody(modalBody);
    setModalOpen(true);
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <>
      <Header />
      <div className="settings-container">
        <div className="settings-content">
          {user && (
            <Formik
              initialValues={{
                UserName: user.userName || "",
                Email: user.email || "",
                FirstName: user.firstName || "",
                LastName: user.lastName || "",
                Phone: user.phone || "",
                Country: user.country || "",
                State: user.state || "",
                City: user.city || "",
                ZipCode: user.zipCode || "",
                AddressLine1: user.addressLine1 || "",
                AddressLine2: user.addressLine2 || "",
                ProfilePicture: null,
              }}
              validationSchema={Schema}
              onSubmit={handleEdit}

            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form className="login-form settings">
                  <div className="login-content-container settings-form">
                    <div className="login-rigth-container">
                      {isLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <>
                          <div className="login-title-container">
                            <div className="login-title settings-title">
                              Your Profile
                            </div>
                            <p className="login-paragraph settings-paragraph">
                              You can add or edit your information!
                            </p>
                          </div>
                          {user.firstRole !== "SCHOOL_ADMINISTRATOR" && (
                            <div className="profile-image-container">
                              <label className="profile-image-container">
                                <input
                                  name={"ProfilePicture"}
                                  accept="image/*"
                                  type="file"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "ProfilePicture",
                                      e.target.files[0]
                                    );
                                    onProfileImageChanged(e.target.files[0]);
                                  }}
                                />

                                <img
                                  src={
                                    user["profileImageUrl"]
                                      ? user["profileImageUrl"]
                                      : blankImage
                                  }
                                  id={"ProfilePicture-img"}
                                  alt="main"
                                  className={`${
                                    data["ProfilePicture"] ? "" : "blank-image"
                                  }`}
                                />
                                <div className="upload-button">Upload</div>
                              </label>
                            </div>
                          )}

                          {handleInputs(
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur
                          )}

                          <button
                            className="login-submit-button"
                            type="button"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
      <Modal
        open={modalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setModalOpen(false)}
        className="modal-container"
      >
        {rendenModalBody(modalBody)}
      </Modal>
      <Footer />
    </>
  );
};

export default Profile;
