const enumRoles = {
    STUDENT:1,
    SCHOOL_COUNSELOR: 2,
    SCHOOL_ADMINISTRATOR: 3,
    SUPER_ADMIN: 4,
}

const enumChapterAreas = [
    'SELF-AWARENESS',
    'SELF-MANAGEMENT',
    'RESPONSIBLE DECISION-MAKING',
    'RELATIONSHIP-SKILLS',
    'SOCIAL-AWARENESS'
]

export function parseChapterAreas(chapterArea) {
    let theIndex = -1;
    enumChapterAreas.forEach((element, index) => {
        if(element === chapterArea){
            if(index === 0) {
                theIndex = 4
            } else {
                theIndex = index - 1;
            }
        }
    });
    return theIndex;
}

const enumUserApprovedStatus = {
    1: 'DENIED', 
    2: 'PENDING',
    3: 'APPROVED'
}

export const approvalStatus = {
    DENIED: 1 , 
    PENDING: 2,
    APPROVED: 3
}

export const parseUserApprovedStatus = (status) => {
    const approvalStatus = {
        1: "DENIED",
        2: "PENDING",
        3: "APPROVED",
    }
    return approvalStatus[status];
}

const enumSelfEvaluation = {
        0: 'GHOST',
        1: 'PENDING',
        2: 'AUTO_EVALUATION_COMPLETED',
        3: 'COUNSELOR_EVALUATION_COMPLETED'
}

export function parseSelfEvaluationStatus(selfEvaluationStatus) {
    let theValue;
    Object.keys(selfEvaluationStatus).forEach((key) => {
        if(key == selfEvaluationStatus){
            theValue = selfEvaluationStatus[key]
        }
    })
        return theValue;
}

export const evaluationStatus = {
    PENDING: 1,
    AUTO_EVALUATION_COMPLETED: 2,
    COUNSELOR_EVALUATION_COMPLETED: 3
}

export const chapterProgressStatus = {
    INCOMPLETED: 1, 
    COMPLETED: 2,
    REVIEWED: 3
}

export const roles = Object.freeze(enumRoles);

export const chapterAreas = Object.freeze(enumChapterAreas);