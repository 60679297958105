import axios from 'axios';

const axiosInstance = axios.create({
  //baseURL: `https://movementbe-development.azurewebsites.net`,
  //baseURL: 'https://movementbe-dev.azurewebsites.net/',
  // baseURL: 'https://movementbe-staging.azurewebsites.net/',
  baseURL: 'https://movementbe-production.azurewebsites.net/',
  headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
});

export default axiosInstance;
