import React, { Component } from 'react';
import './Chapters.css';
import Container from 'react-bootstrap/Container';
import { Row, Col } from 'react-bootstrap'

import Header from "../../components/Header";
import Title from "../../components/Poem/Title";
import Chapter from './Chapter';
import { number } from 'yup';

const numberOfChapters = 10;
const numberOfChaptersCompleted = 7
let chapters = [];

class Chapters extends Component {

    renderChapters = () => {
        for (let index = 1; index <= numberOfChapters; index = index+5) {
                chapters.push(
                    <>
                        <div className="chapter-row">
                            {/* <Col> */}
                                <Chapter chapterNumber={index} completed={index <= numberOfChaptersCompleted} isNextChapterToBeCompleted={index === numberOfChaptersCompleted + 1}/>
                            {/* </Col> */}
                        </div>
                        <div className="chapter-row">
                            {index+1 <= numberOfChapters && <Chapter chapterNumber={index+1} completed={index+1 <= numberOfChaptersCompleted} isNextChapterToBeCompleted={index+1 === numberOfChaptersCompleted}/>}
                            {index+2 <= numberOfChapters && <Chapter chapterNumber={index+2} completed={index+2 <= numberOfChaptersCompleted} isNextChapterToBeCompleted={index+2 === numberOfChaptersCompleted}/>}
                        </div>
                        <div className="chapter-row">

                            {index+3 <= numberOfChapters && <Chapter chapterNumber={index+3} completed={index+3 <= numberOfChaptersCompleted} isNextChapterToBeCompleted={index+3 === numberOfChaptersCompleted}/>}
                            {index+4 <= numberOfChapters && <Chapter chapterNumber={index+4} completed={index+4 <= numberOfChaptersCompleted} isNextChapterToBeCompleted={index+4 === numberOfChaptersCompleted}/>}
                        </div>
                    </>
                )
        }
        return chapters;
    }

    render() {
      return (
          <div className="bg-grid">
              <Header />
              <Title data={'Select a Chapter'} className='centered'/>
              {/* <img className="poem-border" src={border} alt="border" /> */}
              <div className="chapters-container">
                  {/* <Container> */}
                    {this.renderChapters()}
                  {/* </Container> */}
              </div>
          </div>
      )
    }
}

export default Chapters;