import React, { Component } from "react";
import "./CardWithImage.css";
import Lock from "../../utils/images/Lock.png";

import Roulette from "../../components/Roulette";
import ModalWrapper from "../../components/ModalWrapper";

import { getChapterCompetencyTest } from "../../services/schoolServices";
import { parseChapterAreas } from "../../utils/constants";
import { isMobile } from "react-device-detect";

class CardWithImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rouletteShown: false,
      rouletteIsChoosing: false,
    };
  }

  handlePreviewClose = () => {
    if (this.state.hoveringStartButton) return;

    this.props.handlePreviewClose();

    this.setState({
      rouletteShown: false,
      rouletteIsChoosing: false,
    });
  };

  handleChapterStart = async () => {
    if (!this.props.completed || !this.props.previousEvaluationCompleted)
      return;

    this.props.handleChapterStart();

    if (!this.state.rouletteShown) {
      this.setState({
        rouletteShown: true,
      });
      return;
    } else {
      this.setState({
        rouletteIsChoosing: true,
      });
    }

    try {
      let response = await getChapterCompetencyTest({
        chapterProgressId: this.props.chapterId,
      });
      if (response.data.success) {
        this.setState({
          nextAreaToBeCompleted: parseChapterAreas(
            response.data.body.competencyName
          ),
          nextTaskTemplate: response.data.body.testTemplate,
          testTemplateId: response.data.body.id,
        });
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  hoveringStartButton = (bool) => {
    this.setState({ hoveringStartButton: bool });
  };

  _renderDesktop = () => {
    return this.props.imageToTheRight ? (
      <div
        className="card-container"
        style={{
          zIndex: 99 - this.props.chapterNumber,
          backgroundColor: this.props.backgroundColor,
        }}
      >
        <div className="card-left-side">
          <CardData
            chapterNumber={this.props.chapterNumber}
            chapterTitle={this.props.chapterTitle}
            completed={this.props.completed}
            finished={this.props.finished}
            handleChapterStart={this.handleChapterStart}
            rouletteShown={this.state.rouletteShown}
            handlePreviewClose={this.handlePreviewClose}
            nextAreaToBeCompleted={this.state.nextAreaToBeCompleted}
            nextTaskTemplate={this.state.nextTaskTemplate}
            testTemplateId={this.state.testTemplateId}
            chapterProgressId={this.props.chapterId}
            rouletteIsChoosing={this.state.rouletteIsChoosing}
            hoveringStartButton={this.hoveringStartButton}
            competenciesProgress={this.props.competenciesProgress}
            previousEvaluationCompleted={this.props.previousEvaluationCompleted}
          />
        </div>
        <div className="card-right-side">
          <CardImage
            chapterImage={this.props.chapterImage}
            backgroundColor={this.props.backgroundColor}
            completed={this.props.completed}
          />
        </div>
      </div>
    ) : (
      <div
        className="card-container"
        style={{ zIndex: 99 - this.props.chapterNumber }}
      >
        <div className="card-left-side">
          <CardImage
            chapterImage={this.props.chapterImage}
            backgroundColor={this.props.backgroundColor}
            completed={this.props.completed}
          />
        </div>
        <div className="card-right-side">
          <CardData
            chapterNumber={this.props.chapterNumber}
            chapterTitle={this.props.chapterTitle}
            completed={this.props.completed}
            finished={this.props.finished}
            handleChapterStart={this.handleChapterStart}
            rouletteShown={this.state.rouletteShown}
            handlePreviewClose={this.handlePreviewClose}
            nextAreaToBeCompleted={this.state.nextAreaToBeCompleted}
            nextTaskTemplate={this.state.nextTaskTemplate}
            testTemplateId={this.state.testTemplateId}
            chapterProgressId={this.props.chapterId}
            rouletteIsChoosing={this.state.rouletteIsChoosing}
            hoveringStartButton={this.hoveringStartButton}
            competenciesProgress={this.props.competenciesProgress}
            previousEvaluationCompleted={this.props.previousEvaluationCompleted}
          />
        </div>
      </div>
    );
  };

  _renderMobile = () => {
    return this.props.imageToTheRight ? (
      <div
        className="card-container-mobile"
        style={{
          zIndex: 99 - this.props.chapterNumber,
          backgroundColor: this.props.backgroundColor,
        }}
      >
        <div className="card-left-side">
          <CardData
            chapterNumber={this.props.chapterNumber}
            chapterTitle={this.props.chapterTitle}
            completed={this.props.completed}
            finished={this.props.finished}
            handleChapterStart={this.handleChapterStart}
            rouletteShown={this.state.rouletteShown}
            handlePreviewClose={this.handlePreviewClose}
            nextAreaToBeCompleted={this.state.nextAreaToBeCompleted}
            nextTaskTemplate={this.state.nextTaskTemplate}
            testTemplateId={this.state.testTemplateId}
            chapterProgressId={this.props.chapterId}
            rouletteIsChoosing={this.state.rouletteIsChoosing}
            hoveringStartButton={this.hoveringStartButton}
            competenciesProgress={this.props.competenciesProgress}
            previousEvaluationCompleted={this.props.previousEvaluationCompleted}
          />
        </div>
        <div className="card-right-side">
          <CardImage
            chapterImage={this.props.chapterImage}
            backgroundColor={this.props.backgroundColor}
            completed={this.props.completed}
          />
        </div>
      </div>
    ) : (
      <div
        className="card-container-mobile"
        style={{ zIndex: 99 - this.props.chapterNumber }}
      >
        <div className="card-left-side">
          <CardImage
            chapterImage={this.props.chapterImage}
            backgroundColor={this.props.backgroundColor}
            completed={this.props.completed}
          />
        </div>
        <div className="card-right-side">
          <CardData
            chapterNumber={this.props.chapterNumber}
            chapterTitle={this.props.chapterTitle}
            completed={this.props.completed}
            finished={this.props.finished}
            handleChapterStart={this.handleChapterStart}
            rouletteShown={this.state.rouletteShown}
            handlePreviewClose={this.handlePreviewClose}
            nextAreaToBeCompleted={this.state.nextAreaToBeCompleted}
            nextTaskTemplate={this.state.nextTaskTemplate}
            testTemplateId={this.state.testTemplateId}
            chapterProgressId={this.props.chapterId}
            rouletteIsChoosing={this.state.rouletteIsChoosing}
            hoveringStartButton={this.hoveringStartButton}
            competenciesProgress={this.props.competenciesProgress}
            previousEvaluationCompleted={this.props.previousEvaluationCompleted}
          />
        </div>
      </div>
    );
  };

  render() {
    if (isMobile) {
      return this._renderMobile();
    } else {
      return this._renderDesktop();
    }
  }
}

const CardData = (props) => {
    // console.log(props, "card data props")
  return (
    <div>
      <div className="card-data">
        <div className="card-data-chapter mg-10">
          Chapter {props.chapterNumber}
        </div>
        <div className="card-data-chapter-title mg-10">
          {props.chapterTitle}
        </div>
        <div className="button-roulette-container">
          <button
            onMouseEnter={() => props.hoveringStartButton(true)}
            onMouseLeave={() => props.hoveringStartButton(false)}
            onClick={() => {
              if (!props.finished) props.handleChapterStart();
            }}
            className={`card-data-button mg-10 
                    ${
                      !props.previousEvaluationCompleted
                        ? "incomplete"
                        : props.finished
                        ? "complete"
                        : !props.completed
                        ? "incomplete"
                        : ""
                    }

                    ${props.rouletteIsChoosing ? "roulette-choosing" : ""}
                `}
          >
            {props.finished
              ? "Complete"
              : props.rouletteShown
              ? "Start"
              : "Open"}
          </button>
          {props.rouletteShown && (
            <div>
              <ModalWrapper handlePreviewClose={props.handlePreviewClose}>
                <Roulette
                  isChoosing={props.rouletteIsChoosing}
                  nextAreaToBeCompleted={props.nextAreaToBeCompleted}
                  nextTaskTemplate={props.nextTaskTemplate}
                  testTemplateId={props.testTemplateId}
                  chapterProgressId={props.chapterProgressId}
                  competenciesProgress={props.competenciesProgress}
                />
              </ModalWrapper>
              <div className="blurred"> </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const CardImage = (props) => {
  return (
    <div className="chapter-image-container">
      {!props.completed && <img className="lock-image" src={Lock} alt="Lock" />}
      <img
        className="chapter-image"
        src={props.chapterImage}
        alt="chapter1-image"
      />
    </div>
  );
};

export default CardWithImage;
