import { combineReducers } from 'redux';
import appReducer from './appReducer';
import loginReducer from './loginReducer';
import schoolReducer from './schoolReducer';
import schoolAdminReducer from './schoolAdminReducer';
import selfEvaluationReducer from './selfEvaluationReducer';
import studentReducer from "./studentReducer";

const rootReducer = combineReducers({
  appReducer, loginReducer, schoolReducer, schoolAdminReducer, selfEvaluationReducer, studentReducer
});

export default rootReducer;