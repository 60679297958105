import React, { Component } from 'react';
import './MovementBeLand.css';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Title from "../../components/Poem/Title";
import CardWithImage from "../../components/CardWithImage";
import Vector3 from '../../utils/images/Vector3.png';
import chapter1Image from '../../utils/images/chapter1-image.png';
import chapter2Image from '../../utils/images/chapter2-image.png';
import chapter3Image from '../../utils/images/chapter3-image.png';
import chapter4Image from '../../utils/images/chapter4-image.png';
import chapter5Image from '../../utils/images/chapter5-image.png';
import chapter6Image from '../../utils/images/chapter6-image.png';
import chapter7Image from '../../utils/images/chapter7-image.png';
import chapter8Image from '../../utils/images/chapter8-image.png';
import chapter9Image from '../../utils/images/chapter9-image.png';
import chapter10Image from '../../utils/images/chapter10-image.png';
import CircularProgress from "@material-ui/core/CircularProgress";

import { getAllChapters } from '../../services/chapterServices';
import { getMyChaptersProgress, startANewChapter, getMyAutoEvaluations, getMyFirstAutoEvaluation } from '../../services/schoolServices'
import SelfEvaluation from './SelfEvaluation.js';
import FirstSelfEvaluation from './FirstSelfEvaluation';

import { isMobile } from 'react-device-detect'
import { chapterProgressStatus, evaluationStatus } from '../../utils/constants';

let completedChapters = 1
let chapters = [
    {
        image: chapter1Image,
        number: 1,
        title: 'Choose your Avatar',
        backgroundColor: '#BCD8FF',
        finished: true,
    },
    {
        image: chapter2Image,
        number: 2,
        title: 'Identity Island',
        backgroundColor: '#0075CF'
    },
    {
        image: chapter3Image,
        number: 3,
        title: 'Purpose Island',
        backgroundColor: '#FF7373'
    },
    {
        image: chapter4Image,
        number: 4,
        title: 'Intuition Island',
        backgroundColor: '#6CD998'
    },
    {
        image: chapter5Image,
        number: 5,
        title: 'Vision Island',
        backgroundColor: '#E3C763'
    },
    {
        image: chapter6Image,
        number: 6,
        title: 'Resilience Island',
        backgroundColor: '#DD8BF1'
    },
    {
        image: chapter7Image,
        number: 7,
        title: 'Innovation Island',
        backgroundColor: '#204E6E'
    },
    {
        image: chapter8Image,
        number: 8,
        title: 'Leadership Island',
        backgroundColor: '#FF9145'
    },
    {
        image: chapter9Image,
        number: 9,
        title: 'Sustainability Island',
        backgroundColor: '#F79595'
    },
    {
        image: chapter10Image,
        number: 10,
        title: 'Wisdom Island',
        backgroundColor: '#0075CF'
    }
]


let colors = ['#BCD8FF', '#0075CF', '#FF7373', '#6CD998', '#E3C763', '#DD8BF1', '#204E6E', '#FF9145', '#F79595', '#0075CF']
let numberOfCompetencies = 5;

class MovementBeLand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShown: false,
            // chapters: chapters,
            chapterProgress: [
                {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: 'Pre Chapter',
                    chapterProgressId: '00000000-0000-0000-0000-000000000000',
                    evaluationCompleted: false,
                }
            ],
            isLoading: true,
        }
    }

    handleChapterStart = () => {
        this.setState({
            modalShown: true
        })
    }

    handlePreviewClose = () => {
        this.setState({
            modalShown: false,
        })
    }

    async componentDidMount() {
        this.generateChapterProgress();
        let chapters = []

        try {
            await startANewChapter({});
        } catch (error) {
            console.error('error: ', error);
            this.setState({ isLoading: false });
        }

        try {
            let response = await getAllChapters({});
            if (response.data.success) {
                chapters = response.data.body;
                chapters = chapters.map((element, index) => {
                    return (
                        {
                            id: element.id,
                            image: element.mainPictureUrl,
                            number: element.order,
                            title: element.name,
                            backgroundColor: colors[index],
                            // finished: false,
                        }
                    )
                })
                this.setState({
                    chapters: chapters,
                })
            }
        } catch (error) {
            console.error('error: ', error);
            this.setState({ isLoading: false });
        }

        try {
            let responseGetMyChaptersProgress = await getMyChaptersProgress({});
            // console.log('responeGetMyChaptersProgress: ', responseGetMyChaptersProgress);
            chapters = []
            if (responseGetMyChaptersProgress.data.success) {
                this.state.chapters.forEach((chapter, index) => {
                    let found = false;
                    responseGetMyChaptersProgress.data.body.map((element, index) => {
                        if (chapter.id === element.chapterId) {
                            found = true;
                            switch (element.state) {
                                //incomplete
                                case 1:
                                    chapters.push({ ...chapter, finished: false, completed: true, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                    break;
                                //completed
                                case 2:
                                    if (element.completedCompetencies === numberOfCompetencies) {
                                        chapters.push({ ...chapter, finished: true, completed: true, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                    } else {
                                        chapters.push({ ...chapter, finished: true, completed: false, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                    }
                                    break;
                                //reviewed
                                case 3:
                                    chapters.push({ ...chapter, finished: true, completed: false, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                    break;
                                default:
                                    chapters.push({ ...chapter, finished: false, completed: false, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                    break;
                            }
                        }
                    })
                    if (!found) {
                        chapters.push({ ...chapter, finished: false, completed: false, id: chapter.id, autoEvaluationComplete: false })
                    }
                })
            }
            // console.log('chapters: ', chapters);
            this.setState({
                chapters: chapters,
            })
        } catch (error) {
            console.error('error: ', error);
            this.setState({ isLoading: false });
        }

        try {
            let responseGetMyAutoEvaluations = await getMyAutoEvaluations({});
            // console.log('responseGetMyAutoEvaluations: ', responseGetMyAutoEvaluations);
            if (responseGetMyAutoEvaluations.data.success === true) {
                this.setState({
                    myAutoEvaluations: responseGetMyAutoEvaluations.data.body.evaluations
                })
            }
        } catch (error) {
            console.error('error: ', error);
            this.setState({ isLoading: false });
        }

        try {
            let responseGetMyFirstAutoEvaluation = await getMyFirstAutoEvaluation({});
            // console.log('response GetMyFirstAutoEvaluation', responseGetMyFirstAutoEvaluation)
            
            if (responseGetMyFirstAutoEvaluation.data.success === true) {
                if (responseGetMyFirstAutoEvaluation.data.body.state === evaluationStatus.PENDING) {
                    this.setState({
                        myFirstAutoEvaluationCompleted: false
                    })
                } else if (responseGetMyFirstAutoEvaluation.data.body.state === evaluationStatus.AUTO_EVALUATION_COMPLETED) {
                    this.setState({
                        myFirstAutoEvaluationCompleted: true
                    })

                }
            }
        } catch (error) {
            console.error('error: ', error);
            this.setState({ isLoading: false });
        }
        this.setState({ isLoading: false });
    }

    async componentDidUpdate(prevProps) {
        
        if (this.props !== prevProps) {
            try {
                this.generateChapterProgress();
                let responseGetMyChaptersProgress = await getMyChaptersProgress({});
                // console.log('responeGetMyChaptersProgress: ', responseGetMyChaptersProgress);
                chapters = []
                if (responseGetMyChaptersProgress.data.success) {
                    this.state.chapters.forEach((chapter, index) => {
                        let found = false;
                        responseGetMyChaptersProgress.data.body.map((element, index) => {
                            if (chapter.id === element.chapterId) {
                                found = true;
                                switch (element.state) {
                                    //incomplete
                                    case 1:
                                        chapters.push({ ...chapter, finished: false, completed: true, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                        break;
                                    //completed
                                    case 2:
                                        if (element.completedCompetencies === numberOfCompetencies) {
                                            chapters.push({ ...chapter, finished: true, completed: true, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                        } else {
                                            chapters.push({ ...chapter, finished: true, completed: false, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                        }
                                        break;
                                    //reviewed
                                    case 3:
                                        chapters.push({ ...chapter, finished: true, completed: false, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                        break;
                                    default:
                                        chapters.push({ ...chapter, finished: false, completed: false, competenciesProgress: element.competenciesProgress, id: element.id, autoEvaluationComplete: false, chapterId: chapter.id })
                                        break;
                                }
                            }
                        })
                        if (!found) {
                            chapters.push({ ...chapter, finished: false, completed: false, id: chapter.id, autoEvaluationComplete: false })
                        }
                    })
                }
                this.setState({
                    chapters: chapters,
                })
            } catch (error) {
                console.error('error: ', error);
            }
        }
    }

    generateChapterProgress = async () => {
        const responseGetAllChapters = await getAllChapters({});
        const chapters = responseGetAllChapters.data.body;
        const responseMyChapterProgress = await getMyChaptersProgress();
        const myChapterProgress = responseMyChapterProgress.data.body;
        const responseFirstEvaluation = await getMyFirstAutoEvaluation();
        const firstEValuation = responseFirstEvaluation.data.body;
        const responseEvaluations = await getMyAutoEvaluations();
        const evaluations = responseEvaluations.data.body.evaluations;
        const { chapterProgress } = this.state;
        chapters.forEach(chapter => {
            chapterProgress.push(chapter);
        })
        chapterProgress.forEach(chapter => {
            myChapterProgress.forEach(cProgress => {
                if (chapter.id === cProgress.chapterId) {
                    chapter.chapterProgressId = cProgress.id;
                    chapter.competenciesProgress = cProgress.competenciesProgress;
                    chapter.completedCompetencies = cProgress.completedCompetencies;
                    chapter.state = cProgress.state;
                    chapter.chapterCompleted = cProgress.state === chapterProgressStatus.COMPLETED;
                }
            })
        })
        chapterProgress[0].evaluationCompleted = firstEValuation.state === evaluationStatus.AUTO_EVALUATION_COMPLETED;
        chapterProgress.forEach(chapter => {
            evaluations.forEach(evaluation => {
                if (chapter.chapterProgressId === evaluation.id) {
                    chapter.evaluationdId = evaluation.evaluationId;
                    chapter.evaluations = evaluation.evaluations;
                    chapter.evaluationState = evaluation.state;
                    chapter.evaluationCompleted = evaluation.state === evaluationStatus.AUTO_EVALUATION_COMPLETED;
                }
            })
        })
        // console.log('chapterProgress: ', chapterProgress);
        this.setState({ chapterProgress });

    }

    drawPath = (dots, flip, secondaryPath, moveLeft, done) => {
        // console.log('done: ', done)
        return (
            <div className={secondaryPath ? 'secondary-path' : null} id={moveLeft ? 'move-left' : null}>
                <div className="dot-container" id={flip ? 'flip-dots' : null}>
                    {dots.map((dot, index) => {
                        // console.log(dot.x)
                        return (
                            <div className="dot" style={{ transform: `translate(${dot.x}, ${dot.y})` }} id={done ? 'path-done' : undefined} />
                        )
                    })}
                </div>
            </div>
        )
    }

    _renderChapterAndAssessment = (chapter, index) => {
        return (
            <>
                <div className="chapter-row">
                    <div className="chapter-div">

                    </div>
                    <div className="assessment-div">
                        <div className="assessment-not-even">
                            <SelfEvaluation
                                chapter={{
                                    id: this.state.chapters[index - 1]?.id,
                                    completed: this.state.chapters[index - 1]?.finished,
                                    chapterId: this.state.chapters[index - 1]?.chapterId
                                }}
                                evaluation={this.state.myAutoEvaluations ?
                                    this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id).length !== 0 ?
                                        this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id)
                                        :
                                        undefined
                                    :
                                    undefined}
                                backgroundColor={this.state.chapters[index - 1]?.backgroundColor}
                            />
                        </div>
                    </div>
                </div>
                <div className="chapter-row">
                    <div className="chapter-div">
                        <div className="chapter-even">
                            <CardWithImage
                                imageToTheRight={true}
                                chapterImage={chapter.image}
                                chapterNumber={chapter.number + 1}
                                chapterTitle={chapter.title}
                                backgroundColor={chapter.backgroundColor}
                                completed={chapter.completed}
                                finished={chapter.finished}
                                handleChapterStart={this.handleChapterStart}
                                handlePreviewClose={this.handlePreviewClose}
                                chapterProgressId={this.state.chapterProgressId}
                                chapterId={chapter.id}
                                competenciesProgress={chapter.competenciesProgress ? chapter.competenciesProgress : []}
                                previousEvaluationCompleted={
                                    index === 0 ?
                                        this.state.myFirstAutoEvaluationCompleted ?
                                            true
                                            :
                                            false
                                        : (
                                            this.state.myAutoEvaluations ? (
                                                this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id).length !== 0 ?
                                                    true
                                                    :
                                                    false
                                            ) : (
                                                    false
                                                )
                                        )
                                }
                            />
                            {this.drawPath(secondaryDotsArray, false, true, false, chapter.finished)}
                        </div>
                    </div>
                    <div className="assessment-div">
                        {this.drawPath(dotsArray, true, false, false, this.state.chapterProgress && this.state.chapterProgress[index] && this.state.chapterProgress[index].evaluationCompleted)}
                    </div>
                </div>
            </>
        )
    }

    _renderAssessmentAndChapter = (chapter, index) => {
        return (
            <>
                <div className="chapter-row">
                    <div className="assessment-div">
                        <div className="assessment-not-even">
                            {
                                index === 0 ?
                                    <FirstSelfEvaluation /> :
                                    <SelfEvaluation
                                        chapter={{
                                            id: this.state.chapters[index - 1].id,
                                            completed: this.state.chapters[index - 1].finished,
                                            chapterId: this.state.chapters[index - 1].chapterId
                                        }}
                                        evaluation={this.state.myAutoEvaluations ?
                                            this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id).length !== 0 ?
                                                this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id)
                                                :
                                                undefined
                                            :
                                            undefined}
                                        backgroundColor={this.state.chapters[index - 1].backgroundColor}
                                    />
                            }
                        </div>
                    </div>
                    <div className="chapter-div">

                    </div>
                </div>
                <div className="chapter-row">
                    <div className="assessment-div">
                        {this.drawPath(dotsArray, false, false, false, this.state.chapterProgress && this.state.chapterProgress[index] && this.state.chapterProgress[index].evaluationCompleted)}
                    </div>
                    <div className="chapter-div">
                        <div className="chapter-not-even">
                            <CardWithImage
                                imageToTheRight={true}
                                chapterImage={chapter.image}
                                chapterNumber={chapter.number + 1}
                                chapterTitle={chapter.title}
                                backgroundColor={chapter.backgroundColor}
                                completed={chapter.completed}
                                finished={chapter.finished}
                                handleChapterStart={this.handleChapterStart}
                                handlePreviewClose={this.handlePreviewClose}
                                chapterProgressId={this.state.chapterProgressId}
                                chapterId={chapter.id}
                                competenciesProgress={chapter.competenciesProgress ? chapter.competenciesProgress : []}
                                previousEvaluationCompleted={
                                    index === 0 ?
                                        this.state.myFirstAutoEvaluationCompleted ?
                                            true
                                            :
                                            false
                                        : (
                                            this.state.myAutoEvaluations ? (
                                                this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id).length !== 0 ?
                                                    true
                                                    :
                                                    false
                                            ) : (
                                                    false
                                                )
                                        )
                                }
                            />
                            {this.drawPath(secondaryDotsArray, true, true, true, chapter.finished)}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    _renderAssessmentAndChapterMobile = (chapter, index) => {
        return (
            <>
                <div>
                    <div>
                        {
                            index === 0 ?
                                <FirstSelfEvaluation /> :
                                <SelfEvaluation
                                    chapter={{
                                        id: this.state.chapters[index - 1].id,
                                        completed: this.state.chapters[index - 1].finished,
                                        chapterId: this.state.chapters[index - 1].chapterId
                                    }}
                                    evaluation={this.state.myAutoEvaluations ?
                                        this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id).length !== 0 ?
                                            this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id)
                                            :
                                            undefined
                                        :
                                        undefined}
                                    backgroundColor={this.state.chapters[index - 1].backgroundColor}
                                />
                        }
                    </div>
                    <div>

                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            <CardWithImage
                                imageToTheRight={true}
                                chapterImage={chapter.image}
                                chapterNumber={chapter.number + 1}
                                chapterTitle={chapter.title}
                                backgroundColor={chapter.backgroundColor}
                                completed={chapter.completed}
                                finished={chapter.finished}
                                handleChapterStart={this.handleChapterStart}
                                handlePreviewClose={this.handlePreviewClose}
                                chapterProgressId={this.state.chapterProgressId}
                                chapterId={chapter.id}
                                competenciesProgress={chapter.competenciesProgress ? chapter.competenciesProgress : []}
                                previousEvaluationCompleted={
                                    index === 0 ?
                                        this.state.myFirstAutoEvaluationCompleted ?
                                            true
                                            :
                                            false
                                        : (
                                            this.state.myAutoEvaluations ? (
                                                this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index - 1].id).length !== 0 ?
                                                    true
                                                    :
                                                    false
                                            ) : (
                                                    false
                                                )
                                        )
                                }
                            />
                            {this.drawPath(secondaryDotsArray, true, true, true, chapter.finished)}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    _renderLastAssessment = (chapter, index) => {
        return (

            <div className="chapter-row">
                <div className={index % 2 === 0 ? "chapter-div" : null}>

                </div>
                <div className="assessment-div" id="last-assessment">
                    <SelfEvaluation
                        chapter={{
                            id: this.state.chapters[index]?.id,
                            completed: this.state.chapters[index]?.finished,
                            chapterId: this.state.chapters[index]?.chapterId
                        }}
                        evaluation={this.state.myAutoEvaluations ?
                            this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index].id).length !== 0 ?
                                this.state.myAutoEvaluations.filter(e => e.id === this.state.chapters[index].id)
                                :
                                undefined
                            :
                            undefined}
                        backgroundColor={this.state.chapters[index]?.backgroundColor}
                    />
                </div>
                {
                    !isMobile ?
                        <div className={index % 2 === 0 ? null : "chapter-div"}>
                        </div>
                        :
                        <>
                        </>
                }
            </div>
        )
    }

    displayGamePath = chapters => {
        return (
            <>
                { chapters.map((chapter, index) => {
                    return (
                        <>
                            {
                                isMobile ? (
                                    this._renderAssessmentAndChapterMobile(chapter, index)
                                ) : (
                                        (index) % 2 === 0 ?
                                            this._renderAssessmentAndChapter(chapter, index) :
                                            this._renderChapterAndAssessment(chapter, index)
                                    )
                            }
                        </>
                    )
                })}
                {
                    this._renderLastAssessment(chapters[chapters.length - 1], chapters.length - 1)
                }
            </>
        )
    }

    _renderDesktop(isLoading) {
        return (
            <>
                <div className={isLoading ? 'is-loading' : 'not-loading'}>
                    <CircularProgress color="secondary" />
                </div>
                <div className="bg-grid movement-beland" id={isLoading ? 'display-not': undefined}>
                    <Header />
                    <Title data={'MovementBe Land'} className='centered chapter-title' />
                    {/* <div className="chapter-title">
                        MovementBe Land
                    </div> */}
                    <div className="under-title-line">
                        <img src={Vector3} alt="chapter1-image" />
                    </div>
                    <div className="text-under-title">
                        Welcome! This is the beginning of your new journey. There are 10 chapter islands and you will need to complete activities on each island to move on to the next level. But don’t worry, you will have a Movement BE guide on each island to help you on your way. Each activity is worth 10 BE coins. Use your BE coins throughout the game to buy swag in the BE Store. Conquer each island and meet me on the final level to win the game!
                    </div>
                    <div className="question-under-title">
                        Are you ready?
                    </div>
                    {/* <div className={`cards-container ${this.state.modalShown ? 'blurred' : ''}`}> */}
                    <div className={`cards-container`}>
                        {this.state.chapters && this.displayGamePath(this.state.chapters)}
                    </div>
                    <Footer />
                </div>
            </>
        )
    }

    _renderMobile(isLoading) {
        return (
            <>
                <div className={isLoading ? 'is-loading' : 'not-loading'}>
                    <CircularProgress color="secondary" />
                </div>
                <div className="bg-grid">
                    <Header />
                    <Title data={'MovementBe Land'} className='centered chapter-title' />
                    {/* <div className="chapter-title">
                    MovementBe Land
                </div> */}
                    <div className="under-title-line-mobile">
                        <img src={Vector3} alt="chapter1-image" />
                    </div>
                    <div className="text-under-title text-under-title-mobile">
                        Welcome! This is the beginning of your new journey. There are 10 chapter islands and you will need to complete activities on each island to move on to the next level. But don’t worry, you will have a Movement BE guide on each island to help you on your way. Each activity is worth 10 BE coins. Use your BE coins throughout the game to buy swag in the BE Store. Conquer each island and meet me on the final level to win the game!
                </div>
                    {/* <div className={`cards-container ${this.state.modalShown ? 'blurred' : ''}`}> */}
                    <div className={`cards-container`}>
                        {this.state.chapters && this.displayGamePath(this.state.chapters)}
                    </div>
                </div>
                <Footer />
            </>
        )
    }



    render() {
        const { isLoading } = this.state;
        return (
            <>
                {
                    isMobile ?
                        this._renderMobile(isLoading) :
                        this._renderDesktop(isLoading)
                }
            </>
        )
    }
}

export default MovementBeLand;

let secondaryDotsArray = [
    {
        x: '20vw',
        y: '0vw',
    },
    {
        x: '19.9vw',
        y: '0vw',
    },
    {
        x: '19.7vw',
        y: '0vw',
    },
    {
        x: '19.4vw',
        y: '0vw',
    },
    {
        x: '19vw',
        y: '0vw',
    },
    {
        x: '18vw',
        y: '-0.5vw',
    },
    {
        x: '17vw',
        y: '-1.5vw',
    },
    {
        x: '16vw',
        y: '-2.3vw',
    },
    {
        x: '15.1vw',
        y: '-2.8vw',
    },
    {
        x: '14.3vw',
        y: '-3.2vw',
    },
    {
        x: '13.6vw',
        y: '-3.5vw',
    },
    {
        x: '13.2vw',
        y: '-3.6vw',
    },
    {
        x: '13.1vw',
        y: '-3.6vw',
    },
    {
        x: '13vw',
        y: '-3.6vw',
    },
    {
        x: '12.9vw',
        y: '-3.6vw',
    },
]


let dotsArray = [
    {
        x: '0vw',
        y: '0vw',
    },
    {
        x: '0.20vw',
        y: '0vw',
    },
    {
        x: '0.5vw',
        y: '0vw',
    },
    {
        x: '0.9vw',
        y: '0vw',
    },
    {
        x: '1.7vw',
        y: '-0.5vw',
    },
    {
        x: '2.6vw',
        y: '-1.3vw',
    },
    {
        x: '3.6vw',
        y: '-2.3vw',
    },
    {
        x: '4.6vw',
        y: '-3.3vw',
    },
    {
        x: '5.6vw',
        y: '-4vw',
    },
    {
        x: '6.5vw',
        y: '-4.5vw',
    },
    {
        x: '7vw',
        y: '-4.7vw',
    },
    {
        x: '7.3vw',
        y: '-4.9vw',
    },
    {
        x: '7.4vw',
        y: '-4.9vw',
    },
    {
        x: '7.5vw',
        y: '-4.9vw',
    },
    {
        x: '7.75vw',
        y: '-4.9vw',
    },
    {
        x: '8.2vw',
        y: '-5vw',
    },
    {
        x: '9vw',
        y: '-5.3vw',
    },
    {
        x: '10vw',
        y: '-6.2vw',
    },
    {
        x: '11vw',
        y: '-7.2vw',
    },
    {
        x: '12vw',
        y: '-8.2vw',
    },
    {
        x: '13vw',
        y: '-9.2vw',
    },
    {
        x: '14vw',
        y: '-10.2vw',
    },
    {
        x: '15vw',
        y: '-11.2vw',
    },
    {
        x: '16vw',
        y: '-12.2vw',
    },
    {
        x: '17vw',
        y: '-13.2vw',
    },
]