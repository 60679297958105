import React, { useEffect, useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  createStudent,
  createCounselor,
  createSchoolAdmin,
  createSchoolService,
  getCreateTokenService,
  getAllSchoolService,
} from "../../services/schoolServices";
import moment from "moment";
import blankImage from "../../utils/images/blank_user.png";
import DatePicker from "../../components/Admin/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const Schema = Yup.object().shape({
  firstName: Yup.string().required("You must add a name!"),
  lastName: Yup.string().required("You must add a last name!"),
  displayName: Yup.string().required("You must add a display name!"),
  userName: Yup.string().required("You must add a username!"),
  email: Yup.string().required("You must add a email!"),
  birthDate: Yup.string().required("You must add a birth date!"),

  schoolId: Yup.string().required("You must add a school!"),

  country: Yup.string().required("You must add a country!"),
  state: Yup.string().required("You must add a state!"),
  city: Yup.string().required("You must add a city!"),
  addressLine1: Yup.string().required("You must add a addressLine1!"),
  zipCode: Yup.string().required("You must add a zipCode!"),
});

const SchoolAdminSchema = Yup.object().shape({
  firstName: Yup.string().required("You must add a name!"),
  lastName: Yup.string().required("You must add a last name!"),
  displayName: Yup.string().required("You must add a display name!"),
  userName: Yup.string().required("You must add a username!"),
  email: Yup.string().required("You must add a email!"),
  birthDate: Yup.string().required("You must add a birth date!"),

  country: Yup.string().required("You must add a country!"),
  state: Yup.string().required("You must add a state!"),
  city: Yup.string().required("You must add a city!"),
  addressLine1: Yup.string().required("You must add a addressLine1!"),
  zipCode: Yup.string().required("You must add a zipCode!"),
});

const SchoolSchema = Yup.object().shape({
  name: Yup.string().required("You must add a name!"),

  country: Yup.string().required("You must add a country!"),
  state: Yup.string().required("You must add a state!"),
  city: Yup.string().required("You must add a city!"),
  addressLine1: Yup.string().required("You must add a addressLine1!"),
  zipCode: Yup.string().required("You must add a zipCode!"),
});

const initialValues = {
  firstName: "",
  lastName: "",
  displayName: "",
  userName: "",
  profilePicture: null,
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
  birthDate: "",
  schoolId: "",
};
const schoolValues = {
  MainPicture: null,
  name: "",
  addressLine1: "",
  addressLine2: "",
  country: "",
  state: "",
  city: "",
  zipCode: "",
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const CreateStudent = ({ type, handleModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schoolsOptions, setSchoolOptions] = useState([]);
  const [schoolToken, setSchoolToken] = useState(null);

  const handleSend = () => {
    const apiCalls = {
      Counselor: createCounselor,
      Student: createStudent,
      "School Admin": createSchoolAdmin,
      School: createSchoolService,
    };
    return apiCalls[type];
  };

  useEffect(() => {
    if (type === "School") {
      getCreateTokenService({ PermissionRequired: 1 }).then((res) =>
        setSchoolToken(res.data.body)
      );
    } else {
      getAllSchoolService()
        .then((res) => {
          const options = [];
          const response = res.data.body;
          response.forEach((element) => {
            options.push({ value: element.id, label: element.name });
          });
          console.log(response, "response schools");
          setSchoolOptions(options);
        })
        .catch((err) => this.setState({ isLoading: false, dataList: [] }));
    }
  }, []);

  const handleInputs = (values, errors, touched, handleChange, handleBlur) => {
    const valuesArray = Object.keys(
      type === "School" ? schoolValues : initialValues
    );
    return valuesArray.map(
      (item) =>
        item !== "schoolId" &&
        item !== "profilePicture" &&
        item !== "MainPicture" &&
        item !== "birthDate" && (
          <div key={item} className={`input-container w-70`}>
            <div className="edit-input-label">{item}</div>
            <input
              className="text-input"
              name={`${item}`}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              type={item === "Password" ? "password" : "text"}
              value={values[item]}
              placeholder={item}
            />
            <div className="error-span">
              {errors[item] && touched[item] && errors[item]}
            </div>
          </div>
        )
    );
  };

  const create = (values) => {
    let parsedValues = { ...values };

    const submit = handleSend();
    setIsLoading(true);
    if (type === "School Admin") {
      parsedValues = Object.assign({}, values);
      delete parsedValues["profilePicture"];
      delete parsedValues["schoolId"];
    }
    if (type === "School") {
      parsedValues.password = "Test1234";
      submit(parsedValues, schoolToken)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success) {
            handleModalOpen({
              title: `Create`,
              description: `Created successfully`,
            });
          } else {
            handleModalOpen({
              title: `Create`,
              description: `Something went wrong, try again later`,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleModalOpen({
            title: `Create`,
            description: `Something went wrong, try again later`,
          });
        });
    } else {
      parsedValues.password = "Test1234";
      submit(parsedValues)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success) {
            handleModalOpen({
              title: `Create`,
              description: `Created successfully`,
            });
          } else {
            handleModalOpen({
              title: `Create`,
              description: `Something went wrong, try again later`,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleModalOpen({
            title: `Create`,
            description: `Something went wrong, try again later`,
          });
        });
    }
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <Formik
      initialValues={type === "School" ? schoolValues : initialValues}
      validationSchema={
        type === "School Admin"
          ? SchoolAdminSchema
          : type === "School"
          ? SchoolSchema
          : Schema
      }
      onSubmit={create}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <div className="create-container">
          {type !== "School Admin" && (
            <label className="main-image-container create">
              <input
                accept="image/*"
                type="file"
                onChange={(e) => {
                  setFieldValue(
                    type === "School" ? "MainPicture" : "profilePicture",
                    e.target.files[0]
                  );
                  if (e.target.files) {
                    const file = e.target.files[0];
                    if (file && file.size > 15 * 1024 * 1024) return false;
                    let reader = new FileReader();
                    // it's onload event and you forgot (parameters)
                    reader.onload = function (e) {
                      let image = document.getElementById("main-image");
                      image.src = e.target.result;
                    };
                    reader.readAsDataURL(file);
                  }
                }}
              />
              <img
                id="main-image"
                className={`${values["profilePicture"] ? "" : "blank-image"}`}
                src={blankImage}
                alt="main"
              />
            </label>
          )}

          {handleInputs(values, errors, touched, handleChange, handleBlur)}

          {type !== "School" && (
            <div className="register-input-container">
              <DatePicker
                initialDate={new Date()}
                setDate={(date) =>
                  setFieldValue("birthDate", moment.utc(date).format())
                }
              />
            </div>
          )}
          {type !== "School Admin" && type !== "School" && (
            <div className="register-input-container">
              <Select
                styles={customStyles}
                className="register-select"
                placeholder="School"
                options={schoolsOptions}
                onChange={(item) => setFieldValue("schoolId", item.value)}
              />
              <div className="error-span">
                {errors.schoolId && touched.schoolId && errors.schoolId}
              </div>
            </div>
          )}

          <div className="register-input-container-mobile">
            <button className="modal-button edit-button" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreateStudent;
