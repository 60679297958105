import React, { useState, useEffect } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";

const LateralMenu = ({ data, taskRef, chapterRef }) => {
  const [open, setOpen] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [itemClicked, setItemClicked] = useState(null);

  useEffect(() => {
    createButtons(data.length);
  }, []);

  const createMenu = () => {
    const menu = data.map((item, index) => {
      if (item.childrens) {
        return (
          <>
            <ListItem
              className={`${buttons[index] ? "selected" : ""}`}
              onClick={() => handleClick(index)}
              button
            >
              <div
                className={`admin-dashboard-list-text ${
                  itemClicked === index ? "active-text" : ""
                }`}
              >
                {item.name}
              </div>
            </ListItem>
            <Collapse in={buttons[index]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderChildren(item.childrens, item.name)}
              </List>
            </Collapse>
          </>
        );
      }
      return (
        <ListItem
          onClick={() => {
            item.onClick && item.onClick();
          }}
          button
        >
          <div
            className={`admin-dashboard-list-text ${
              itemClicked === index ? "active-text" : ""
            }`}
          >
            {item.name}
          </div>
        </ListItem>
      );
    });

    return menu;
  };

  const createButtons = (count) => {
    const newButtons = [];
    for (let i = 0; i < count; i++) {
      newButtons.push(false);
    }
    setButtons(newButtons);
  };

  const renderChildren = (data, name) =>
    data.map((item) =>
      (name === "Tasks" || name === "Chapters") && item.name === "List" ? (
        <ListItem
          className="collapse-item"
          ref={name === "Tasks" ? taskRef : chapterRef}
          onClick={() => item.onClick()}
          button
        >
          <div className="admin-dashboard-list-text children-text">
            {item.name}
          </div>
        </ListItem>
      ) : (
        <ListItem
          className="collapse-item"
          onClick={() => item.onClick()}
          button
        >
          <div className="admin-dashboard-list-text children-text">
            {item.name}
          </div>
        </ListItem>
      )
    );

  const handleClick = (index) => {
    //const newButtons = buttons.slice();
    setItemClicked(index);
    const newButtons = [];
    for (let i = 0; i < buttons.length; i++) {
      newButtons.push(false);
    }
    newButtons[index] = !buttons[index];
    setButtons(newButtons);
  };

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className="sidebar"
    >
      {createMenu()}
    </List>
  );
};

export default LateralMenu;
