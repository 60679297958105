import React, { useState, useEffect } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import CircularProgress from "@material-ui/core/CircularProgress";
import AssignStudents from "./AssignStudents";
import CreateWorkshop from "./CreateWorkshop";
import WorkShopAddClasses from "./WorkShopAddClasses";
import { deleteWorkShop } from "../../services/workshopServices";
import WorkshopDetails from "./WorkshopDetails";

const WorkShopList = ({
  dataList,
  updateInfo,
  handleModalOpen,
  setInformation,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const assignStudents = (item) => {
    setInformation({
      title: item.title,
      descriptionComponent: (
        <AssignStudents handleModalOpen={handleModalOpen} item={item} />
      ),
      witouthButtons: true,
    });
  };

  const handleEditWorkshop = (id) => {
    setInformation({
      title: "Edit Workshop",
      descriptionComponent: (
        <CreateWorkshop
          handleModalOpen={handleModalOpen}
          workShopId={id}
          isEdit
        />
      ),
      witouthButtons: true,
    });
  };

  const handleAddClasses = (item) => {
    setInformation({
      title: "Add classes",
      descriptionComponent: (
        <WorkShopAddClasses
          handleModalOpen={handleModalOpen}
          workShopId={item.id}
          workshop={item}
        />
      ),
      witouthButtons: true,
    });
  };

  const handleViewDetails = (item) => {
    setInformation({
      title: item.title,
      descriptionComponent: (
        <WorkshopDetails
          workshop={item}
        />
      ),
      witouthButtons: true,
    });
  };

  const handleDeleteWorkshop = (id) => {
    deleteWorkShop({ id })
      .then((res) => {
        if (res.data.success) {
          handleModalOpen({
            title: "Delete Workshop",
            description: `Workshop deleted successfully!`,
          });
          updateInfo();
        } else {
          handleModalOpen({
            title: "Error",
            description: `Something went wrong , try again later!`,
          });
        }
      })
      .catch((err) => {
        handleModalOpen({
          title: "Error",
          description: `Something went wrong , try again later!`,
        });
      });
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={"dashboard-list-container"}
    >
      {dataList.length === 0 && <div>No information available</div>}
      {dataList.length > 0 && (
        <ListItem className={`dashboard-list-item`}>
          <ListItemText className="list-item-text" primary={"Title"} />
          <ListItemText className="list-item-text" primary={""} />
          <ListItemText className="list-item-text" primary={""} />
          <ListItemText className="list-item-text" primary={""} />
          <ListItemText className="list-item-text" primary={""} />

        </ListItem>
      )}

      {dataList.map((item) => (
        <ListItem className={`dashboard-list-item`}>
          <ListItemText onClick={() => handleViewDetails(item)} className="list-item-text list-item-icon" primary={item.title} button />
          <ListItemText
            onClick={() => {
              handleAddClasses(item);
            }}
            className="list-item-icon"
            primary={"Add Classes"}
            button
          />
          <ListItemText
            onClick={() => {
              assignStudents(item);
            }}
            className="list-item-icon"
            primary={"Invite Students"}
            button
          />
          <ListItemText
            onClick={() => {
              handleEditWorkshop(item.id);
            }}
            className="list-item-icon"
            primary={"Edit"}
            button
          />
          <ListItemText
            onClick={() => {
              handleDeleteWorkshop(item.id);
            }}
            className="list-item-icon"
            primary={"Delete"}
            button
          />
        </ListItem>
      ))}
    </List>
  );
};

export default WorkShopList;
