import { 
    LOGIN_ATTEMPT, LOGIN_FAILED, LOGIN_SUCCESS, SET_LOGIN_ERROR, SET_LOG_OUT, SET_EXPIRATION_TOKEN
    } from '../actions/action-types/app-action-types'
import jwt_decode from "jwt-decode";

let token = localStorage.getItem('token');

const initState = {
    isLogged: token ? true : false,
    user: {
        firstRole: token ? jwt_decode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] : 'SUPER_ADMIN',
        approvalStatus: token ? jwt_decode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"] : 'DENIED',
        displayname: (localStorage.getItem('user.displayname') ? localStorage.getItem('user.displayname') : 'DisplayName'),
        profileImageUrl: (localStorage.getItem('user.profileImageUrl') ? localStorage.getItem('user.profileImageUrl') : 'DisplayName'),
        userName: (localStorage.getItem('user.userName') ? localStorage.getItem('user.userName') : 'DisplayName'),
        isLoading: false
    },
}

const LoginReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case LOGIN_ATTEMPT: 
            return {
                ...state, 
                error: null,
                isLogged: false,
                user: null,
                isLoading: true
            };    
        case LOGIN_FAILED: 
            return {
                ...state, 
                error: payload,
                isLogged: false,
                user: null, 
                isLoading: false
            }; 
        case LOGIN_SUCCESS: 
            return {
                ...state, 
                error: null,
                isLogged: true, 
                user: payload,
                isLoading: true
            }
        case SET_LOGIN_ERROR: 
            return{
                ...state, 
                error: payload
            }
        case SET_LOG_OUT: 
            return {
                user: null,
                isLoading: false,
                isLogged: false,
                error: null,
            };
        default: return state 
    }
}

export default LoginReducer;
