import React, { useState, useEffect } from "react";
import {
  getAllTest,
  getAllAnsweredTest,
  getAnsweredTest,
  studentGetAll,
} from "../../services/schoolServices";
import { setTask } from "../../redux/actions/schoolActions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./SelectChapter.css";

import Header from "../../components/Header";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Task from "../Task";
import Select from "react-select";

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const SelectChapter = ({ fromAdmin }) => {
  const { user } = useSelector((state) => state.loginReducer);

  const { studentList } = useSelector((state) => state.studentReducer);
  const { taskList } = useSelector((state) => state.schoolReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const [allTask, setAllTask] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [studentOptions, setStudentOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);

  const [taskId, setTaskId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [pageNum, setPageNum] = useState(2);

  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);

  useEffect(() => {
    const options = [{ value: null, label: "None selected" }];

    console.log(studentList, "studentList")
    studentList.forEach((element) => {
      options.push({ value: element.studentId, label: element.firstName?.replace(/(<([^>]+)>)/ig, "") });
		});
		
    const taskOptions = [{ value: null, label: "None selected" }];
    taskList.forEach((element) => {
      taskOptions.push({ value: element.id, label: element.title?.replace(/(<([^>]+)>)/ig, "") });
    });

    setTaskOptions(taskOptions);
    setStudentOptions(options);
    setIsLoading(true);
    if (history.location.pathname === "/select-chapter") {
      getAllTest({ PageSize: 50 })
        .then((res) => {
          console.log(res);
          setIsLoading(false);
          setAllTask(res.data.body);
          setHasNextPage(res.data.hasNextPage);
        })
        .catch((err) => setIsLoading(false));
    } else {
      getAllAnsweredTest({ PageSize: 50 })
        .then((res) => {
          setAllTask(res.data.body);
          setIsLoading(false);
          setHasNextPage(res.data.hasNextPage);
        })
        .catch((err) => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    const params = {
      StudentId: userId,
      TestTemplateId: taskId,
      PageSize: 50,
      PageNum: pageNum,
    };
    if (userId === null) {
      delete params["StudentId"];
    }

    if (taskId === null) {
      delete params["TestTemplateId"];
    }

    if (history.location.pathname === "/select-chapter") {
      getAllTest(params)
        .then((res) => {
          console.log(res.data, "res data get all test");
          setIsLoading(false);
          setAllTask(res.data.body);
          setHasNextPage(res.data.hasNextPage);
        })
        .catch((err) => setIsLoading(false));
    } else {
      getAllAnsweredTest(params)
        .then((res) => {
          console.log(res.data, "res data get all test");

          setAllTask(res.data.body);
          setHasNextPage(res.data.hasNextPage);
          setIsLoading(false);
        })
        .catch((err) => setIsLoading(false));
    }
  }, [userId, taskId, pageNum]);

  const handleCrateTask = () => {
    if (history.location.pathname === "/select-chapter") {
      return allTask.map((item) => (
        <div
          className="task-select-item"
          onClick={() => handleClickTask(item.template, item.id)}
        >
          {item.title?.replace(/(<([^>]+)>)/ig, "")}
        </div>
      ));
    } else if (history.location.pathname === "/my-answered-tasks") {
      return allTask.map(
        (item) =>
          item.answeredBy === user.userName && (
            <>
              <div
                className="task-select-item"
                onClick={() => handleClickTask(null, item.userTestId)}
              >
								<div className="crimson" dangerouslySetInnerHTML={{__html: item.testTemplateName }} />
                <div className="subtitle">Answered by: {item.answeredBy}</div>
              </div>
            </>
          )
      );
    } else {
      return allTask.map((item) => (
        <div
          className="task-select-item"
          onClick={() => handleClickTask(null, item.userTestId)}
        >
          <div className="crimson" dangerouslySetInnerHTML={{__html: item.testTemplateName }} />

          <div className="subtitle">Answered by: {item.answeredBy}</div>
        </div>
      ));
    }
  };

  const handleClickTask = async (task, id) => {
    if (history.location.pathname === "/select-chapter") {
      dispatch(setTask({ template: task, templateId: id, isComplete: false }));
      history.push("/task");
    } else {
      getAnsweredTest({ userTestId: id }).then((res) => {
        const response = res.data.body;
        dispatch(
          setTask({
            template:
              response.answeredTemplates[response.answeredTemplates.length - 1],
            templateId: id,
            isComplete: true,
          })
        );
      });
      if (!fromAdmin) {
        history.push("/task");
      } else {
        setOpenModal(true);
      }
    }
  };
  const debounce = (func, wait, immediate = false) => {
    let timeout;
    return function (...args) {
      const later = () => {
        timeout = null; // added this to set same behaviour as ES5
        if (!immediate) func.apply(this, args); // this is called conditionally, just like in the ES5 version
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(this, args);
    };
  };

  const search = debounce((toSearch) => {
    studentGetAll({ ImportantWord: toSearch }).then((res) => {
      const response = res.data.body.slice();
      console.log(response, "response on searhc")
      const options = [{ value: null, label: "None selected" }];
      response.forEach((element) => {
        options.push({ value: element.studentId, label: element.firstName?.replace(/(<([^>]+)>)/ig, "") });
      });

      setStudentOptions(options);
      console.log(options, "options search debounce")
    });
  }, 1000);

  const handleInputChange = (inputValue) => {
    console.log(inputValue);
    search(inputValue);
  }

  console.log(studentOptions, "studentOptions all time")
  return (
    <div
      className={`task-container ${
        fromAdmin ? "" : "select-chapter-container"
      }`}
    >
      {!fromAdmin && <Header />}
      <div className="task-select-searchs">
        <Select
          styles={customStyles}
          isDisabled={false}
          className="register-select"
          placeholder="Select by task"
          options={taskOptions}
          isSearchable
          onChange={(item) => {
            setTaskId(item.value);
						setPageNum(1);
						setHasPrevPage(false);
          }}
        />

        <Select
          onInputChange={handleInputChange}
          styles={customStyles}
					isDisabled={false}
					isSearchable
          className="register-select"
          placeholder="Select by student"
          options={studentOptions}
          onKeyUp={(e) => {console.log(e.target.value , "event key up")}}
          onChange={(item) => {
            setUserId(item.value);
						setPageNum(1);
						setHasPrevPage(false);
          }}
        />
      </div>

      <div className="page-selector-container">
        {hasPrevPage ? (
          <div
            className="handle-page-button"
            onClick={() => {
              if (pageNum > 1) {
                if (pageNum === 2) {
                  setHasPrevPage(false);
                }
                setPageNum(pageNum - 1);
              }
            }}
          >
            Prev Page
          </div>
        ) : (
          <div />
        )}
        {hasNextPage && (
          <div
            className="handle-page-button"
            onClick={() => {
              setPageNum(pageNum + 1);
              setHasPrevPage(true);
            }}
          >
            Next Page
          </div>
        )}
      </div>

      {isLoading ? (
        <div className="loading-container">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="task-content">
          {allTask && allTask.length > 0 ? (
            handleCrateTask()
          ) : (
            <div>No tasks available</div>
          )}
        </div>
      )}
      <Modal
        open={openModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setOpenModal(false)}
        className="modal-container"
      >
        <Task fromAdmin />
      </Modal>
    </div>
  );
};

export default SelectChapter;
