export const taskWithRow = (string) => {
  const handleString = string.split("\n");

  let startRow = 0;
  let finalRow = 0;
  let newString = [];
  handleString.forEach( (item, index) => {
    if(!item.includes("<text>") && !item.includes("<input>")){
      newString.push(item);
    }
    
    if(item.includes("<text>")){
      if(handleString[index + 1].includes("<input>")){
        // handleString[index] = "<row>" + item;
        startRow = index;
      } else {
        newString.push(item);
      }
    }
    if(item.includes("<input>")){
      if(!handleString[index + 1].includes("<input>")) {
        // handleString[index] = item + "</row>";
        finalRow = index;
        let row = "";
        for (let i = startRow; i <= finalRow; i++) {
          row = row + handleString[i];
        }
        newString.push("<row>" + row + "</row>");
      }
    }
  });

  const pages = newString.slice();

  let stringPages = "";
  pages.forEach((page) => {
    stringPages = stringPages + page + "\n";
  });

  return stringPages;
} 