import React, { useState, useEffect } from 'react';
import './MyTests.css';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { studentGetMyTest } from '../../services/schoolServices';
import { useHistory } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setTask } from "../../redux/actions/schoolActions"
import CircularProgress from "@material-ui/core/CircularProgress";
import { getMyChaptersProgress } from '../../services/schoolServices'

function MyTests(props) {
  const [myTests, setMyTests] = useState([]);
  const [myChaptersProgress, setMyChaptersProgress] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const resStudentGetMyTest = await studentGetMyTest({});
      if(resStudentGetMyTest.data.success) {
        setMyTests(resStudentGetMyTest.data.body);
      }

      const responseGetMyChaptersProgress = await getMyChaptersProgress({});
      if(responseGetMyChaptersProgress.data.success) {
        setMyChaptersProgress(responseGetMyChaptersProgress.data.body);
      }
      setIsLoading(false); 
    }
    fetchData();
  }, []);

  
  const _renderTasks = () => {
    let tareas = []
    for (let index = 0; index < myChaptersProgress.length; index++) {
        const chapter = myChaptersProgress[index];
        let tasksInChapter = [];
        chapter.competenciesProgress.forEach( competencieProgress => {
            for (let index = 0; index < myTests.length; index++) {
                const test = myTests[index];
                if(competencieProgress.userTestId === test.userTestId) {
                    tasksInChapter.push(test);
                }
            }
        })
        let chapterWithTasks = {
            chapterName: chapter.chapterName,
            tasks: tasksInChapter
        }
        tareas.push(chapterWithTasks);
    }
      
    return tareas.length > 0 ? tareas.map( element => {
        return (
            <>
                <li className="item-my-test-title">{element.chapterName}</li>
                {
                    element.tasks.map( e => {
                        return <li className="item-my-test" dangerouslySetInnerHTML={{__html: e.testTemplateName}} onClick={() => _redirectToTask(e)}/>
                    })
                }
            </>
            )
          }) : <div>No Information Available</div>
      } 
      
  const _redirectToTask = (task) => {
    props.setTask({ template: task.baseTemplate,
                    templateId: task.userTestId,
                    isComplete: true
                });
    history.push('/task');
  }

  return (
    <div className="bg-grid">
        <Header />
        { isLoading ? (
            <div className="loading-container">
                <CircularProgress color="secondary" />
            </div>
        ) : (
            <section className='appWrapper'>
                <div className="mytest-container" style={{marginTop: 100, position: 'relative'}}>
                    <ul className="itemList">
                        {_renderTasks()}
                    </ul>
                </div>
            </section>
        )}
        <Footer />
    </div>
  );
}
        

const mapStateToProps = (state) =>{
    return {
    }
  }

const mapDispatchToProps= (dispatch)=>{
    return{
        setTask: (task) =>  {dispatch(setTask(task))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MyTests));