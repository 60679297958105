import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "./AcceptInvitation.css";
import {
  checkInvitationToken,
  acceptInvitation,
} from "../../services/invitationServices";
import CircularProgress from "@material-ui/core/CircularProgress";
import Register from "../Register";
import AcceptInvitationModal from "./AcceptInvitationModal";

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const AcceptInvitation = ({ match, history }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userExist, setUserExist] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userRegisterd, setUserRegistered] = useState(false);
  const token = match.params.token;

  useEffect(() => {
    setIsLoading(true);
    checkInvitationToken(token)
      .then((res) => {
        if (!res.data.success) {
          setUserExist(false);
        } else {
          setUserExist(true);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err.response.status === 401));
  }, [userRegisterd]);
  
  const handleAcceptInvitation = () => {
    acceptInvitation(token).then((res) => toggleModal());
  };

  const onRegister = () => {
    setUserRegistered(true);
  };
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const onAccept = () => {
    setShowModal(false);
    history.replace("/")
  }

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : userExist ? (
    <div className="invitation-container">
      <Header />
      <div className="accept-invitation-container">
        <div onClick={handleAcceptInvitation} className="login-submit-button">
          Accept Invitation
        </div>
      </div>
      <Footer />
      <AcceptInvitationModal show={showModal} toggleModal={toggleModal} title="Invitation Accepted" description={"Invitation Accepted"} onAccept={onAccept}/>
    </div>
  ) : (
    <Register isWorkshop token={match.params.token} history={history} onRegister={onRegister}/>
  );
};

export default AcceptInvitation;
