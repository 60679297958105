import React, { Component } from 'react';
import './AutoEvaluationModal.css';
import { withRouter } from 'react-router-dom';
import { Polar } from 'react-chartjs-2';
import { isMobile } from 'react-device-detect'

class AutoEvaluationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: ['SELF MANAGEMENT', 'RESPONSIBLE DECISION MAKING', 'RELATIONSHIP SKILLS', 'SOCIAL AWARENESS', 'SELF AWARENESS'],
                datasets: [{
                    label: '# of Votes',
                    data: [1, 4, 4, 5, 3],
                    backgroundColor: [
                        '#F07F2F',
                        '#FFCA38',
                        '#91B93E',
                        '#91B93E',
                        '#F07F2F',
                    ],
                    borderColor: [
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                    ],
                    borderWidth: 0.5
                }]
            },
            options: {
                legend: {
                    display: false
                },
                scale: {
                    ticks: {
                        max: 5,
                        z: 3
                    },
                },
                pointLabels: {
                    fontSize: 36
                },
                tooltips: {
                    caretSize: 0,
                    enabled: true,
                    bodyFontSize: 10,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.labels[tooltipItem.index] + ' ' + tooltipItem.value;
        
                            return label;
                        }
                    }
                },
            }
        }
    }

    createChapterEvaluation = async () => {
        if(this.props.history) this.props.history.push('/land');
    }

    componentDidMount() {
            this.setState( prevState => {
                let data = this.state.data;
                data.datasets[0].data = this.props.answerValues.map( e => { return e} )
                return data
            })
    }

    _renderDesktop() {
        return(
            <div className="autoEvaluation-modal-container">
                <div className="title">Thanks for completeting the assessment!</div>
                <div className="subtitle">You can visualize your results in MovementBE Land</div>
                <div className="chart" >
                    <Polar 
                        data={this.state.data}
                        width={200}
                        height={200}
                        options={this.state.options}
                        min={5}
                    />
                </div>
                <div className="modal-next-button" onClick={this.createChapterEvaluation} ><span>Next</span></div>
            </div>
        )
    }

    _renderMobile() {
        return(
            <div className="autoEvaluation-modal-container-mobile">
                <div className="title-mobile">Thanks for completeting the assessment!</div>
                <div className="subtitle">You can visualize your results in MovementBE Land</div>
                <div className="chart" >
                    <Polar 
                        data={this.state.data}
                        width={200}
                        height={200}
                        options={this.state.options}
                        min={5}
                    />
                </div>
                <div className="modal-next-button" onClick={this.createChapterEvaluation} ><span>Next</span></div>
            </div>
        )
    }

    render() {
        if (isMobile) {
            return this._renderMobile()
        } else {
            return this._renderDesktop()
        }
    }
}

export default withRouter(AutoEvaluationModal);