import React, { useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { approveStudent, removeTest } from "../../services/schoolServices";
import CreateTest from "../../components/CreateTest";
import { useDispatch } from "react-redux";
import { setTask } from "../../redux/actions/schoolActions";
import Task from "../Task";

const TaskList = ({ dataList, updateInfo, handleModalOpen }) => {
	const dispatch = useDispatch();
	const [taskToEdit, setTaskToEdit] = useState(null);

	return taskToEdit ? (
		<>
			<h3>Edit Task</h3>
			<CreateTest taskId={taskToEdit} />
		</>
	) : (
			<>
				<h3>Task List</h3>
				<List
					component="nav"
					aria-labelledby="nested-list-subheader"
					className={"dashboard-list-container"}
				>
					{dataList.length === 0 && <div>No information available</div>}
					{dataList.length > 0 && (
						<ListItem className={`dashboard-list-item`}>
							<ListItemText className="list-item-text" primary={"Task title"} />
							<ListItemText
								className="list-item-text"
								primary={"Task description"}
							/>
							<ListItemText className="list-item-text" primary={""} />

							<ListItemText
								className="list-item-icon color-transparent"
								primary={"Approved"}
							/>
							<ListItemText
								className="list-item-icon color-transparent"
								primary={"Delete"}
								button
							/>
						</ListItem>
					)}
					{dataList.map((item) => (
						<ListItem className={`dashboard-list-item`}>
							<ListItemText
								className="list-item-text button-text"
								primary={item.title.replace(/(<([^>]+)>)/ig, "")}
								button
								onClick={() => {
									dispatch(
										setTask({
											template: item.template,
											templateId: item.id,
											isComplete: true,
										})
									);
									handleModalOpen({
										title: "Task",
										descriptionComponent: <Task fromAdmin />,
									});
								}}
							/>
							<ListItemText
								className="list-item-text"
								primary={
									item.description.length < 30
										? item.description.replace(/(<([^>]+)>)/ig, "")
										: item.description.replace(/(<([^>]+)>)/ig, "").substring(0, 30) + "..."
								}
							/>
							<ListItemText className="list-item-text" primary={""} />

							<ListItemText
								onClick={() => {
									setTaskToEdit(item.id);
								}}
								className="list-item-icon"
								primary={"Edit"}
								button
							/>
							<ListItemText
								onClick={() =>
									handleModalOpen({
										title: "Are you sure?",
										description: `You want to delete ${item.title} task?`,
										acceptAction: () =>
											removeTest({ id: item.id })
												.then((res) => {
													updateInfo();
													handleModalOpen({
														title: "Task Deleted",
														description: `The task ${item.title} was deleted`,
													});
												})
												.catch((err) => {
													handleModalOpen({
														title: "There was an error while deleting",
														description: `Try again later`,
													});
												}),
									})
								}
								className="list-item-icon"
								primary={"Delete"}
								button
							/>
						</ListItem>
					))}
				</List>
			</>
		);
};

export default TaskList;
