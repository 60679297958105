import React, { useEffect, useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  createClass,
  getAllSchoolService,
  counselorGetAll,
  schoolAdminGetAll,
} from "../../services/schoolServices";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

const Schema = Yup.object().shape({
  PersonInChargeEmail: Yup.string().required("You must add a person in charge!"),
  Description: Yup.string().required("You must add a description!"),
  Title: Yup.string().required("You must add a title"),
  SchoolId: Yup.string().required("You must select a school id"),
});

const initialValues = {
  SchoolId: "",
  Title: "",
  Description: "",
  PersonInChargeEmail: "",
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const CreateClass = ({ type, handleModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schoolsOptions, setSchoolOptions] = useState([]);
  const [personInChargeOptions, setPersonInChargeOptions] = useState([]);
  const [schoolToken, setSchoolToken] = useState(null);
  const [personInChargeLoading, setPersonInChargeLoading] = useState(false);
  useEffect(() => {
    getAllSchoolService()
      .then((res) => {
        const options = [];
        const response = res.data.body;
        console.log(response, "respnses create clas!")
        response.forEach((element) => {
          if(element.approvalStatus === 3) {
            options.push({ value: element.id, label: element.name });
          }
        });
        setSchoolOptions(options);
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  }, []);

  const handleInputs = (values, errors, touched, handleChange, handleBlur) => {
    const valuesArray = Object.keys(initialValues);
    return valuesArray.map(
      (item) =>
        item !== "SchoolId" &&
        item !== "PersonInChargeEmail" && (
          <div key={item} className={"register-input-container"}>
            <input
              className="register-text-input"
              name={`${item}`}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              type={item === "Password" ? "password" : "text"}
              value={values[item]}
              placeholder={item}
            />
            <div className="error-span">
              {errors[item] && touched[item] && errors[item]}
            </div>
          </div>
        )
    );
  };

  const create = (values) => {
    createClass(values)
      .then((res) => {
        if(res.data.success){
          handleModalOpen({
            title: "Create Class",
            description: `Class created successfully`,
          });
        } else {
          handleModalOpen({
            title: "Create Class",
            description: `There was an error creating the class, please try again later`,
          });
        }
      })
      .catch((err) => {
        handleModalOpen({
          title: "Create Class",
          description: `There was an error creating the class, please try again later`,
        });
      });
  };

  const searchPersonInCharge = async (schoolId) => {
    setPersonInChargeLoading(true);
    try {
      const allCounselors = await counselorGetAll({ schoolId });
      const allSchoolAdmin = await schoolAdminGetAll({ schoolId });
      const options = [];
      allCounselors.data.body.forEach((element) => {
        options.push({ value: element.email, label: element.userName });
      });
      allSchoolAdmin.data.body.forEach((element) => {
        console.log(element.email, "email asdmins!!!")

        options.push({ value: element.email, label: element.userName });
      });
      setPersonInChargeOptions(options);
      setPersonInChargeLoading(false);
    } catch (error) {
      console.log(error, "error catch search person in charge");
    }
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Schema}
      onSubmit={create}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <div className="create-container">
          {handleInputs(values, errors, touched, handleChange, handleBlur)}

          {type !== "School Admin" && type !== "School" && (
            <div className="register-input-container">
              <Select
                styles={customStyles}
                className="register-select"
                placeholder="School"
                options={schoolsOptions}
                onChange={(item) => {
                  searchPersonInCharge(item.value);
                  setFieldValue("SchoolId", item.value);
                }}
              />
              <div className="error-span">
                {errors.SchoolId && touched.SchoolId && errors.SchoolId}
              </div>
            </div>
          )}

          {type !== "School Admin" && type !== "School" && (
            <div className="register-input-container">
              {personInChargeLoading ? (
                <div
                  style={{ width: "100%", height: "100%" }}
                  className="loading-container"
                >
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <>
                  <Select
                    styles={customStyles}
                    isDisabled={!values.SchoolId || values.SchoolId === ""}
                    className="register-select"
                    placeholder="Person in Charge"
                    options={personInChargeOptions}
                    onChange={(item) =>
                      setFieldValue("PersonInChargeEmail", item.value)
                    }
                  />
                  <div className="error-span">
                    {errors.schoolId && touched.schoolId && errors.schoolId}
                  </div>
                </>
              )}
            </div>
          )}

          <div className="register-input-container-mobile">
            <button className="register-submit-button" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreateClass;
