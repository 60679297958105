import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./MyWorkShops.css";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMobile } from "react-device-detect";
import blankImage from "../../utils/images/blank_user.png";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import Modal from "@material-ui/core/Modal";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getMyWorkShops } from "../../services/workshopServices";
import { editUser, getMyData } from "../../services/securityServices";
import datesHelper from "../../utils/datesHelper";

const MyWorkShops = (data) => {
  const [isLoading, setIsLoading] = useState(false);
  const [myWorkshops, setWorkShops] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalBody, setModalBody] = useState({});

  useEffect(() => {
    setIsLoading(true);
    getMyWorkShops().then((res) => {
      setIsLoading(false);
      setWorkShops(res.data.body);
      console.log(res.data.body, "res user setWorkShops");
    });
  }, []);
  
  const rendenModalBody = ({
    title,
    description,
    descriptionComponent,
    acceptAction,
    cancelAction,
  }) =>
    descriptionComponent ? (
      <div className="modal-body-container">
        {descriptionComponent}
        <div className="modal-button-container">
          {acceptAction && (
            <button className="modal-button" onClick={acceptAction}>
              yes
            </button>
          )}
          <div
            className="modal-button"
            onClick={() => {
              cancelAction && cancelAction();
              setModalOpen(false);
            }}
          >
            close
          </div>
        </div>
      </div>
    ) : (
      <div className="modal-body">
        <div className="modal-title">{title}</div>
        <div>{description}</div>
        <div className="modal-button-container">
          {acceptAction && (
            <button className="modal-button" onClick={acceptAction}>
              yes
            </button>
          )}
          <div
            className="modal-button"
            onClick={() => {
              cancelAction && cancelAction();
              setModalOpen(false);
            }}
          >
            close
          </div>
        </div>
      </div>
    );

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div className="my-workshop-container">
      <Header />
      <div className="my-workshop-list-container">
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={"dashboard-list-container"}
        >
          {myWorkshops.length === 0 && <div>No information available</div>}
          {myWorkshops.length > 0 && (
            <ListItem className={`dashboard-list-item`}>
              <ListItemText className="list-item-text" primary={"Title"} />
              <ListItemText
                className="list-item-text"
                primary={"Starting Date"}
              />
              <ListItemText className="list-item-text" primary={"End Date"} />
              <ListItemText className="list-item-text" primary={"Zoom Link"} />
            </ListItem>
          )}

          {myWorkshops.map((item) => (
            <ListItem className={`dashboard-list-item`}>
              <ListItemText className="list-item-text" primary={item.title} />
              <ListItemText
                className="list-item-text"
                primary={datesHelper(item.startingDate)}
              />
              <ListItemText
                className="list-item-text"
                primary={datesHelper(item.endDate)}
              />
              <ListItemText
                onClick={() => {
                  if (!item.zoomLink.includes("http")) {
                    window.open("https:/" + item.zoomLink, "_blank");
                  } else {
                    window.open(item.zoomLink, "_blank");
                  }
                }}
                className="list-item-text zoom-link"
                primary={item.zoomLink}
              />
            </ListItem>
          ))}
        </List>
      </div>
      <Modal
        open={modalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setModalOpen(false)}
        className="modal-container"
      >
        {rendenModalBody(modalBody)}
      </Modal>
      <Footer />
    </div>
  );
};

export default MyWorkShops;
