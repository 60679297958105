import React from "react";
import bubbleImage from "../../utils/images/red_bubble.png";
import nateImage from "../../utils/images/nate_logo.png";
import points from "../../utils/images/points.png";
import { isMobile } from 'react-device-detect'

const Paragraph = ({ isFirst, children, onClick, withoutNate, customBubbleImage, mobile }) => {
  return (
  <div className={(isMobile || mobile) ? "next-button-container-mobile" : "next-button-container"}>
    {isFirst && <img className="points-image" src={points} alt="points" /> }

    <div className={isMobile ? `next-button-mobile ${isFirst ? "next-button-mobile-first" : ""}` : "next-button"}>
        <div className="second-triangle" />
      <div onClick={onClick} className="poem-triangle">
      <div className="triangle" />

          <div className="bubble-text">
            {children}
          </div>
      </div>     
    </div>
    {
      !withoutNate && <img className="nate-image" src={nateImage} alt="nate" />
    }
  </div>
)};

export default Paragraph;
