import React, { useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  approveCounselor,
  removeCounselor,
  editCounselor,
  getCounselor,
  getAllSchoolService,
  counselorAddSchool,
  counselorRemoveSchool,
  getMySchools,
} from "../../services/schoolServices";
import moment from "moment";
import blankImage from "../../utils/images/blank_user.png";
import DatePicker from "../../components/Admin/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useSelector } from "react-redux";

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const CounselorList = ({
  dataList,
  updateInfo,
  handleModalOpen,
  setInformation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [counselorSelected, setCounselorSelected] = useState(null);
  const [counselorInformation, setCounselorInformation] = useState(null);
  const [studentSchool, setStudentSchool] = useState([]);
  const [schools, setSchools] = useState([]);
  const [renderType, setRenderType] = useState("counselorInformation");
  const { user } = useSelector((state) => state.loginReducer);

  const handleDataInformation = (data) => {
    const orderedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.displayName,
      userName: data.userName,
      profilePictureUrl: data.profilePictureUrl,
      email: data.email,
      phone: data.phone,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      country: data.country,
      state: data.state,
      city: data.city,
      zipCode: data.zipCode,
      birthDate: data.birthDate,
    };

    const newData = Object.keys(orderedData).map((key) => {
      return (
        key !== "id" &&
        key !== "approvalStatus" && (
          <div
            key={key}
            className={`input-container w-50 ${
              key === "birthDate" || key === "profilePictureUrl" ? "w-100" : ""
            }`}
          >
            <div className="edit-input-label">{key}</div>
            {typeof data[key] === "boolean" ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={data[key]}
                      onChange={() => handleChange(key, !data[key], data)}
                    />
                  }
                />
              </FormGroup>
            ) : key === "profilePictureUrl" ? (
              <label className="main-image-container">
                <input
                  name={key}
                  id={key}
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    handleChange(key, e.target.files[0], data);
                  }}
                />
                <img
                  id="main-image"
                  className={`${data[key] ? "" : "blank-image"}`}
                  src={data[key] ? data[key] : blankImage}
                  alt="main"
                />
              </label>
            ) : key === "birthDate" ? (
              <DatePicker
                initialDate={new Date(data[key])}
                setDate={(date) => handleChange(key, date, data)}
              />
            ) : (
              <input
                className={`text-input`}
                name={key}
                onChange={(e) => handleChange(key, e.target.value, data)}
                placeholder={key}
                // defaultValue={key === "birthDate" ? `${month}-${day}-${year}` : data[key]}
                defaultValue={data[key]}
              />
            )}
          </div>
        )
      );
    });

    return (
      <div className="edit-container">
        <h3 className="edit-title">Edit</h3>
        {newData}
      </div>
    );
  };

  const renderUserInformation = (type) => {
    const orderedData = counselorInformation && {
      profilePictureUrl: counselorInformation.profilePictureUrl,
      firstName: counselorInformation.firstName,
      lastName: counselorInformation.lastName,
      displayName: counselorInformation.displayName,
      userName: counselorInformation.userName,
      schoolsIds: counselorInformation.schoolsIds,
      email: counselorInformation.email,
      phone: counselorInformation.phone,
      addressLine1: counselorInformation.addressLine1,
      addressLine2: counselorInformation.addressLine2,
      country: counselorInformation.country,
      state: counselorInformation.state,
      city: counselorInformation.city,
      zipCode: counselorInformation.zipCode,
      birthDate: counselorInformation.birthDate,
    };

    const information = counselorInformation && Object.keys(orderedData);

    const typeToRender = {
      counselorInformation: (
        <div className="user-information-container">
          {information &&
            information.map((key) => {
              return key === "profilePictureUrl" ? (
                <div className="counselor-prifle-image-container">
                  <div className="user-image-container">
                    <img
                      id="main-image"
                      className={`${
                        counselorInformation[key] ? "" : "blank-image"
                      }`}
                      src={
                        counselorInformation[key]
                          ? counselorInformation[key]
                          : blankImage
                      }
                      alt="main"
                    />
                  </div>
                </div>
              ) : key === "schoolsIds" ? (
                <div key={key} className={`input-container w-50`}>
                  <div className="edit-input-label">Assigned Schools</div>
                  {counselorInformation["schoolsIds"] && counselorInformation["schoolsIds"].length > 0 && counselorInformation[key].map((element) => (
                    <div>{element}</div>
                  ))}
        
                </div>
              ) : (
                <div key={key} className={`input-container w-50`}>
                  <div className="edit-input-label">{key}</div>
                  {key === "approvalStatus" ? (
                    <div>
                      {counselorInformation[key] < 3 ? "Denied" : "Approved"}
                    </div>
                  ) : (
                    <div>
                      {counselorInformation[key] !== "null"
                        ? counselorInformation[key]
                        : ""}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      ),
      schools: (
        <div className="classes-container">
          <div className="all-classes-container">
            <div className="class-item">
              <div>All approved Schools</div>
            </div>
            {renderSchools()}
          </div>
          <div className="all-classes-container">
            <div className="class-item">
              <div>Asigned School</div>
            </div>
            {renderStudentSchool()}
          </div>
        </div>
      ),
    };
    return typeToRender[type];
  };

  const renderStudentSchool = () => {
    const removeSchool = (data, index, id) => {
      const parsedItem = Object.assign({}, counselorInformation);
      delete parsedItem["profilePictureUrl"];
      counselorRemoveSchool({
        schoolId: id,
        counselorId: counselorInformation.id,
      })
        .then((res) => {
          handleModalOpen({
            title: `Counselor Add School`,
            description: "School removed successfully",
          });
        })
        .catch((err) => {
          handleModalOpen({
            title: `Counselor Add School`,
            description: "Something went wrong, try again later",
          });
        });

      const newSchools = schools.slice();
      const newCounselorsSchools = studentSchool.slice();
      newSchools.push(data);
      newCounselorsSchools.splice(index, 1);
      setSchools(newSchools);
      setStudentSchool(newCounselorsSchools);
    };

    return studentSchool.map((item, index) => item && (
      <div key={"student-" + index} className="class-item">
        <div>{item.name}</div>
        <div
          className="add-button"
          onClick={() => removeSchool(item, index, item.id)}
        >
          Remove School
        </div>
      </div>
    ));
  };

  const renderSchools = () => {
    const addSchool = (data, index, id) => {
      const parsedItem = Object.assign({}, counselorInformation);
      delete parsedItem["profilePictureUrl"];
      counselorAddSchool({
        schoolId: id,
        counselorId: counselorInformation.id,
      })
        .then((res) => {
          handleModalOpen({
            title: `Counselor Add School`,
            description: "School added successfully",
          });
        })
        .catch((err) => {
          handleModalOpen({
            title: `Counselor Add School`,
            description: "Something went wrong, try again later",
          });
        });

      const newSchools = schools.slice();
      const newCounselorsSchools = studentSchool.slice();
      newCounselorsSchools.push(data);
      newSchools.splice(index, 1);
      setSchools(newSchools);
      setStudentSchool(newCounselorsSchools);
    };

    return schools.map(
      (item, index) =>
      item && item.approvalStatus > 2 && (
          <div className="class-item">
            <div>{item.name}</div>
            <div
              className="add-button"
              onClick={() => addSchool(item, index, item.id)}
            >
              Assign School
            </div>
          </div>
        )
    );
  };

  const handleChange = (item, value, data) => {
    const newEdit = Object.assign(data);
    if (item === "profileImageUrl") {
      if (value) {
        const file = value;
        if (file && file.size > 15 * 1024 * 1024) return false;
        let reader = new FileReader();
        // it's onload event and you forgot (parameters)
        reader.onload = function (e) {
          let image = document.getElementById("main-image");
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
        newEdit["ProfilePicture"] = value;
      }
    } else {
      newEdit[item] = value;
    }
  };

  const displayEditModal = (counselor) => {
    const item = counselor;
    console.log(item, "counselorInformation item");
    item.schoolId = counselor["schoolsIds"][0];

    delete item["schoolsIds"];
  
    setInformation({
      title: "Counselor Edit",
      descriptionComponent: handleDataInformation(item),
      acceptAction: (e) => {
        e.target.disabled = true;
        editCounselor({
          ...item,
          birthDate: moment.utc(item.birthDate).format(),
        })
          .then((res) => {
            if (res.data.success) {
              handleModalOpen({
                title: `Counselor Edit`,
                description: `The Counselor ${item.userName} was edited`,
              });
            } else {
              handleModalOpen({
                title: "There was an error while editing",
                description: `Try again later`,
              });
            }
            updateInfo();
          })
          .catch((err) => {
            handleModalOpen({
              title: "There was an error while editing",
              description: `Try again later`,
            });
          });
      },
    });
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : !counselorSelected && !counselorInformation ? (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={"dashboard-list-container"}
    >
      {dataList.length === 0 && <div>No information available</div>}
      {dataList.length > 0 && (
        <ListItem className={`dashboard-list-item`}>
          <ListItemText className="list-item-text" primary={"Username"} />
          <ListItemText className="list-item-text" primary={"Last Name"} />
          <ListItemText className="list-item-text" primary={"Email"} />
        </ListItem>
      )}
      {dataList.map((item) => (
        <ListItem
          onClick={() => {
            setCounselorSelected(item.id);
            setIsLoading(true);
            getCounselor({ counselorId: item.id })
              .then((res) => {
                setIsLoading(false);
                setCounselorInformation(res.data.body);
                setStudentSchool(res.data.body.schoolsIds);
              })
              .catch(() => {
                handleModalOpen({
                  title: "Error",
                  description: `Try again later`,
                });
                setIsLoading(false);
              });
          }}
          className={`dashboard-list-item list-item-button`}
        >
          <ListItemText className="list-item-text" primary={item.userName} />
          <ListItemText className="list-item-text" primary={item.lastName} />
          <ListItemText className="list-item-text" primary={item.email} />
        </ListItem>
      ))}
    </List>
  ) : (
    <div className="information-container">
      <div className="action-buttons">
        {user.firstRole === "SUPER_ADMIN" && (
          <>
            <div
              className="user-action-buttons"
              onClick={() => {
                handleModalOpen({
                  title: "Are you sure?",
                  description: `You want to ${
                    counselorInformation.approvalStatus < 3
                      ? "Approve"
                      : "Disapprove"
                  } ${counselorInformation.userName}?`,
                  acceptAction: (e) => {
                    e.target.disabled = true;
                    approveCounselor({
                      CounselorId: counselorInformation.id,
                      Approval: counselorInformation.approvalStatus < 3,
                    })
                      .then((res) => {
                        updateInfo();
                        if (res.data.success) {
                          handleModalOpen({
                            title: `Counselor ${
                              counselorInformation.approvalStatus < 3
                                ? "Approved"
                                : "Disapproved"
                            }`,
                            description: `The Counselor ${
                              counselorInformation.userName
                            } was ${
                              counselorInformation.approvalStatus < 3
                                ? "approved"
                                : "disapproved"
                            }`,
                          });
                        } else {
                          handleModalOpen({
                            title: "There was an error while approving",
                            description: `Try again later`,
                          });
                        }
                      })
                      .catch((err) => {
                        handleModalOpen({
                          title: "There was an error while approving",
                          description: `Try again later`,
                        });
                      });
                  },
                });
              }}
            >
              {counselorInformation && counselorInformation.approvalStatus < 3
                ? "Approve"
                : "Disapprove"}
            </div>
            <div
              className="user-action-buttons"
              onClick={() => {
                setIsLoading(true);
                getCounselor({ counselorId: counselorInformation.id })
                  .then((res) => {
                    setIsLoading(false);
                    displayEditModal(res.data.body);
                  })
                  .catch(() => {
                    handleModalOpen({
                      title: "Error",
                      description: `Try again later`,
                    });
                    setIsLoading(false);
                  });
              }}
            >
              Edit
            </div>
            <div
              className="user-action-buttons"
              onClick={(e) => {
                handleModalOpen({
                  title: "Are you sure?",
                  description: `You want to delete ${counselorInformation.userName} counselor?`,
                  acceptAction: () => {
                    e.target.disabled = true;
                    removeCounselor({ id: counselorInformation.id })
                      .then((res) => {
                        updateInfo();
                        if (res.data.success) {
                          handleModalOpen({
                            title: "Counselor Deleted",
                            description: `The counselor ${counselorInformation.userName} was deleted`,
                          });
                        } else {
                          handleModalOpen({
                            title: "There was an error while deleting",
                            description: `Try again later`,
                          });
                        }
                      })
                      .catch((err) => {
                        handleModalOpen({
                          title: "There was an error while deleting",
                          description: `Try again later`,
                        });
                      });
                  },
                });
              }}
            >
              Delete
            </div>
          </>
        )}
        <div
          className="user-action-buttons"
          onClick={() => {
            setIsLoading(true);
            setRenderType("schools");

            if (counselorInformation.approvalStatus > 2) {
              if (user.firstRole === "SUPER_ADMIN") {
                getAllSchoolService()
                  .then((res) => {
                    const response = res.data.body.slice();
                    const counselorShools = [];

                    res.data.body.forEach((el) => {
                      counselorInformation.schoolsIds.forEach((item) => {
                        if (el.id === item) {
                          const index = response.indexOf(el);
                          if (index > -1) {
                            counselorShools.push(el);
                            response.splice(index, 1);
                          }
                        }
                      });
                    });
                    setIsLoading(false);
                    setSchools(response);
                    setStudentSchool(counselorShools);
                  })
                  .catch((err) => setIsLoading(false));
              } else {
                getMySchools()
                  .then((res) => {
                    const response = res.data.body.slice();
                    const counselorShools = [];

                    res.data.body.forEach((el) => {
                      counselorInformation.schoolsIds.forEach((item) => {
                        if (el.id === item) {
                          const index = response.indexOf(el);
                          if (index > -1) {
                            counselorShools.push(el);
                            response.splice(index, 1);
                          }
                        }
                      });
                    });
                    setIsLoading(false);
                    setSchools(response);
                    setStudentSchool(counselorShools);
                  })
                  .catch((err) => setIsLoading(false));
              }
            } else {
              setInformation({
                title: "Assign School",
                descriptionComponent: (
                  <div>This counselor is not approved yet</div>
                ),
                witouthButtons: true,
              });
            }
          }}
        >
          Assign School
        </div>
      </div>
      {renderUserInformation(renderType)}
    </div>
  );
};

export default CounselorList;
