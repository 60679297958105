import React, { Component } from 'react';
import { connect } from 'react-redux'
import { changeSchoolAdminApprovedStatusAttempt } from "../../redux/actions/schoolAdmin";

import './SchoolAdmin.css'

class SchoolAdmin extends Component {
    
    handleClick = () => {
        this.props.changeSchoolAdminApprovedStatusAttempt({
          Email: "aletest1@gmail.com",
          Approved: true
        });
    }
    render() {
            return (
                <div>
                        <button onClick={() => { this.handleClick() }}> Click </button>
                        <h1>changed School Admin Status: {`${this.props.changedSchoolAdminStatus}`}</h1>
                </div>
            )
    }
}

const mapStateToProps = (state) =>{
    return {
      changedSchoolAdminStatus: state.schoolAdminReducer.changedSchoolAdminStatus,
    }
  }
const mapDispatchToProps= (dispatch)=>{
    return{
      changeSchoolAdminApprovedStatusAttempt: (schoolAdminApprovedStatus_data) => {dispatch(changeSchoolAdminApprovedStatusAttempt(schoolAdminApprovedStatus_data))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(SchoolAdmin)