import React, { Component } from "react";
import "./Roulette.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setTask } from "../../redux/actions/schoolActions";

class Roulette extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [
        {
          name: "SELF-MANAGEMENT",
          available: true,
          background: "#FFEADB",
          border: "2px solid #F07F2F",
        },
        {
          name: "RESPONSIBLE DECISION-MAKING",
          available: true,
          background: "#FFF5DB",
          border: "2px solid #FFCA38",
        },
        {
          name: "RELATIONSHIP-SKILLS",
          available: true,
          background: "#F2FFD6",
          border: "2px solid #91B93E",
        },
        {
          name: "SOCIAL-AWARENESS",
          available: true,
          background: "#F2FFD6",
          border: "2px solid #91B93E",
        },
        {
          name: "SELF-AWARENESS",
          available: true,
          background: "#FFEADB",
          border: "2px solid #F07F2F",
        },
      ],
      highlightedElement: 1,
      nextAreaToBeCompleted: -1,
      isChoosing: false,
    };
  }

  nextHighlightedElement = () => {
    let availableAreas = this.state.areas.filter((e) => e.available === true);
    if (availableAreas.length === 1) {
      let indexOfArea = this.state.areas.findIndex((e) => e.available === true);
      return indexOfArea;
    } else {
      if (this.state.highlightedElement === this.state.areas.length) {
        return 0;
      } else {
        return this.state.highlightedElement + 1;
      }
    }
  };

  handleClick = () => {
    debugger
    // console.log(this.props.competenciesProgress.filter((e) => e.status === 3).length, "this.props.competenciesProgress.filter((e) => e.status === 3).length")
    if (this.state.isChoosing) return;
    this.setState({
      nextAreaToBeCompleted: -1,
      isChoosing: this.props.isChoosing,
    });
    this.rouletteInterval =
      this.props.competenciesProgress.filter((e) => e.status === 3).length === 4
        ? (setInterval(
          () =>
            this.setState({
              highlightedElement: this.nextHighlightedElement(),
            }),
          100
        ),
          setTimeout(() => {
            this.setState({
              nextAreaToBeCompleted: this.props.nextAreaToBeCompleted,
              nextTaskTemplate: this.props.nextTaskTemplate,
              testTemplateId: this.props.testTemplateId,
            });

            setTimeout(() => {
              this.handleRedirect();
            }, 500);
          }, 500))
        : (setInterval(
          () =>
            this.setState({
              highlightedElement: this.nextHighlightedElement(),
            }),
          100
        ),
          setTimeout(() => {
            this.setState({
              nextAreaToBeCompleted: this.props.nextAreaToBeCompleted,
              nextTaskTemplate: this.props.nextTaskTemplate,
              testTemplateId: this.props.testTemplateId,
            });

            setTimeout(() => {
              this.handleRedirect();
            }, 2000);
          }, 3000));
  };

  handleRedirect = () => {
    debugger
    const { history } = this.props;
    this.props.setTask({
      template: this.props.nextTaskTemplate,
      templateId: this.props.testTemplateId,
      isComplete: false,
      chapterProgressId: this.props.chapterProgressId,
    });

    // this.props.setTask({ template: template, templateId: templateId, isComplete: false, chapterProgressId: this.props.chapterProgressId });
    this.setState({
      highlightedElement: 1,
      nextAreaToBeCompleted: -1,
      isChoosing: false,
    });
    if (history) history.push("/task");
  };

  componentDidMount() {
    let areas = this.state.areas;
    if (!this.props.competenciesProgress) return;
    this.props.competenciesProgress.map((element, index) => {
      let area = areas.find((e) => e.name === element.name);
      let i = areas.findIndex((e) => e.name === area.name);

      if (area.available) {
        area.available = element.status === 2 || element.status === 1;
      }

      areas[i] = area;
      this.setState({
        areas: areas,
      });
    });

    this.setState({
      isChoosing: this.props.isChoosing,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isChoosing !== prevProps.isChoosing) {
      this.setState({
        isChoosing: this.props.isChoosing,
      });
      this.handleClick();
    }
  }

  render() {
    const { history } = this.props;
    let unavailableStyles = {
      backgroundColor: "#E1E1E1",
      border: "1px solid #BEBEBE",
      color: "#AFAFAF",
    };

    // console.log(
    //   this.state.highlightedElement,
    //   this.props.competenciesProgress,
    //   this.props.competenciesProgress.filter((e) => e.status === 2).length,
    //   this.props.competenciesProgress.filter((e) => e.status === 2).length ===
    //   1 ||
    //   this.props.competenciesProgress.filter((e) => e.status === 1).length ===
    //   1,
    //   this.props.competenciesProgress.filter((e) => e.status === 1),
    //   this.props.competenciesProgress.filter((e) => e.status === 2),
    //   "highligthened all time"
    // );
    return (
      <div className="roulette-container">
        <div className="inner-circle"></div>
        <ul class="circle-container">
          {this.state.areas.map((element, index) => {
            return (
              <li className="circle-item-list">
                <div className="roullete-slot-container bg-white">
                  <div
                    className="roullete-slot-container"
                    style={
                      element.available
                        ? !this.state.isChoosing
                          ? {
                            backgroundColor: element.background,
                            border: element.border,
                          }
                          : index === this.state.highlightedElement
                            ? {
                              backgroundColor: element.background,
                              border: element.border,
                            }
                            : {
                              backgroundColor: element.background,
                              border: element.border,
                              opacity: 0.5,
                            }
                        : unavailableStyles
                    }
                  >
                    <div className="roullete-slot-text">
                      {element.name
                        .split("-")
                        .join(" ")
                        .split(" ")
                        .map((e) => (
                          <div className="roullete-slot-text-word">{e}</div>
                        ))}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        {this.state.nextAreaToBeCompleted === this.state.highlightedElement &&
          clearInterval(this.rouletteInterval) &&
          this.setState({ isChoosing: false })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTask: (task) => {
      dispatch(setTask(task));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Roulette));

let templateId = "c1acbaf7-b5c3-4791-cc68-08d85b348789";
let template = `<step>
<testtitle><p><strong>I am</strong> <em>poem</em></p></testtitle>
<title><p><em>Who Are You?</em>&nbsp;</p></title>
<subtitle><p>It seems like a simple question, right? But really- <strong>who are you? How do you define yourself?</strong> I mean-- when is the last time you went up to stranger and asked- who are you? Seems weird right? But is it?&nbsp;</p></subtitle>
<subtitle><p>Because we don't take the time to get to know people-- we often make false generalizations. <strong>Often we stereotype people without even realizing it.</strong>&nbsp;</p></subtitle>
<subtitle><p>In reality- this is because we also judge ourselves. Because we do not take the time to get to know ourselves-we don't have the depth to ever get to know someone else beyond the surface.&nbsp;</p></subtitle>
<subtitle><p>In this activity-you will write an original "I AM Poem" telling YOUR story. We will guide you through this framework to help you bring out your best writing.&nbsp;</p></subtitle>
<subtitle><p><strong>But remember: it's YOUR story.</strong> You get to choose what you want to write. Take this time to notice how you feel during the process of writing your story. Does it make you feel good-- foes it make you feel bad? And if I am the author-- at any time-- can I change my story?&nbsp;</p></subtitle>
</step>
<step>
<title><p><em>Begin by writing</em></p></title>
<subtitle><p>Remember this is your story-- so do not be limited to this framework. This is just a guide&nbsp;</p></subtitle>
<row><text>I am</text><input><placeholder>brave</placeholder></input></row>
<row><text>I am</text><input><placeholder>talented</placeholder></input></row>
<row><text>I am</text><input><placeholder>ambitious</placeholder></input></row>
</step>
<step>
<row><text>Because my purpose is to</text><input></input><input></input></row>
</step>
<step>
<title><p><em>Now, I want you to close your eyes and think of a life changing experience.</em></p></title>
<subtitle><p>How did you feel? Were you happy? Were you sad? Lost? Surprised? Joyful? Angry?</p></subtitle>
<row><text>I was</text><input></input><input></input><input></input></row>
</step>
<step>
<title><p><em>Next, describe what the emotion we picked sounds, taste, smells, looks, and feels like.</em></p></title>
<subtitle><p>Notice how you feel as you’re writing this. Remember you decided to choose this story. Why did you pick this story to tell? Why was this life changing experience so important to you?</p></subtitle>
<row><text>It souds like</text><input></input></row>
<row><text>It taste like</text><input></input></row>
<row><text>It smells like</text><input></input></row>
<row><text>It looks like</text><input></input></row>
<row><text>It feels like</text><input></input></row>
</step>
<step>
<title><p><em>For our last stanza— please close your eyes again but this time think about your future— and you want to BE!</em>&nbsp;</p></title>
<subtitle><p>Then realize who you want to BE is who you are right now! So write the first stanza again but thinking of your future.</p></subtitle>
<row><text>I am</text><input></input></row>
<row><text>I am</text><input></input></row>
<row><text>I am</text><input></input></row>
<row><text>Because my purpose is to</text><input></input><input></input></row>
</step>`;
