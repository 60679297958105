import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {loginFailed} from "../../redux/actions/auth"
const token = localStorage.getItem("token");
class ProtectedRoute extends React.Component {
    // componentDidUpdate(){
    //     if(!token){
    //         this.props.loginFailed("Login expired");
    //     }
    // }
    render() {
        const Component = this.props.component;
        const isAuthenticated = this.props.admitedUsers ? this.props.admitedUsers.includes(this.props.userFirstRole) : false;
        console.log('is logged inside protected route: ', this.props.isLogged)
        console.log('is authenticated inside protected route: ', isAuthenticated);
        return this.props.isLogged ? (
            isAuthenticated ? (
                <Component userFirstRole={this.props.userFirstRole} />
                ) : (
                    <Redirect to={{ pathname: '/' }} />
                )
            ) : (
                <Redirect to={{ pathname: '/login' }} />
            )
    }
}

const mapStateToProps = (state) =>{
    return {
      userFirstRole: state.loginReducer.user?.firstRole,
      isLogged: state.loginReducer.isLogged
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        loginFailed: (error) => {
            dispatch(loginFailed(error));
        },
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProtectedRoute)