import React, {useState} from "react";
import DatePicker from "react-datepicker";


const DatePickerComponent = ({ initialDate, setDate , dateFormat, showTimeSelect}) => {
  const [pickerDate, setPickerDate] = useState(initialDate);

  const handleChange = (date) => {
    console.log(date, "date handle change")
    setPickerDate(date);
    setDate(date);
  }

  return (
    <DatePicker
      selected={pickerDate}
      onChange={handleChange}
      showTimeSelect={showTimeSelect}
      dateFormat={dateFormat}
      className="date-picker text-input"
    />
  );
};

export default DatePickerComponent;
