import React from "react";
import "./PoemImage.css";
import iamPoem from "../../../utils/images/poem.png";
import border from "../../../utils/images/poem_border.png";

const PoemImage = ({text}) => <div className="poem-image-container">    
      <div className="poem-image-content">
            <div dangerouslySetInnerHTML={{__html: text}} className="poem-image-title crimson" />
            <img className="poem-border" src={border} alt="border" />
      </div>
</div>;

export default PoemImage;
