import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Login from "../../views/Login";

class LoginRoute extends React.Component {

    render() {
        // const isAuthenticated = this.props.admitedUsers ? this.props.admitedUsers.includes(this.props.userFirstRole) : false;
        return this.props.isLogged ? (
            // isAuthenticated ? (
                <Redirect to={{ pathname: '/' }} />
                ) : (
                <Login />
            )
    }
}

const mapStateToProps = (state) =>{
    return {
      isLogged: state.loginReducer.isLogged
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(LoginRoute)