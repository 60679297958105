import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { loginAttempt } from "../../redux/actions/auth";
import "./Task.css";
import closeIcon from "../../utils/images/close.png";
import pages from "./pages";

// Poem Components

import Title from "../../components/Poem/Title";
import Subtitle from "../../components/Poem/Subtitle";
import Paragraph from "../../components/Poem/Paragraph";
import NextButton from "../../components/Poem/NextButton";
import Row from "../../components/Poem/Row";
import LineBreak from "../../components/Poem/LineBreak";
import LastPage from "../../components/Poem/LastPage/index";
import PoemImage from "../../components/Poem/PoemImage";
import LineInput from "../../components/Poem/LineInput";

import Footer from "../../components/Footer";
import Header from "../../components/Header";

import { createTest, answerTest, answerChapterCompentencyTest } from "../../services/schoolServices";
import { taskWithRow } from "../../utils/taskHelper";
import { setTask } from "../../redux/actions/schoolActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import { isMobile } from 'react-device-detect'

class Poem extends Component {
  state = {
    step: 0,
    startPoem: false,
    endPoem: false,
    pages: [],
    pagesCount: 0,
    selectedPage: 0,
    containerHeight: 0,
    buttonDisabled: 0,
    isLoading: true,
  };
  renderedPages = [];
  contentContainer = createRef();
  containerRef = createRef();

  componentDidMount() {
    this.handlePages();
    this.renderedPages = [];
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step && this.state.step > 0 && !this.props.fromAdmin) {
      this.scrollTo();
    }

    if (
      prevProps.school &&
      this.props.school &&
      prevProps.school.task.template !== this.props.school.task.template
    ) {
      this.handlePages();
    }
  }

  componentWillUnmount() {
    this.props.setTask({
      template: null,
      templateId: null,
      isComplete: null,
    });
    this.setState({
      step: 0,
      startPoem: false,
      endPoem: false,
      pages: [],
      pagesCount: 0,
      selectedPage: 0,
      containerHeight: 0,
      buttonDisabled: 0,
      isLoading: true,
    });
  }

  handlePages = () => {
    //const handlePage = this.props.school.task;

    if (this.props.school.task.template) {
      
      const task = this.props.school.task.template.replace(/&nbsp;/g, ' ');

      const handlePage = task.split("\n");

      //const handlePage = taskWithRow(pages).split("\n");

      let newPages = [];
      let startPage = false;
      let page = [];
      handlePage.forEach((item) => {
        if (item.includes("</step>")) {
          startPage = false;
          newPages.push(page);
          page = [];
        }

        if (startPage) {
          page.push(item);
        }

        if (item.includes("<step>")) {
          startPage = true;
        }
      });
      if (this.props.school.task.isComplete) {
        this.setState({
          pages: newPages,
          pagesCount: newPages.length,
          step: newPages.length,
          isLoading: false,
        });
      } else {
        this.setState({
          pages: newPages,
          pagesCount: newPages.length,
          isLoading: false,
        });
      }
    }
  };

  sendAnsweredChapter = async (templateString) => {
    console.log('ultimo next', this.state);
    console.log('school: ', this.props.school);
    console.log('tremplateString: ', templateString);
        try {
          let response = await answerChapterCompentencyTest({
            AnsweredTemplate: templateString,
            TestTemplateCompetencyId: this.props.school.task.templateId,
            ChapterProgressId: this.props.school.task.chapterProgressId,
          });
          console.log('responseAnswerChapterCompetencyTest:', response)
          
          if(response.data.success) {
          }
        } catch (error) {
          console.error('error: ', error);
        }
  }

  handleConstructPages = () => {
    const testPages = this.state.pages;
    let newPages = [];
    let newPage;

    if (this.props.school.task.isComplete) {
      newPage = testPages.map((page) =>
        page.map((item, index) =>
          this.renderTag(
            item,
            item.substring(item.lastIndexOf("<") + 2, item.lastIndexOf(">")),
            index
          )
        )
      );
      newPages.push(
        <div
          className={`page-container ${
            this.state.step === 0 ? "first-page" : ""
          }`}
        >
          {newPage}
        </div>
      );

      this.renderedPages = newPages;
      this.containerRef.current.scroll(0, 0);

      return newPages;
    }

    newPage =
      this.state.step < this.state.pagesCount &&
      testPages[this.state.step].map((item, index) =>
        this.renderTag(
          item,
          item.substring(item.lastIndexOf("<") + 2, item.lastIndexOf(">")),
          index
        )
      );

    if (this.state.step === 0) {
      newPages = [];
    } else {
      newPages = this.renderedPages.slice();
    }

    if (this.state.step < this.state.pagesCount) {
      if (newPages.length === this.state.step) {
        newPages.push(
          <div
            className={`page-container ${
              this.state.step === 0 ? "first-page" : ""
            }`}
          >
            {newPage}
          </div>
        );
      }
    } else {
      if (this.state.step >= this.state.pagesCount - 1) {
        console.log('this.props.isMobile: ', this.props.isMobile);
        newPages.push(<LastPage
                          isPreviewing={this.props.isPreviewing}
                          handlePreviewClose={this.props.handlePreviewClose}
                          mobile={this.props.isMobile}
                      />);
      }
    }

    this.renderedPages = newPages;

    return newPages;
  };

  scrollTo = () => {
    let nodes = document.querySelectorAll(".poem-title");

    if (this.state.step === this.state.pagesCount) {
      this.containerRef.current.scroll(
        0,
        this.containerRef.current.scrollTop + 300
      );
    } else {
      nodes[nodes.length - 1] &&
        nodes[nodes.length - 1].scrollIntoView({ behavior: "smooth" });
    }
  };

  renderTag = (item, tag, itemIndex) => {
    const parsedItem = item.replace(`</${tag}>`, "").replace(`<${tag}>`, "");
    let placeHolder = "";
    let inputValue = "";
    let rowText = "";
    let rowCount = 1;
    if (tag === "input" || tag === "row") {
      if (item.includes("<placeholder>")) {
        placeHolder = item.substring(
          item.lastIndexOf("<placeholder>") + 13,
          item.lastIndexOf("</placeholder>")
        );
        inputValue = item
          .replace("<placeholder>", "")
          .replace("</placeholder>", "")
          .replace(
            item.substring(
              item.lastIndexOf("<placeholder>") + 13,
              item.lastIndexOf("</placeholder>")
            ),
            ""
          );
      } else {
        inputValue = parsedItem;
      }

      if (tag === "row") {
        rowText = item.substring(
          item.lastIndexOf("<text>") + 6,
          item.lastIndexOf("</text>")
        );
        rowCount = (item.match(/<input>/g) || []).length;

        if (item.includes("<placeholder>")) {
          placeHolder = item.substring(
            item.lastIndexOf("<placeholder>") + 13,
            item.lastIndexOf("</placeholder>")
          );
          const inputString = item.substring(
            item.indexOf("<input>") + 7,
            item.indexOf("</input>")
          );
          inputValue = inputString
            .replace("<placeholder>", "")
            .replace("</placeholder>", "")
            .replace(
              item.substring(
                item.lastIndexOf("<placeholder>") + 13,
                item.lastIndexOf("</placeholder>")
              ),
              ""
            );
        } else {
          const inputString = item.substring(
            item.indexOf("<input>") + 7,
            item.indexOf("</input>")
          );
          inputValue = inputString;
        }
      }
    }

    const tags = {
      title: <Title data={parsedItem} />,
      text: <Paragraph data={parsedItem} />,
      subtitle: (
        <Subtitle firstPage={this.state.step === 0} data={parsedItem} />
      ),
      testtitle: <PoemImage text={parsedItem} />,
      br: <LineBreak />,
      input: (
        <LineInput
          rowCount={rowCount}
          value={inputValue}
          placeholder={placeHolder}
        />
      ),
      row: (
        <Row
          buttonDisabled={this.props.school.task.isComplete}
          key={`${itemIndex} ${tag}`}
          stepPosition={this.state.step}
          itemIndex={itemIndex}
          onInputChange={this.onInputChange}
          rowCount={rowCount}
          text={rowText}
          inputValue={inputValue}
          placeholder={placeHolder}
        />
      ),
    };

    return tags[tag];
  };

  onInputChange = (value, inputPosition, stepPosition) => {
    let newPages = this.state.pages;
    const item = newPages[stepPosition][inputPosition].slice();
    let inputStart = item.indexOf("<input>") + 7;
    const inputEnd = item.indexOf("</input>");
    if (item.includes("<placeholder>")) {
      inputStart = item.indexOf("</placeholder>") + 14;
    }

    const inputValue = item.slice(0, inputStart) + value + item.slice(inputEnd);
    newPages[this.state.step][inputPosition] = inputValue;
    this.setState({
      pages: newPages,
    });
  };

  handleNextStep = () => {
    const inputs = document.querySelectorAll("textarea");
    Array.from(inputs, (item) => {
      item.classList.add("disabled");
      item.setAttribute("disabled", true);
    });
    this.setState({ step: this.state.step + 1 });
    if (this.state.step >= this.state.pagesCount - 1 && this.props.isPreviewing)
      return;
    if (this.state.step >= this.state.pagesCount - 1) {
      this.transformPage();
    }
  };

  transformPage = () => {
    const pages = this.state.pages.slice();
    let stringPages = "";
    pages.forEach((page) => {
      stringPages = stringPages + "<step> \n";
      page.forEach((item) => {
        stringPages = stringPages + item + "\n";
      });
      stringPages = stringPages + "</step> \n";
    });
    if (!this.props.isPreviewing) {
      this.sendAnsweredChapter(stringPages);
      answerTest({
        TemplateId: this.props.school.task.templateId,
        AnsweredTemplate: stringPages,
      }).catch((res) => console.log(res, "respuesta answer test"));
    }

    // createTest({
    //   Target: 1,
    //   Title: "I am Poem",
    //   Description: "I am Poem",
    //   Template: stringPages,
    //   IsPrimary: false,
    // }).then(res => console.log(res, "respuesta desde el create get test en poem"))
    console.log(stringPages, "page generated");
  };

  renderDesktop = () => {
    return (
      <div ref={this.containerRef} className={`poem-container  ${this.props.isReport ? "report-task" : ""} ${ this.props.fromAdmin  ? "from-admin" : ""}`}>
        
          <>
          <div className={this.state.isLoading ? "is-loading" : "not-loading"}>
            <CircularProgress color="secondary" />
          </div>
            {this.props.isPreviewing || this.props.fromAdmin ? "" : <Header variant={"light"} />}
            <div
              ref={this.contentContainer}
              className={`poem-content ${
                this.state.step === 0 && "first-page"
              }`}
            >
              {this.state.pages.length > 0 && this.handleConstructPages()}
              {!this.props.isReport && <div className="poem-background" />}
              {/* <div
            className="close-button"
            onClick={() => {
              if (this.props.isPreviewing) {
                this.renderedPages = [];
                this.setState({
                  step: 0,
                });

                this.props.handlePreviewClose();
                return;
              }

              const inputs = document.querySelectorAll("textarea");
              Array.from(inputs, (item) => {
                if (
                  parseInt(item.getAttribute("inputIndex")) ===
                  this.state.step - 1
                ) {
                  item.classList.remove("disabled");
                  item.removeAttribute("disabled");
                }
              });
              if (this.state.step >= 1) {
                this.renderedPages.pop();
              }
              this.setState({
                step: this.state.step >= 1 ? this.state.step - 1 : 0,
              });
            }}
          >
            <img src={closeIcon} alt="close" />
          </div> */}
              <>
                {!this.props.school.task.isComplete && (
                  <div
                    className="close-button"
                    onClick={() => {
                      if (this.props.isPreviewing) {
                        this.renderedPages = [];
                        this.setState({
                          step: 0,
                        });

                        this.props.handlePreviewClose();
                        return;
                      }

                      const inputs = document.querySelectorAll("textarea");
                      Array.from(inputs, (item) => {
                        if (
                          parseInt(item.getAttribute("inputIndex")) ===
                          this.state.step - 1
                        ) {
                          item.classList.remove("disabled");
                          item.removeAttribute("disabled");
                        }
                      });
                      if (this.state.step >= 1) {
                        this.renderedPages.pop();
                      }
                      this.setState({
                        step: this.state.step >= 1 ? this.state.step - 1 : 0,
                      });
                    }}
                  >
                    <img src={closeIcon} alt="close" />
                  </div>
                )}
                {this.state.pagesCount > this.state.step && (
                  <NextButton
                    isFirst={this.state.step === 0}
                    onClick={this.handleNextStep}
                    mobile={this.props.isMobile}
                  >
                    {this.state.step === 0 ? "Let’s begin!" : "Next"}
                  </NextButton>
                )}
              </>
            </div>
            {this.props.isPreviewing || this.props.fromAdmin ? "" : <Footer />}
          </>
      </div>
    );
  }

renderMobile = () => {
  
  return (
    <div ref={this.containerRef} className={`poem-container ${this.props.isReport ? "report-task" : ""} poem-container-mobile ${ this.props.fromAdmin  ? "from-admin" : ""}`}>
      {this.state.isLoading ? (
        <div className="loading-container">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          {this.props.isPreviewing || this.props.fromAdmin ? "" : <Header whiteBackground variant={"light"} />}
          <div
            ref={this.contentContainer}
            className={`poem-content poem-content-mobile ${
              this.state.step === 0 && "first-page"
            }`}
          >
            {this.state.pages.length > 0 && this.handleConstructPages()}
            <div className="poem-background" />
            {/* <div
          className="close-button"
          onClick={() => {
            if (this.props.isPreviewing) {
              this.renderedPages = [];
              this.setState({
                step: 0,
              });

              this.props.handlePreviewClose();
              return;
            }

            const inputs = document.querySelectorAll("textarea");
            Array.from(inputs, (item) => {
              if (
                parseInt(item.getAttribute("inputIndex")) ===
                this.state.step - 1
              ) {
                item.classList.remove("disabled");
                item.removeAttribute("disabled");
              }
            });
            if (this.state.step >= 1) {
              this.renderedPages.pop();
            }
            this.setState({
              step: this.state.step >= 1 ? this.state.step - 1 : 0,
            });
          }}
        >
          <img src={closeIcon} alt="close" />
        </div> */}
            <>
              {!this.props.school.task.isComplete && (
                <div
                  className="close-button close-button-mobile"
                  onClick={() => {
                    if (this.props.isPreviewing) {
                      this.renderedPages = [];
                      this.setState({
                        step: 0,
                      });

                      this.props.handlePreviewClose();
                      return;
                    }

                    const inputs = document.querySelectorAll("textarea");
                    Array.from(inputs, (item) => {
                      if (
                        parseInt(item.getAttribute("inputIndex")) ===
                        this.state.step - 1
                      ) {
                        item.classList.remove("disabled");
                        item.removeAttribute("disabled");
                      }
                    });
                    if (this.state.step >= 1) {
                      this.renderedPages.pop();
                    }
                    this.setState({
                      step: this.state.step >= 1 ? this.state.step - 1 : 0,
                    });
                  }}
                >
                  <img src={closeIcon} alt="close" />
                </div>
              )}
              {this.state.pagesCount > this.state.step && (
                <NextButton
                  isFirst={this.state.step === 0}
                  onClick={this.handleNextStep}
                  withoutNate={this.state.step === 0 ? false : true}
                  mobile={this.props.isMobile}
                >
                  {this.state.step === 0 ? "Let’s begin!" : "Next"}
                </NextButton>
              )}
            </>
          </div>
          {this.props.isPreviewing || this.props.fromAdmin ? "" : <Footer />}
        </>
      )}
 
    </div>
  );
}

  render() {
      if (isMobile || this.props.isMobile) {
        return this.renderMobile()
      } else {
        return this.renderDesktop()
      }
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
    login: state.loginReducer,
    school: state.schoolReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginAttempt: (user) => {
      dispatch(loginAttempt(user));
    },
    setTask: (task) => {
      dispatch(setTask(task));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Poem);
