import React, { useState, useEffect } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  approveCounselor,
  removeCounselor,
  editCounselor,
  getCounselor,
} from "../../services/schoolServices";

import {
  getChapter,
  deleteChapter,
  getCompetency,
  chapterAddQuestion,
} from "../../services/chapterServices";
import moment from "moment";

import blankImage from "../../utils/images/blank_user.png";
import DatePicker from "../../components/Admin/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import CreateChapter from "../Chapter/CreateChapter/CreateChapter";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { object } from "yup";
import QuestionResponses from "./QuestionResponses";

const ChapterList = ({
  dataList,
  updateInfo,
  handleModalOpen,
  setInformation,
  callback,
  isSelfAssessment,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDataInformation = (data) => {
    return (
      <div>
        <CreateChapter
          isEdit
          data={data}
          callback={callback}
          handleModalOpen={handleModalOpen}
        />
      </div>
    );
  };

  const displayEditModal = (item) => {
    setInformation({
      title: "Chapter Edit",
      descriptionComponent: handleDataInformation(item),
      acceptAction: (e) => {},
      witouthButtons: true,
    });
  };

  const renderAssesmentQuestions = (name , id) => {
    console.log('chapterId: ', id)
    setInformation({
      title: name,
      descriptionComponent: <QuestionResponses chapterId={id} handleModalOpen={handleModalOpen} />,
      witouthButtons: true,
    });
  };
  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={"dashboard-list-container"}
    >
      {dataList.length === 0 && <div>No information available</div>}
      {dataList.length > 0 && !isSelfAssessment && (
        <ListItem className={`dashboard-list-item`}>
          <ListItemText className="list-item-text" primary={"Chapter Name"} />
          <ListItemText className="list-item-text" primary={""} />
          <ListItemText className="list-item-text" primary={""} />

          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Delete"}
            button
          />
          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Edit"}
            button
          />
        </ListItem>
      )}
      {isSelfAssessment && (
        <ListItem
          onClick={() => {
            renderAssesmentQuestions("Initial Assessment");
          }}
          className={`dashboard-list-item ${
            isSelfAssessment ? "cursor-pointer" : ""
          }`}
        >
          <ListItemText
            className="list-item-text"
            primary={"Initial Assessment"}
          />
          <ListItemText className="list-item-text" primary={""} />
          <ListItemText className="list-item-text" primary={""} />
        </ListItem>
      )}
      {dataList.map((item) => (
        <ListItem
          onClick={() => {
            if (isSelfAssessment) {
              renderAssesmentQuestions(item.name, item.id);
            }
          }}
          className={`dashboard-list-item ${
            isSelfAssessment ? "cursor-pointer" : ""
          }`}
        >
          <ListItemText className="list-item-text" primary={item.name} />
          <ListItemText className="list-item-text" primary={""} />
          <ListItemText className="list-item-text" primary={""} />
          {!isSelfAssessment && (
            <>
              <ListItemText
                onClick={(e) => {
                  handleModalOpen({
                    title: "Are you sure?",
                    description: `You want to delete ${item.name}?`,
                    acceptAction: () => {
                      if (e.target) {
                        e.target.disabled = true;
                      }
                      deleteChapter({ id: item.id })
                        .then((res) => {
                          updateInfo();
                          if (res.data.success) {
                            handleModalOpen({
                              title: "Chapter Deleted",
                              description: `The chapter ${item.name} was deleted`,
                            });
                          } else {
                            handleModalOpen({
                              title: "There was an error while deleting",
                              description: `Try again later`,
                            });
                          }
                        })
                        .catch((err) => {
                          handleModalOpen({
                            title: "There was an error while deleting",
                            description: `Try again later`,
                          });
                        });
                    },
                  });
                }}
                className="list-item-icon"
                primary={"Delete"}
                button
              />
              <ListItemText
                onClick={() => {
                  setIsLoading(true);

                  getChapter({ id: item.id })
                    .then((res) => {
                      setIsLoading(false);
                      displayEditModal(res.data.body);
                    })
                    .catch(() => {
                      handleModalOpen({
                        title: "Error",
                        description: `Try again later`,
                      });
                      setIsLoading(false);
                    });
                }}
                className="list-item-icon"
                primary={"Edit"}
                button
              />
            </>
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default ChapterList;
