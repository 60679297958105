import React, { useEffect, useState, useCallback } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Task from "../Task";
import {
  createClass,
  getAllSchoolService,
  counselorGetAll,
  studentGetAll,
  getStudent,
  getAllTest,
  getAllAnsweredTest,
  getAnsweredTest,
  getStudentProgress,
  getTest,
  getStudentAutoEvaluations,
  getFirstAutoEvaluation,
} from "../../services/schoolServices";
import { getAllChapters } from "../../services/chapterServices";

import { getStudentChapterProgress } from "../../services/chapterServices";
import { reviewTest } from "../../services/counselorService";
import { getCompetency } from "../../services/chapterServices";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { setTask as setTaskAction } from "../../redux/actions/schoolActions";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import blankImage from "../../utils/images/blank_user.png";
import { forceStartChapter } from "../../services/chapterServices";

const Schema = Yup.object().shape({
  PersonInChargeEmail: Yup.string().required(
    "You must add a person in charge!"
  ),
  Description: Yup.string().required("You must add a description!"),
  Title: Yup.string().required("You must add a title"),
  SchoolId: Yup.string().required("You must select a school id"),
});

const initialValues = {
  SchoolId: "",
  Title: "",
  Description: "",
  PersonInChargeEmail: "",
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const StudentProgress = ({
  type,
  userInformation,
  handleModalOpen,
  chapters,
  chaptersToFilter,
  setInformation,
  updateProgress
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schoolsOptions, setSchoolOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);

  const [selectedStudent, setSelectedStudent] = useState(userInformation);
  const [error, setError] = useState("");
  const [task, setTask] = useState([]);
  const [selectedTaskInfo, setSelectedTaskInfo] = useState(false);

  const taskSelected = useSelector(
    (state) => state.schoolReducer?.task?.template
  );
  const [alertStudent, setAlertStudent] = useState(false);
  const [counselorComment, setCounselorComment] = useState("");
  const [competencies, setCompetencies] = useState([]);
  const [graphyc, setGraphyc] = useState(null);

  const dispatch = useDispatch();
  const [allChapters, setAllChapters] = useState([]);
  console.log(selectedStudent, "selectedStudent selected all time");

  const renderGraphyc = () => {
    const dataSets = graphyc.dataSets.map((el, index) => ({
      label: el.name,
      fill: false,
      lineTension: 0.1,
      backgroundColor: competencyColors[index],
      borderColor: competencyColors[index],
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: competencyColors[index],
      pointBackgroundColor: "#fff",
      pointBorderWidth: 0,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: competencyColors[index],
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: el.points,
    }));

    const data = {
      labels: graphyc.labels,
      datasets: dataSets,
    };
    return (
      <Line
        options={{ scales: { yAxes: [{ ticks: { max: 5, min: 0 } }] } }}
        data={data}
      />
    );
  };

  const competencyColors = {
    0: "#F0E42F",
    1: "#FFCA38",
    2: "#ADFF02",
    3: "#91B93E",
    4: "#F07F2F",
  };

  useEffect(() => {
    onStudentSelected();
  }, []);

  const onStudentSelected = async () => {
    try {
      setIsLoading(true);

      const { id } = userInformation;

      const { data: allCompetencies } = await getCompetency({});
      const response = allCompetencies.body;
      const competenciesResponse = {};
      response.forEach((el) => (competenciesResponse[el.id] = el.name));

      const { data: allChaptersResponse } = await getAllChapters({});

      console.log(competencies, "competencies", allChapters, "allchapters");

      const { data: studentProgress } = await getStudentChapterProgress({
        studentId: id,
      });
      const { data: studentAutoEvaluations } = await getStudentAutoEvaluations({
        id: id,
      });
      const { data: studentFirstEvaluation } = await getFirstAutoEvaluation({
        id: id,
      });

      const { data: student } = await getStudent({ studentId: id });
      const { data: answeredTest } = await getStudentChapterProgress({
        studentId: id,
      });

      console.log(
        studentAutoEvaluations,
        student,
        answeredTest,
        allChapters,
        competenciesResponse,
        "student , answered test y sutdent autoeval"
      );

      const graphyc = {
        labels: ["First Evaluation"],
        dataSets: [
          {
            name: "",
            points: [],
          },
        ],
      };

      const points = {};

      studentFirstEvaluation.body.evaluations &&
        studentFirstEvaluation.body.evaluations.forEach((el) => {
          points[competenciesResponse[el.competencyId]] = [el.personalValue];
        });
      studentAutoEvaluations.body.evaluations &&
        studentAutoEvaluations.body.evaluations.forEach((el) => {
          el.evaluations &&
            el.evaluations.forEach((evaluation) => {
              points[competenciesResponse[evaluation.competencyId]] = [
                ...points[competenciesResponse[evaluation.competencyId]],
                evaluation.personalValue,
              ];
            });
        });

      allChaptersResponse.body.forEach((el) => {
        graphyc.labels = [...graphyc.labels, el.name];
      });

      graphyc.dataSets = Object.keys(points).map((key) => ({
        name: key,
        points: points[key],
      }));
      console.log(graphyc, "graphyc process");
      setCompetencies(competenciesResponse);
      setAllChapters(allChaptersResponse.body);

      setTask(answeredTest.body);
      setGraphyc(graphyc);
      setIsLoading(false);
    } catch (err) {
      console.log(err, "error try catch select student");
    }
  };

  const handleSendReport = () => {
    // Guid AnswerTestId
    // [Required]
    // string ReviewedTemplate
    // string Notes
    // string Description
    // string Extras
    // bool HasToBeReDone
    reviewTest({
      answerTestId: selectedTaskInfo.id,
      ReviewedTemplate: selectedTaskInfo.template,
      notes: counselorComment,
    }).then((res) => console.log(res.data.body, "counselor review test"));
  };

  const renderTasks = () => {
    let newChapters = [];
    const orderedChapters = chapters.slice();
    const newAllchapters = chaptersToFilter.slice();

    chaptersToFilter.forEach((el, index) => {
      for (let index = 0; index < chapters.length; index++) {
        const element = chapters[index];
        if (element.chapterId === el.id) {
          const i = newAllchapters.indexOf(el);
          const order = newAllchapters[i].order;
          orderedChapters[index].order = order;
          if (i > -1) {
            newAllchapters.splice(i, 1);
          }
        }
      }
    });

    newChapters = [...orderedChapters, ...newAllchapters];
    newChapters.sort((a, b) => a.order - b.order);
    
    return newChapters.map((item, index) => {
      return (
        <div
          key={`${item?.title} ${index}`}
          className={`report-task-item student-chapter-row ${
            item.state === 1
              ? "chapter-inprogress"
              : item.state === 2
              ? "chapter-completed"
              : "chapter-bloqued"
          }`}
        >
          {item.name ? item.name : item.chapterName}
          <div className="option-menu">
            {item.completedCompetencies === 0 && (
              <div className="option-menu-item">
                The student has not yet completed any task
              </div>
            )}
            {item.competenciesProgress?.map((el, index) => {
              return (
                el.userTest && (
                  <div
                    className="option-menu-item"
                    key={`${el?.testTemplateName} ${index}`}
                    onClick={() => {
                      dispatch(
                        setTaskAction({
                          template: el.userTest.answeredTest,
                          templateId: null,
                          isComplete: true,
                        })
                      );

                      setSelectedTaskInfo({
                        id: el.userTest.answerId,
                        template: el.userTest.answeredTest,
                      });
                    }}
                    dangerouslySetInnerHTML={{
                      __html: el.userTest.testTemplateName,
                    }}
                  />
                )
              );
            })}
          </div>

          {item.state && (
            <div className="student-chapter-text-container">
              <div className="student-chapter-label">
                Completed Competencies :
              </div>
              <div className="student-chapter-text">
                {item.completedCompetencies ? item.completedCompetencies : 0}
              </div>
            </div>
          )}

          {!item.state && (
            <div
              onClick={() => {
                setIsLoading(true);

                forceStartChapter({
                  chapterId: item.id,
                  studentId: userInformation.id,
                })
                  .then((res) => {
                    if (res.data.success) {
                      handleModalOpen({
                        title: `Unlock Chapter`,
                        description: "Chapter unblocked successfully",
                      });
                    } else {
                      handleModalOpen({
                        title: `Unlock Chapter`,
                        description: "There was an error, try again later",
                      });
                    }

                    getStudentProgress({
                      studentId: userInformation.id,
                    }).then((res) => {
                      getAllChapters({})
                        .then((response) => {
                          setIsLoading(false);
                          updateProgress(res.data.body,response.data.body)
                          // setInformation({
                          //   title: "Unlock Chapter",
                          //   descriptionComponent: renderChapters(
                          //     res.data.body,
                          //     response.data.body
                          //   ),
                          //   witouthButtons: true,
                          // });
                        })
                        .catch((err) => console.log(err));
                    });
                  })

                  .catch(() =>
                    handleModalOpen({
                      title: `Unlock Chapter`,
                      description: "There was an error, try again later",
                    })
                  )
              }      
            }
              className="student-chapter-text-container unblock-chapter-button"
            >
              Unlock Chapter
            </div>
          )}
        </div>
      );
    });
  };

  const handleCounselorComment = (e) => {
    const value = e.target.value;
    setCounselorComment(value);
  };

  return (
    <div>
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        selectedStudent && (
          <div className="chapter-report-student">
            <div className="profile-info-container">
              <img
                id="profile-image"
                className={`student-image`}
                src={
                  selectedStudent.profilePictureUrl
                    ? selectedStudent.profilePictureUrl
                    : blankImage
                }
                alt="profile"
              />
              <div className="profile-info-content">
                <div className="profile-info-item">
                  Name: {selectedStudent.firstName}
                </div>
                <div className="profile-info-item">
                  Last Name: {selectedStudent.lastName}
                </div>
                <div className="profile-info-item">
                  Phone: {selectedStudent.phone}
                </div>
                <div className="profile-info-item">
                  Email: {selectedStudent.email ? selectedStudent.email : ""}
                </div>
              </div>
            </div>
            <div className="competencies-grapic">
              {graphyc && renderGraphyc()}
            </div>
            <div className="chapter-report-student-content">
              <div className="list-chapters-container">{renderTasks()}</div>
              <div className="report-task-container">
                {taskSelected && <Task isReport={true} isPreviewing={true} />}
              </div>
            </div>
            {taskSelected && (
              <div className="report-footer">
                <div className="report-alert-container">
                  <div className="report-alert-button-container">
                    <div
                      onClick={() => setAlertStudent((state) => !state)}
                      className={`report-alert-button ${
                        alertStudent ? "selected" : ""
                      }`}
                    />
                    <div>Alert</div>
                  </div>
                </div>

                <div className="counselor-comment-container">
                  <textarea
                    onChange={handleCounselorComment}
                    className="counselor-comment-input"
                    placeholder="Professional counselor comments"
                  />
                  <div
                    className="send-review-button"
                    onClick={handleSendReport}
                  >
                    Send Review
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default StudentProgress;
