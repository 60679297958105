import React, { Component } from 'react';
import Evaluation from '../../utils/images/selfEvaluation.png';
import { getMyFirstAutoEvaluation } from '../../services/schoolServices'
import { Polar } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelfEvaluation } from "../../redux/actions/selfEvaluationActions.js";
import GreenTick from '../../utils/images/green-tick.png';
import { isMobile } from 'react-device-detect'

class FirstSelfEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myFirstAutoEvaluation: null,
            data: {
                labels: ['SELF MANAGEMENT', 'RESPONSIBLE DECISION MAKING', 'RELATIONSHIP SKILLS', 'SOCIAL AWARENESS', 'SELF AWARENESS'],
                datasets: [{
                    label: '# of Votes',
                    data: [],
                    backgroundColor: [
                        '#F07F2F',
                        '#FFCA38',
                        '#91B93E',
                        '#91B93E',
                        '#F07F2F',
                    ],
                    borderColor: [
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                    ],
                    borderWidth: 0.5
                }]
            }
        }
    }

    options = {
        legend: {
            display: false,
            labels: {
                font: {
                    size: 1
                }
            }
        },
        scale: {
            ticks: {
                max: 5,
                z: 3
            },
            pointLabels: {
                fontSize: 13
            }
        },
        tooltips: {
            caretSize: 0,
            enabled: true,
            bodyFontSize: 12,
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.labels[tooltipItem.index] + ' ' + tooltipItem.value;

                    return label;
                }
            }
        },
    }

    async componentDidMount() {
        try {
            let responseGetMyFirstAutoEvaluation = await getMyFirstAutoEvaluation({});
            console.log('response GetMyFirstAutoEvaluation', responseGetMyFirstAutoEvaluation)
            if(responseGetMyFirstAutoEvaluation.data.success === true) {
                if(responseGetMyFirstAutoEvaluation.data.body.evaluations.lenght !== 0) {
                    this.setState( prevState => {
                        let data = this.state.data;
                        data.datasets[0].data = responseGetMyFirstAutoEvaluation.data.body.evaluations.map( e => { return e.personalValue} )
                        return data
                    })
                }
                this.setState({
                    myFirstAutoEvaluation: responseGetMyFirstAutoEvaluation.data.body
                })
            }
        } catch (error) {
            console.error('error: ', error);
        }
    }

    redirectToSelfEvaluation = () => {
        //setear el chapter en redux y si es "incomplete" decir que es la primer task.
        this.props.setSelfEvaluation('first');
        const { history } = this.props;
        //aca hay que mandar a redux el capitulo anterior que fue completado
        if(history) history.push('/autoevaluation/' + JSON.stringify({ id: '0', chapterId: '0' }))
    }

    _renderDesktop = () => {
        return(
            <div className="self-evaluation">
                    { this.state.myFirstAutoEvaluation === null ? (
                        <div className="self-evaluation-circle first-self-evaluation-circle" style={{backgroundColor: '#EAE23F'}}>
                            <img onClick={this.redirectToSelfEvaluation} className="self-evaluation-img" src={Evaluation} alt="self-evaluation" />
                        </div>  
                    ) : (<div className="background" style={{backgroundColor: '#EAE23F'}}>
                            <div className="chart-container">
                                <img className="green-tick-image" src={GreenTick} alt="Done" />
                                {/* El grafico */}
                                <Polar 
                                    data={this.state.data}
                                    width={200}
                                    height={200}
                                    options={this.options}
                                    min={5}
                                />
                            </div>
                        </div>) }
            </div>
        )
    }

    _renderMobile = () => {
        return(
            <div className="self-evaluation-mobile">
                    { this.state.myFirstAutoEvaluation === null ? (
                        <div className="self-evaluation-circle first-self-evaluation-circle" style={{backgroundColor: '#EAE23F'}}>
                            <img onClick={this.redirectToSelfEvaluation} className="self-evaluation-img" src={Evaluation} alt="self-evaluation" />
                        </div>  
                    ) : (<div className="background" style={{backgroundColor: '#EAE23F', borderRadius: '50%'}}>
                            <div className="chart-container">
                                <img className="green-tick-image" src={GreenTick} alt="Done" />
                                {/* El grafico */}
                                <Polar 
                                    data={this.state.data}
                                    width={200}
                                    height={200}
                                    options={this.options}
                                    min={5}
                                />
                            </div>
                        </div>) }
            </div>
        )
    }

    render() {
        if (isMobile) {
          return this._renderMobile()
        } else {
          return this._renderDesktop()
        }
    }
};


const mapStateToProps = (state) =>{
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelfEvaluation: (evaluation) => {dispatch(setSelfEvaluation(evaluation))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(FirstSelfEvaluation));