import React, { Component } from "react";
import { connect } from "react-redux";
import { createSchoolAttempt, getCreateToken } from "../../redux/actions/schoolActions";
import { Formik } from "formik";
import * as Yup from "yup";

import "./CreateSchool.css";

const Schema = Yup.object().shape({
  Name: Yup.string().required("You must add a Name!"),
  Country: Yup.string().required("You must add a country!"),
  State: Yup.string().required("You must add a state!"),
  City: Yup.string().required("You must add a city!"),
  AddressLine1: Yup.string().required("You must add a addressLine1!"),
  AddressLine2: Yup.string().required("You must add a addressLine2!"),
  ZipCode: Yup.string().required("You must add a ZipCode!"),
});

const initialValues = {
  Name: "",
  Country: "",
  State: "",
  City: "",
  AddressLine1: "",
  AddressLine2: "",
  ZipCode: "",
  MainPicture: null
};

class CreateSchool extends Component {
  handleRegister = (values) => {
    console.log(this.props.school.createToken, "create school this.props.school.createToken");
    if(values.MainPicture !== null){
      this.props.createSchoolAttempt(values, this.props.school.createToken);
      this.setState({imageError: false})
    } else {
      this.setState({imageError: true})
    }
  };
  componentDidMount(){
    this.props.getCreateToken({PermissionRequired: 1});
  }

  handleInputs = (values, errors, touched, handleChange, handleBlur) => {
    const valuesArray = Object.keys(initialValues);
    return valuesArray.map(
      (item) =>
      item !== 'MainPicture' &&
          <div key={item} className="input-container">
            {item}
            <input
              className="text-input"
              name={`${item}`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[item]}
            />
            {errors[item] && touched[item] && errors[item]}
          </div>
    );
  };

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={this.handleRegister}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form className="form-container" onSubmit={handleSubmit}>
            {this.handleInputs(
              values,
              errors,
              touched,
              handleChange,
              handleBlur
            )}
            <input name="MainPicture" id="MainPicture" accept="image/*" type="file" onChange={(e) => {
              setFieldValue('MainPicture', e.target.files[0])
            }} />
            <button className="submit-button" type="submit">
              Submit
            </button>
          </form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
    school: state.schoolReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createSchoolAttempt: (params, token) => {
      dispatch(createSchoolAttempt(params, token));
    },
    getCreateToken: (params) => {
      dispatch(getCreateToken(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSchool);
