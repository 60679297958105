import { 
    APP_ACTION,
    } from '../actions/action-types/app-action-types'


const initState = {
    appState: 'initial state',
    anotherState: 'another state',
}

const AppReducer = (state = initState, action) => {
    switch (action.type) {

        case APP_ACTION: {
            return {
                ...state,
                appState: 'An Action'
            }
        }
        
        default: {
            return state
        }
    }
}

export default AppReducer;
