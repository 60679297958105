import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
export default class ModalWrapper extends Component {
    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        // this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            // alert('You clicked outside of me!');
            this.props.handlePreviewClose();
        }
    }

    render() {
        return (
            <div>
                <div ref={this.wrapperRef} style={{zIndex: '100'}}>{this.props.children}</div>
                {this.props.greyBackground && <div style={{
                                                height: '100vh',
                                                width: '100vw',
                                                position: 'fixed',
                                                top: '0px',
                                                left: '0px',
                                                overflow: 'hidden',
                                                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                                zIndex: 10
                                                }}>
                </div>}
            </div>
            );
    }
}