
import axios from "./axiosInstance";

export const reviewTest = (params) => axios.post("/Counselor/ReviewTest", params);


// /Counselor/ReviewTest
// Body:
// Guid AnswerTestId
// [Required]
// string ReviewedTemplate
// string Notes
// string Description
// string Extras
// bool HasToBeReDone