import {
    CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_ATTEMPT,
    CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_SUCCESS,
CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_FAILED,
} from '../actions/action-types/app-action-types';

const initState = {changedSchoolAdminStatus: false};

const SchoolAdminReducer = (state = initState, {type, payload}) => {
    switch (type) {
        case CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_ATTEMPT: 
            return {
                ...state, 
                error: null,
                changedSchoolAdminStatus: false,
            };    
        case CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_SUCCESS: 
            return {
                ...state, 
                error: payload,
                changedSchoolAdminStatus: true,
            }; 
        case CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_FAILED: 
            return {
                ...state, 
                error: null,
                changedSchoolAdminStatus: false,
            }
        default: return state 
    }
}

export default SchoolAdminReducer;
