import React from 'react';
import TestEditor from './TestEditor';
import { createTest, editTest } from '../../services/schoolServices';
import { getTest } from '../../services/testServices';
import './CreateTest.css';
import Task from '../../views/Task';
import Preview from '../Preview';
import { setTask } from "../../redux/actions/schoolActions";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { taskWithRow } from "../../utils/taskHelper";
import { isMobile } from 'react-device-detect'
import CircularProgress from "@material-ui/core/CircularProgress";
import closeIcon from "../../utils/images/close.png";

class CreateTest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// Array of tags and their values
			tags: [],
			isPreviewing: false,
			isPreviewingMobile: false,
			taskId: undefined,
			focusedInputIndex: 0,
			allSaved: false,
			isLoading: false,
			description: "",
			title: "",
			copyDescription: "",
			copyTitle: "",
		};

		this.handler = this.handler.bind(this);
		this.removeTag = this.removeTag.bind(this);
	}

	async componentWillMount() {
		const queryString = window.location.search;;

		const urlParams = new URLSearchParams(queryString);
		let taskId;
		if(this.props.taskId){
			taskId = this.props.taskId;
		} else {
			taskId = urlParams.get('taskid');
		}

		if(taskId !== null) {
			this.setState({
				taskId: taskId
			})
		} else {
			this.setState({
				tags: [{tagName: 'testtitle', value: ''}],
			})
		}
	}

	async componentDidMount() {

		if(this.state.taskId) {
			let response = await getTest(this.state.taskId);
			if(response.data.success) {
				if(response.data.body.templateObj) {
					this.setState({
						tags: JSON.parse(response.data.body.templateObj)
					})
				} else {
					this.setState({
						tags: [{tagName: 'testtitle', value: ''}], 
					})
				}
			}
		}
	}

	//removes the tag with index i from the tags array. It will be passed to the te
	removeTag = (i) => {
		const newTags = [ ...this.state.tags ];
		newTags.splice(i, 1);
		this.setState({ tags: newTags });
	}

	//The handler is passed to each testEditor together with the tag index.
	//It then adds the value of the tag on input change. 
	handler = (value, i) => {
		let tags = [...this.state.tags];

		let tag = {...tags[i]}

		tag.value = value

		tags[i] = tag;

		this.setState({tags})
	}

	placeholderHandler = (value, i) => {
		value = value.split('&nbsp;').join(' ');
		
		let tags = [...this.state.tags];

		let tag = {...tags[i]}

		tag.placeholder = value

		tags[i] = tag;

		this.setState({tags})
	}

	//Generates the final string to be send to the server. It will then be used to render the tests.
	generateTagsString = async () => {

		let title = this.state.tags.filter( tag => tag.tagName === 'testtitle')[0].value.replace('<p>', '').replace('</p>', '');

		if (title === '' || title === '<br>') {
			await this.setState({ titleIsEmpty: true });
			return;
		} else {
			await this.setState({ titleIsEmpty: false });
		}

		let tagsString = '<step>\n';
		this.state.tags.map( tag => {
			if(tag.tagName === 'step') {
				tagsString += '</step>\n<step>\n'
			} else {
				if(tag.tagName === 'input') {
					tag.value = tag.value.replace('<p>', '<text>').replace('</p>', '</text>\n');
					tag.placeholder = tag.placeholder.replace('<p>', '<placeholder>').replace('</p>', '</placeholder>');
					tagsString += `${tag.value}`
					tagsString += '<input>' +tag.placeholder + '</input>\n'

					tagsString += '<input></input>\n'.repeat(tag.numberOfInputs-1);
				} else {
					if(tag.tagName === 'text') {
						tag.value = tag.value.replace('<p>', '').replace('</p>', '');
					}
					tagsString += `<${tag.tagName}>${tag.value}</${tag.tagName}>\n`
				}
			}
		})
		tagsString += '</step>\n';

		tagsString = await taskWithRow(tagsString);

		this.setState({
			tagsString: tagsString
		})
	}

	//Methods for each of the tags buttons. Each one of them creates a new tag with empty value.

	onStepClick = () => {
		this.setState({
			tags: [...this.state.tags, {tagName: 'step', value: ''}],
			allSaved: false

		})
	};

	onTitleClick = () => {
		this.setState({
			tags: [...this.state.tags, {tagName: 'title', value: ''}],
			allSaved: false

		})
	};

	onSubtitleClick = () => {
		this.setState({
			tags: [...this.state.tags, {tagName: 'subtitle', value: ''}],
		allSaved: false

		})
	};

	onTextClick = () => {
		this.setState({
			tags: [...this.state.tags, {tagName: 'text', value: ''}],
			allSaved: false

		})
	};

	onInputClick = () => {
		this.setState({
			tags: [...this.state.tags, {tagName: 'input', value: '', placeholder: '', numberOfInputs: 1}],
			allSaved: false,
		})
	}
	
	handleTrySave = () => {
		let title = this.state.tags.filter( tag => tag.tagName === 'testtitle')[0].value.replace(/<[^>]*>/g, '');

		this.props.handleModalOpen({
			descriptionComponent: <div>
				<h3>Save Task</h3>
				<input
					className={`text-input create-task-input`}
					name="title"
					onChange={(e) => this.setState({title: e.target.value})}
					placeholder={"Title"}
					defaultValue={!this.state.title ? title : this.state.title}
				/>
				<input
					className={`text-input create-task-input`}
					name="description"
					onChange={(e) => this.setState({description: e.target.value})}
					placeholder={"Description"}
					defaultValue={this.state.description}
				/>
			</div>,
			acceptAction: (e) => {
				this.handleSave();
			}
		 })
	}

	handleSaveCopy = () => {
		let title = this.state.tags.filter( tag => tag.tagName === 'testtitle')[0].value.replace(/<[^>]*>/g, '');

		this.props.handleModalOpen({
			descriptionComponent:   <div>
				<h3>Save Task</h3>
				<input
					className={`text-input create-task-input`}
					name="title"
					onChange={(e) => this.setState({copyTitle: e.target.value})}
					placeholder={"Title"}
					defaultValue={!this.state.copyTitle ? this.state.title : this.state.copyTitle}
				/>
				<input
					className={`text-input create-task-input`}
					name="description"
					onChange={(e) => this.setState({copyDescription: e.target.value})}
					placeholder={"Description"}
					defaultValue={this.state.copyDescription}
				/>
			</div>,
			acceptAction: async (e) => {
				await this.generateTagsString();
				let testData = {
					Target: 1,
					Title: this.state.copyTitle,
					Description: this.state.copyDescription,
					Template: this.state.tagsString.replace(/&nbsp;/g, ' '),
					templateObj: JSON.stringify(this.state.tags),
					isPrimary: true
				}
				let response = await createTest(testData);
				this.props.handleModalOpen({
					title: "Create Task",
					description: `Create successful`,
				 })
			}
		 })
	}


	handleSave = async () => {
		this.setState({isLoading: true})
		await this.generateTagsString();

		//Filters the tags array to get the test title tag. Then from its value, it replaces the p tags for empty strings in order to leave a clean title string.
		//let title = this.state.tags.filter( tag => tag.tagName === 'testtitle')[0].value.replace('<p>', '').replace('</p>', '');
		// let title = this.state.tags.filter( tag => tag.tagName === 'testtitle')[0].value.replace(/<[^>]*>/g, '');
		let title = this.state.tags.filter( tag => tag.tagName === 'testtitle')[0].value.replace(/<[^>]*>/g, '');

		if(!this.state.taskId) {
			let testData = {
				Target: 1,
				Title: this.state.title ? this.state.title : title,
				Description: this.state.description,
				Template: this.state.tagsString.replace(/&nbsp;/g, ' '),
				templateObj: JSON.stringify(this.state.tags),
				isPrimary: true
			}
			let response = await createTest(testData);
			this.setState({allSaved: true, isLoading: false, taskId: response.data.success ? response.data.body.id : null });
			if(response.data.success){
				this.props.handleModalOpen({
					title: "Create Task",
					description: `Create successful`,
				 })
			} else {
				this.props.handleModalOpen({
					title: "Create Task",
					description: `Something went wrong, try again later`,
				 })
			}
		
		} else {
			let testData = {
				TestTemplateId: this.state.taskId,
				Target: 1,
				Title: this.state.title,
				Description: this.state.description,
				Template: this.state.tagsString.replace(/&nbsp;/g, ' '),
				templateObj: JSON.stringify(this.state.tags),
				isPrimary: true,
			}
			let response = await editTest(testData);
			this.setState({allSaved: true, isLoading: false})
			this.props.handleModalOpen({
				title: "Edit Task",
				description: `Edit successful`,
			 })
		}
	}

// 	<input
// 	className={`text-input`}
// 	name={key}
// 	onChange={(e) => handleChange(key, e.target.value, data)}
// 	placeholder={key}
// 	// defaultValue={key === "birthDate" ? `${month}-${day}-${year}` : data[key]}
// 	defaultValue={data[key]}
// />

	handleClear = async () => {
		await this.setState( {tags: []})
		this.setState({tags: [{tagName: 'testtitle', value: ''}], allSaved: false})		
	}

	handlePreviewOpen = async () => {

		await this.generateTagsString();

		if(this.state.titleIsEmpty) return;

		this.props.setTask( {template: this.state.tagsString, templateId: null});

		this.setState({
			isPreviewing: true
		})
	}

	handlePreviewMobileOpen = async () => {

		await this.generateTagsString();

		if(this.state.titleIsEmpty) return;

		this.props.setTask( {template: this.state.tagsString, templateId: null});

		this.setState({
			isPreviewingMobile: true
		})
	}

	handlePreviewClose = () => {
		this.setState({
			isPreviewing: false,
			isPreviewingMobile: false
		})
	}

	// After select change, a new tag is inserted in the tags array and re rendered under the select.
	//Then the select value is reset.
	handleSelectChange = (e, i) => {

		let value = e.target.value;
		if(value === '+') return;

		let tag;

		if(value === 'input') {
			tag = {tagName: value, value: '', placeholder: '', numberOfInputs: 1}
		} else {
			tag = {
				tagName: value,
				value: ''
			}
		}

		let tags = this.state.tags;

		tags.splice(i+1, 0, tag);

		this.setState({
			tags: tags,
		})

		document.getElementById(`select-new-tag-${i}`).value = '+';
	}

	handleSelectInputChange = (e, i) => {

		let value = e.target.value;

		let tags = this.state.tags;
		let tag = {
			tagName: tags[i].tagName,
			value: tags[i].value,
			placeholder: '',
			numberOfInputs: value
		}

		tags[i] = tag;

		this.setState({
			tags: tags,
			focusedInputIndex: i+1
		})
	}

	render() {
		return this.state.isLoading ? (
			<div className="loading-container">
				<CircularProgress color="secondary" />
			</div>
		) : (
			<div className="editorContainer">
				{/* <div className="container-buttonsContainer sticky">
				</div> */}
					<div className="buttonsContainer sticky">
							<div className="buttonsHalfContainer left">
								<button className="button-create-test" onClick={this.onStepClick}>Step</button>
								<button className="button-create-test" onClick={this.onTitleClick}>Title</button>
								<button className="button-create-test" onClick={this.onSubtitleClick}>Subtitle</button>
								<button className="button-create-test" onClick={this.onTextClick}>Text</button>
								<button className="button-create-test" onClick={this.onInputClick}>Input</button>
							</div>
							<div className="buttonsHalfContainer right">
								<button type="button" className="button-create-test" onClick={() => { this.handleTrySave() }}>Save</button>	
								{ this.state.taskId &&
									<button type="button" className="button-create-test" onClick={() => { this.handleSaveCopy() }}>Sava as Copy</button>	
								}								
								<button type="button" className="button-create-test" onClick={() => { this.handlePreviewOpen() }}>Preview</button>	
								{!isMobile && <button type="button" className="button-create-test" onClick={() => { this.handlePreviewMobileOpen() }}>Mobile Preview</button>}
								<button type="button" className="button-create-test button-clear" onClick={() => { this.handleClear() }}>Clear</button>	
							</div>
							<div
                    className="close-button"
                    onClick={() => {
                     if(this.state.allSaved){
												this.props.callback();
										 } else {
											this.props.handleModalOpen && this.props.handleModalOpen({
												title: "You have information without save?",
												description: `You want to save the information?`,
												acceptAction: (e) => {
													this.handleSave()
												},
												cancelAction: () => {
													this.props.callback();
												}
											 })
										 }
                    }}
                  >
                    <img src={closeIcon} alt="close" />
                  </div>
					</div>
					{this.state.titleIsEmpty && <div className="titleError"> You must add a Test Title</div> }


				<div className="editors">
					<ul className="test-tags">
						{this.state.tags.map( (tag, i) => {

							// There must always be a task title
							switch(tag.tagName) {
								case 'testtitle': {
									return (
										<li>
										<span className="tagName">Task Title</span>
										<select className="select-new-tag" id={`select-new-tag-${i}`} onChange={(e) => {this.handleSelectChange(e, i) }}>
											<option>+</option>
											<option>step</option>
											<option>title</option>
											<option>subtitle</option>
											<option>text</option>
											<option>input</option>
										</select>
										<TestEditor className="text-editor-container" autoFocus={i === this.state.focusedInputIndex} index={i} tagName={tag.tagName} value={tag.value} handler={this.handler} removeTag={this.removeTag}/>
										</li>
									)
								} 
								case 'step': {
									return (
										<li>
										<span className="tagName">Step</span>
										<button type="button" className="button-remove-tag" onClick={() => { this.removeTag(i) }}>-</button>
										<select className="select-new-tag" id={`select-new-tag-${i}`} onChange={(e) => {this.handleSelectChange(e, i) }}>
											<option>+</option>
											<option>step</option>
											<option>title</option>
											<option>subtitle</option>
											<option>label</option>
											<option>input</option>
										</select>
										<hr />
										</li>
									)
								}
								case 'input': {
									return (
										<li>
										<span className="tagName">Input</span>
										<select className="select-input-tag" id={`select-input-tag-${i}`} value={tag.numberOfInputs} onChange={(e) => {this.handleSelectInputChange(e, i) }}>
											<option>1</option>
											<option>2</option>
											<option>3</option>
											<option>4</option>
											<option>5</option>
											<option>6</option>
											<option>7</option>
											<option>8</option>
											<option>9</option>
											<option>10</option>
										</select>
										<span className="tagName">Lines</span>
										<button type="button" className="button-remove-tag" onClick={() => { this.removeTag(i) }}>-</button>
										<select className="select-new-tag" id={`select-new-tag-${i}`} onChange={(e) => {this.handleSelectChange(e, i) }}>
											<option>+</option>
											<option>step</option>
											<option>title</option>
											<option>subtitle</option>
											<option>text</option>
											<option>input</option>
										</select>
										<br />
										<div className='placeholder-container'>
											<div className='placeholder-container-label'>
												<span className="tagName">Label</span>
												<TestEditor autoFocus={i === this.state.focusedInputIndex} index={i} tagName={tag.tagName} value={tag.value} handler={this.handler} removeTag={this.removeTag}/>
											</div>
											<div className='placeholder-container-placeholder'>
												<span className="tagName">Placeholder</span>
												<TestEditor autoFocus={i === this.state.focusedInputIndex} index={i} tagName={tag.tagName} value={tag.placeholder} handler={this.placeholderHandler} removeTag={this.removeTag}/>
											</div>
										</div>
										</li>
									)
								}
								default: {
									return (
										<li>
										<span className="tagName">{tag.tagName}</span>
										<button type="button" className="button-remove-tag" onClick={() => { this.removeTag(i) }}>-</button>
										<select className="select-new-tag" id={`select-new-tag-${i}`} onChange={(e) => {this.handleSelectChange(e, i) }}>
											<option>+</option>
											<option>step</option>
											<option>title</option>
											<option>subtitle</option>
											<option>text</option>
											<option>input</option>
										</select>
										<TestEditor index={i} tagName={tag.tagName} value={tag.value} handler={this.handler} removeTag={this.removeTag}/>
										</li>
									)
								}
							}
						})}
						</ul>
						</div>
						{ this.state.isPreviewing && <Preview show={this.state.isPreviewing} isPreviewing={true} handlePreviewClose={this.handlePreviewClose} />}
						{ this.state.isPreviewingMobile && <Preview show={this.state.isPreviewingMobile} isPreviewing={true} isMobile={true} handlePreviewClose={this.handlePreviewClose} />}
			</div>
		);
	}
}

const mapStateToProps = (state) =>{
    return {
    }
  }

const mapDispatchToProps= (dispatch)=>{
    return{
        setTask: (task) => {dispatch(setTask(task))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTest);