import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react'
import React from 'react'

function Example(props) {
    const [show, setShow] = useState(props.show);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        {/* <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>
   */}
        <Modal size="sm" show={show} onHide={handleClose}>
          <Modal.Header closeButton>
                { props.success ? <Modal.Title>Success</Modal.Title> : <Modal.Title>Error</Modal.Title> }
          </Modal.Header>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
                Accept
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default Example;