import React, { Component } from "react";
import { connect } from "react-redux";
import { getSchool } from "../../redux/actions/schoolActions";
import { loginAttempt, registerAttempt } from "../../redux/actions/auth";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import {
  createTest,
  getAllTest,
  getTest,
  testSendTo,
  editTest,
  removeTest,
  createSchoolService,
  getCreateTokenService,
  schoolAddClass,
  getAllSchoolService,
  getSchoolService,
  studentSetSchool,
  studentAddClass,
  studentRemoveClass,
  studentEditClass,
  getSchoolAllClasses,
} from "../../services/schoolServices";
import "./Apis.css";

// export const createSchoolService = (params, token) => {
//   const formData = new FormData();
//   for (let key in params) formData.append(key, params[key]);
//   return axiosTemp.post("/School/Create", formData, {
//     headers: { Authorization: "Bearer " + token },
//   });
// };

// export const getCreateTokenService = (params) =>
//   axios.post("/School/GetCreateOrEditToken", { ...params });

const apisButton = [
  {
    button: "Create Test",
    data: {
      Target: 1,
      Title: "First test template",
      Description: "just testing",
      Template:
        "<testtitle>poem</testtitle>\n<title><b>I am</b> poem</title>\n<subtitle>Who Are You?</subtitle>",
      SchoolId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
      ClassId: "cf7f5ee9-6250-4f41-209a-08d8442750eb",
      IsPrimary: false,
    },
    event: createTest,
  },
  {
    button: "Get All Templates",
    data: {
      Title: "",
      TimeFrom: "",
      TimeTo: "",
      SchoolId: "",
      ClassId: "",
      IsPrimary: false,
    },
    event: getAllTest,
  },
  { button: "Get Template", data: { id: "" }, event: getTest },
  // {
  //   button: "Test Template Send To",
  //   data: {
  //     TemplateId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
  //     Target: 2,
  //     StudentsIds: ["542e8ab6-8316-44f0-1167-08d842ba97a7"],
  //     SchoolId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
  //     ClassId: "cf7f5ee9-6250-4f41-209a-08d8442750eb",
  //   },
  //   event: testSendTo,
  // },

  {
    button: "Edit Template",
    data: {
      TestTemplateId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
      Target: 1,
      Title: "First test template",
      Description: "just testing",
      Template: "<title>Another test</title>",
      SchoolId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
      ClassId: "cf7f5ee9-6250-4f41-209a-08d8442750eb",
      IsPrimary: true,
    },
    event: editTest,
  },
  { button: "Test Template Remove", data: { id: "" }, event: removeTest },
  {
    button: "Create School",
    data: {
      Name: "",
      Country: "",
      State: "",
      City: "",
      AddressLine1: "",
      AddressLine2: "",
      ZipCode: "",
      MainPicture: null,
    },
    event: createSchoolService,
  },
  {
    button: "Get School",
    data: {
      id: "",
    },
    event: getSchoolService,
  },

  {
    button: "Get All Schools",
    data: {
      onlyApprove: false,
    },
    event: getAllSchoolService,
  },
  {
    button: "Get School All Classes",
    data: {
      SchoolId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
    },
    event: getSchoolAllClasses,
  },
  {
    button: "School Add Class",
    data: {
      SchoolId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
      Title: "TestClass",
      Description: "Some test class to try",
      PersonInCharge: "Atila",
    },
    event: schoolAddClass,
  },
  {
    button: "Student Set School",
    data: {
      SchoolId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
    },
    event: studentSetSchool,
  },
  {
    button: "Student Add Class",
    data: {
      classId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
    },
    event: studentAddClass,
  },
  {
    button: "Student Remove Class",
    data: {
      classId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
    },
    event: studentRemoveClass,
  },
  {
    button: "Student Edit Class",
    data: {
      SchoolId: "542e8ab6-8316-44f0-1167-08d842ba97a7",
      ClassId: "8710bcc3-ad86-41be-209b-08d8442750eb",
      Title: "TestClass2",
      Description: "Some test class to try again",
      PersonInCharge: "Atila el Huno",
    },
    event: studentEditClass,
  },
];

class Apis extends Component {
  state = {
    schoolId: "",
    apiSelected: "",
    arrayInput: "",
    data: {},
  };

  handleDataInformation = (data) => {
    const newData = Object.keys(data).map((key) => (
      <div key={key} className="input-container">
        <div>{key}</div>
        {typeof data[key] === "boolean" ? (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={this.state.data[key]}
                  onChange={() => this.handleChange(key, !this.state.data[key])}
                />
              }
            />
          </FormGroup>
        ) : Array.isArray(data[key]) ? (
          <div>
            <div style={{ display: "flex" }}>
              <input
                className="text-input"
                name={key}
                onChange={(e) => this.handleChangeForArray(e.target.value)}
                placeholder={key}
              />
              <div
                style={{
                  height: 40,
                  width: 40,
                  fontSize: 25,
                  color: "blue",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.state.arrayInput.length > 0 && this.handleAddToArray(key)
                }
              >
                +
              </div>
            </div>
            <div style={{ margin: "15px 0 0 30px" }}> {key} Added</div>
            {data[key].length > 0 && (
              <div>{this.renderArrayPushed(data, key)}</div>
            )}
          </div>
        ) : data[key] === null ? (
          <input
            name="MainPicture"
            id="MainPicture"
            accept="image/*"
            type="file"
            onChange={(e) => {
              this.handleChange(key, e.target.files[0]);
            }}
          />
        ) : (
          <input
            className="text-input"
            name={key}
            onChange={(e) => this.handleChange(key, e.target.value)}
            placeholder={key}
            value={this.state.data[key]}
          />
        )}
      </div>
    ));
    return (
      <div>
        {newData}
        <button
          style={{
            padding: 10,
            marginTop: 20,
          }}
          onClick={() => {
            this.handleSend(this.state.dataIndex);
          }}
        >
          Send {this.state.apiSelected}
        </button>
      </div>
    );
  };

  renderArrayPushed = (data, key) => {
    return this.state.data[key].map((item, index) => (
      <div
        style={{
          padding: 10,
          border: "1px solid #e5e5e5",
          borderRadius: 5,
          display: "flex",
          margin: "15px 0 0 30px",
          width: 300,
          justifyContent: "space-between",
        }}
        className="item arrays"
      >
        {item}
        <div
          style={{ padding: 5, color: "red", cursor: "pointer" }}
          onClick={() => this.removeItem(key, index)}
        >
          x
        </div>
      </div>
    ));
  };

  removeItem = (key, index) => {
    let newItems = this.state.data[key].slice();
    newItems.splice(index, 1);
    this.setState({ data: { ...this.state.data, [key]: newItems } });
  };

  handleAddToArray = (key) => {
    let newData = this.state.data[key].slice();
    newData.push(this.state.arrayInput);
    console.log(
      newData,
      key,
      this.state.data,
      "this.state.data[key] desde el handle to array"
    );

    this.setState({
      data: { ...this.state.data, [key]: newData, arrayInput: "" },
    });
    console.log(this.state.data, "despues del handloe to array");
  };

  handleChangeForArray = (value) => {
    console.log(value, "value desde el handle change array");
    this.setState({
      arrayInput: value,
    });
  };

  handleRenderButtons = () => {
    return apisButton.map((item, index) => {
      return (
        <div key={index}>
          <div
            key={item}
            onClick={() => {
              this.setState({
                apiSelected: item.button,
                data: item.data,
                dataItem: item,
                dataIndex: index,
              });
            }}
            className="api-button"
          >
            {item.button}
          </div>
        </div>
      );
    });
  };

  handleSend = (index) => {
    let data = { ...this.state.data };
    const dataArray = Object.keys(data);
    dataArray.forEach((element) => {
      if (data[element].length < 1) {
        delete data[element];
      }
    });
    if (
      apisButton[index]?.button === "Create School" ||
      apisButton[index]?.button === "Edit School"
    ) {
      if (apisButton[index].button === "Create School") {
        getCreateTokenService({ PermissionRequired: 1 }).then((response) =>
          apisButton[index]
            .event(data, response.data.body)
            .then((data) => console.log(data))
            .catch((err) => console.log(err, "error handle send"))
        );
      } else {
        getCreateTokenService({ PermissionRequired: 2 }).then((response) =>
          apisButton[index]
            .event(data, response.data.body)
            .then((data) => console.log(data))
            .catch((err) => console.log(err, "error handle send"))
        );
      }
    } else {
      apisButton[index]
        .event(data)
        .then((data) => console.log(data))
        .catch((err) => console.log(err, "error handle send"));
    }

    console.log(this.state.data, "data para mandar");
  };

  handleChange = (item, value) => {
    this.setState({ data: { ...this.state.data, [item]: value } });
  };

  render() {
    console.log()

    return (
      <div className="container">
        <div className="left-container">{this.handleRenderButtons()}</div>
        <div className="right-container">
          <h3>{this.state.apiSelected}</h3>
          {/* {this.state.renderData} */}
          {this.state.dataItem &&
            this.state.apiSelected === this.state.dataItem.button &&
            this.handleDataInformation(this.state.data, this.state.dataIndex)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
    login: state.loginReducer,
    school: state.schoolReducer.school,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSchool: (params) => {
      dispatch(getSchool(params));
    },
    loginAttempt: (user) => {
      dispatch(loginAttempt(user));
    },
    registerAttempt: (user) => {
      dispatch(registerAttempt(user));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Apis);
