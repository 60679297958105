import React, { useState, useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  createWorkshop,
  editWorkshop,
  getWorkShop,
} from "../../services/workshopServices";

import { getSchoolAllClasses } from "../../services/schoolServices";

import DatePicker from "../../components/Admin/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  title: Yup.string().required("You must add a title!"),
  zoomLink: Yup.string().required("You must add a last name!"),
  startingDate: Yup.string().required("You must add a display name!"),
  endDate: Yup.string().required("You must add a username!"),
});

const CreateWorkshop = ({ handleModalOpen, workShopId, isEdit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [worskShop, setWorkShop] = useState({
    title: "",
    zoomLink: "",
    startingDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      getWorkShop({ id: workShopId }).then((res) => {
        const { startingDate, title, zoomLink, endDate } = res.data.body;
        setWorkShop({ startingDate, title, zoomLink, endDate });
        setIsLoading(false);
      });
    }
  }, []);

  const handleSend = (values) => {
    setIsLoading(true);
    if (!isEdit) {
      createWorkshop(values)
        .then((res) => {
          setIsLoading(false);
          if (res.data.success) {
            handleModalOpen({
              title: `Create`,
              description: `Created successfully`,
            });
          } else {
            handleModalOpen({
              title: `Create`,
              description: `Something went wrong, try again later`,
            });
          }
        })
        .catch(() => {
          setIsLoading(false);
          handleModalOpen({
            title: `Create`,
            description: `Something went wrong, try again later`,
          });
        });
    } else {
      editWorkshop({ ...values, id: workShopId })
        .then((res) => {
          if (res.data.success) {
            handleModalOpen({
              title: `Edit`,
              description: `Edited successfully`,
            });
            const { startingDate, title, zoomLink, endDate } = res.data.body;

            setWorkShop({ startingDate, title, zoomLink, endDate });
          } else {
            handleModalOpen({
              title: `Edit`,
              description: `Something went wrong, try again later`,
            });
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
          handleModalOpen({
            title: `Edit`,
            description: `Something went wrong, try again later`,
          });
        });
    }
  };

  const handleInputs = (
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue
  ) => {
    const valuesArray = Object.keys(worskShop);
    return valuesArray.map((item) =>
      item !== "startingDate" && item !== "endDate" ? (
        <div
          key={item}
          className={`input-container w-70 border-none margin-none`}
        >
          <div className="edit-input-label">{item}</div>
          <input
            className="text-input"
            name={`${item}`}
            onChange={(e) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
            type={item === "Password" ? "password" : "text"}
            value={values[item]}
            placeholder={item}
          />
          <div className="error-span">
            {errors[item] && touched[item] && errors[item]}
          </div>
        </div>
      ) : (
        <div
          key={item}
          className={`input-container w-70 border-none margin-none`}
        >
          <div className="edit-input-label">{item}</div>

          <DatePicker
            initialDate={
              values[item] !== "" ? new Date(values[item]) : new Date()
            }
            showTimeSelect
            dateFormat="Pp"
            setDate={(date) => setFieldValue(item, date)}
          />
        </div>
      )
    );
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <Formik
      initialValues={worskShop}
      validationSchema={Schema}
      onSubmit={handleSend}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <div className="create-container">
          {handleInputs(
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue
          )}

          <div className="register-input-container-mobile">
            <button className="modal-button edit-button" onClick={handleSubmit}>
              {isEdit ? "Edit" : "Create"}
            </button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreateWorkshop;
