import {
  LOGIN_ATTEMPT,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  SET_LOGIN_ERROR,
  SET_LOG_OUT,
} from "./action-types/app-action-types";
import { login, register } from "../../services/authServices";
import { roles, parseUserApprovedStatus } from "../../utils/constants";
import { workShopRegister } from "../../services/invitationServices";

const isLogin = () => ({ type: LOGIN_ATTEMPT });

export const loginFailed = (payload) => ({ type: LOGIN_FAILED, payload });
export const loginSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });

export const setError = (payload) => ({ type: SET_LOGIN_ERROR, payload });
export const logOut = () => {
  localStorage.removeItem("token");
  return { type: SET_LOG_OUT };
};

export const loginAttempt = (user) => async (dispatch) => {
  try {
    dispatch(isLogin());
    const response = await login(user);
    const userResponse = response.data.body;
    console.log("userResponse: ", userResponse);
    if (response.data.success) {
      localStorage.setItem("token", "Bearer " + userResponse.token);
      localStorage.setItem("user.displayname", userResponse.displayName);
      localStorage.setItem(
        "user.profileImageUrl",
        userResponse.profileImageUrl
      );
      localStorage.setItem("user.userName", userResponse.userName);
      dispatch(
        loginSuccess({
          approvalStatus: parseUserApprovedStatus(userResponse.approvalStatus),
          displayName: userResponse.displayName,
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          phone: userResponse.phone,
          firstRole: parseRole(userResponse.firstRole),
          userName: userResponse.userName,
          profileImageUrl: userResponse.profileImageUrl,
          birthDate: userResponse.birthDate,
        })
      );
    } else {
      dispatch(
        loginFailed({ type: "General", message: response.data.fancyError })
      );
    }
  } catch (err) {
    dispatch(
      loginFailed({
        type: "General",
        message: "Something went wrong, try again later",
      })
    );
  }
};

export const registerAttempt = (user) => async (dispatch) => {
  try {
    const errors = {
      10: "Email",
      15: "Username",
      16: "Email",
      7: "Password",
    };
    dispatch(isLogin());
    const response = await register({
      ...user,
      ConfirmEmail: user.Email,
      ConfirmPassword: user.Password,
    });
    const userResponse = response.data.body;

    if (response.data.success) {
      localStorage.setItem("token", "Bearer " + userResponse.token);
      dispatch(
        loginSuccess({
          approvalStatus: parseUserApprovedStatus(userResponse.approvalStatus),
          displayName: userResponse.displayName,
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          phone: userResponse.phone,
          firstRole: parseRole(userResponse.firstRole),
          userName: userResponse.userName,
        })
      );
    } else {
      dispatch(
        loginFailed({
          type:
            errors[
              response.data.errorCode === 15
                ? response.data.fancyError.includes("Email")
                  ? 16
                  : 15
                : response.data.errorCode
            ],
          message: response.data.fancyError,
        })
      );
    }
  } catch (err) {
    dispatch(
      loginFailed({
        type: "General",
        message: "Something went wrong, try again later",
      })
    );
  }
};

export const workShopRegisterAttempt = (user, token, callback) => async (
  dispatch
) => {
  try {
    const errors = {
      10: "Email",
      15: "Username",
      16: "Email",
      7: "Password",
    };
    dispatch(isLogin());
    const response = await workShopRegister(user, token);
    const userResponse = response.data.body;
    if (response.data.success) {
      localStorage.setItem("token", "Bearer " + userResponse.token);

      dispatch(
        loginSuccess({
          approvalStatus: parseUserApprovedStatus(userResponse.approvalStatus),
          displayName: userResponse.displayName,
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          phone: userResponse.phone,
          firstRole: parseRole(userResponse.firstRole),
          userName: userResponse.userName,
        })
      );

      callback();
      //history.replace(`/invitation/${token}`)
    } else {
      dispatch(
        loginFailed({
          type:
            errors[
              response.data.errorCode === 15
                ? response.data.fancyError.includes("Email")
                  ? 16
                  : 15
                : response.data.errorCode
            ],
          message: response.data.fancyError,
        })
      );
    }
  } catch (err) {
    dispatch(
      loginFailed({
        type: "General",
        message: "Something went wrong, try again later",
      })
    );
  }
};

export function parseRole(firstRole) {
  for (var role in roles) {
    if (roles.hasOwnProperty(role) && roles[role] === firstRole) {
      return role;
    }
  }
  return null;
}
