import React from "react";
import NextButton from "../NextButton";
import "./LastPage.css";

import wellDone from "../../../utils/images/well_done.png";
import largeBubble from "../../../utils/images/large_bubble.png";
import Title from "../Title";
import Subtitle from "../Subtitle";
import Paragraph from "../Paragraph";
import { Link, useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect'

const LastPage = (props) => {
  const history = useHistory();
  return (
    <div className={(isMobile || props.mobile) ? `last-page last-page-container last-page-container-mobile` : `last-page last-page-container`}>
      <div className="last-page-content">
        <div className="poem-background" />
        <img className="well-done-image" src={wellDone} alt="wellDone" />
        <Title className="fs-60" data="Well done!" />
        <Subtitle
          className="crimson"
          data={"<i><b>Keep writing your own story.</b></i>"}
        />
        {/* <Paragraph data="Now take time to read, like, and comment on other people’s poems. " /> */}
        <>
          <div className="next-button-container last-page">
            <div className="next-button">
              <div className="second-triangle" />

              {/* the props isPreviewing and the props function handlePreviewClose come from the createTaskComponent */}
              <div onClick={() => {
                props.isPreviewing === true ?
                props.handlePreviewClose()
                :
                history.push("/land")
                }} className="poem-triangle">
                <div className="triangle" />
                <div className="bubble-text">Back to Home</div>
              </div>
            </div>
          </div>

          {/* <div className="back-button-container">
            <div className="back-button">
              <img className="large-bubble-image" src={largeBubble} alt="bubble" />
                <Link className="back-link" to="/">Back to Home</Link>
            </div>
          </div> */}
        </>
      </div>
    </div>
  );
};

export default LastPage;
