import React, { useState, useEffect } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";

import {
  getAllSchoolService,
  getSchoolAllClasses,
  schoolRemoveClass,
} from "../../services/schoolServices";
import moment from "moment";
import blankImage from "../../utils/images/blank_user.png";
import DatePicker from "../../components/Admin/DatePicker";
import "react-datepicker/dist/react-datepicker.css";

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const ClassesList = ({
  dataList,
  updateInfo,
  handleModalOpen,
  setInformation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schoolsOptions, setSchoolOptions] = useState([]);
  const [classes, setClasses] = useState(null);
  const [error, setError] = useState("");
  const [schoolSelected, setSchoolSelected] = useState("");
  useEffect(() => {
    getAllSchoolService()
      .then((res) => {
        const options = [];
        const response = res.data.body;
        response.forEach((element) => {
          if (element.approvalStatus === 3) {
            options.push({ value: element.id, label: element.name });
          }
        });
        setSchoolOptions(options);
      })
      .catch((err) => setSchoolOptions([]));
  }, []);

  return (
    <div className="class-list-container">
      <div className="register-input-container list-select">
        <Select
          styles={customStyles}
          className="register-select"
          placeholder="School"
          options={schoolsOptions}
          onChange={(item) => {
            setIsLoading(true);
            setSchoolSelected(item.value);
            getSchoolAllClasses({ schoolId: item.value })
              .then((res) => {
                setIsLoading(false);
                setClasses(res.data.body);
                console.log(res, "get school all clases");
              })
              .catch((err) => {
                setIsLoading(false);
                setError("Someting went wrong, try again later");
                console.log(err, "err al classs");
              });
          }}
        />
      </div>
      {isLoading ? (
        <div className="loading-container">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="class-list-table-container">
          {error !== "" && <div>{error}</div>}

          {classes && (
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className={"dashboard-list-container"}
            >
              {classes.length === 0 && <div>No information available</div>}
              {classes.length > 0 && (
                <ListItem className={`dashboard-list-item`}>
                  <ListItemText className="list-item-text" primary={"Title"} />
                  <ListItemText
                    className="list-item-text"
                    primary={"Person in charge"}
                  />
                  <ListItemText className="list-item-text" primary={""} />
                </ListItem>
              )}
              {classes.map((item) => (
                <ListItem className={`dashboard-list-item`}>
                  <ListItemText
                    className="list-item-text"
                    primary={item.title}
                    button
                  />
                  <ListItemText
                    className="list-item-text"
                    primary={item.personInChargeName}
                    button
                  />
                  <ListItemText
                    className="list-item-text button-text"
                    primary="Delete"
                    onClick={() => {
                      setIsLoading(true);
                      schoolRemoveClass({
                        schoolId: schoolSelected,
                        classId: item.id,
                      }).then((res) => {
                        console.log(res)
                        const newClasses = classes.slice();

                        newClasses.forEach((el, index) => {
                          if (el.id === item.id) {
                            newClasses.splice(index, 1);
                          }
                        });
                        setIsLoading(false);
                        setClasses(newClasses);
                      });
                    }}
                    button
                  />
                </ListItem>
              ))}
            </List>
          )}
        </div>
      )}
    </div>
  );
};

export default ClassesList;
