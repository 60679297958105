import React, { useEffect, useState } from "react";

import "./LineInput.css";

import useWindowDimensions from "../../../utils/resizeHelper"

const LineInput = ({
  value,
  itemIndex,
  onInputChange,
  placeholder,
  rowCount,
  textLength,
  disabled,
  inputIndex,
}) => {
  const [inputValue, setValue] = useState(value);
  
  useEffect(() => {
    setValue(value);
  }, [value]);
  const {width} = useWindowDimensions();

  const topHeigth = width > 3000 ? 50 :  40;

  useEffect(() => {
    return setValue("");
  }, [])

  const createLines = () => {
    let lines = [];

    for (let i = 0; i < rowCount; i++) {
      lines.push(
        <div
          key={Math.random(1000)}
          style={{
            position: "absolute",
            top: topHeigth * (i + 1),
            width: "100%",
            height:  width > 3000 ? 3 : 1,
            background: "#e5e5e5",
          }}
        />
      );
    }

    return lines;
  };

  return (
    <>
      <textarea
        placeholder={placeholder}
        onChange={(e) => onInputChange(e.target.value)}
        rows={rowCount}
        col={30}
        disabled={disabled}
        inputIndex={inputIndex}
        style={{
          textIndent: textLength ? textLength + (width > 3000 ? 20 : 5) : 0,
          resize: "none",
          fontSize: width > 3000 ? 30 : 20,
          height:  (width > 3000 ? 50 : 40) * rowCount,
          lineHeight: "55px",
        }}
        className={`line-input text-area ${disabled ? "disabled" : ""}`}
        type="text"
      >
       {inputValue} 
      </textarea>
      {createLines()}
    </>
  );
};

export default LineInput;
