import React, { useState, useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  createWorkshop,
  editWorkshop,
  getWorkShop,
} from "../../services/workshopServices";
import { getCompetency } from "../../services/chapterServices";
import blankImage from "../../utils/images/blank_user.png";

import { getSchoolAllClasses } from "../../services/schoolServices";

import DatePicker from "../../components/Admin/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Line } from "react-chartjs-2";
import Graphyc from "./Graphyc";

const SchoolDetails = ({ schoolInformation }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [competencies, setCompetencies] = useState({});
  const schoolInformationArray = Object.keys(schoolInformation);

  useEffect(() => {
    setIsLoading(true);
    getCompetency({})
      .then((res) => {
        const response = res.data.body;
        const competenciesResponse = {};
        response.forEach((el) => (competenciesResponse[el.id] = el.name));
        setIsLoading(false);
        setCompetencies(competenciesResponse);
      })
      .catch((err) => {});
  }, []);

  const renderInputs = () =>
    schoolInformationArray.map(
      (key) =>
        key !== "chapterStatistics" &&
        key !== "competencyAnalytics" &&
        key !== "mainPictureUrl" &&
        key !== "schoolId" &&
        key !== "approvalStatus" && (
          <div className="school-details-row">
            <div className="school-details-text-label">{key}:</div>
            <div className="school-details-text">{schoolInformation[key]}</div>
          </div>
        )
    );

  const renderCompetenciesGraphycs = () => {
    const graphyc = {
      labels: [],
      dataSets: [
        {
          name: "Competencies",
          points: [],
        },
      ],
    };

    schoolInformation.competencyAnalytics.forEach((el, index) => {
      graphyc.labels.push(competencies[el.competencyId]);
      graphyc.dataSets[0].points.push([el.averageSelfEvaluationValue]);
    });

    return <Graphyc graphyc={graphyc}  colors={["#91B93E"]}  />;
  };

  const renderSchoolInformation = () => {
    return (
      <div className="school-information-details">
        <div className="school-image-container">
          <label className="main-image-container">
            <img
              id="main-image"
              className={`${
                schoolInformation["mainPictureUrl"] ? "" : "blank-image"
              }`}
              src={
                schoolInformation["mainPictureUrl"]
                  ? schoolInformation["mainPictureUrl"]
                  : blankImage
              }
              alt="main"
            />
          </label>
        </div>
        <div className="rows-container">{renderInputs()}</div>
      </div>
    );
  };

  const renderChapterGraphyc = () => {
    const graphyc = {
      labels: [],
      dataSets: [
        {
          name: "Students Count",
          points: [],
        },
      ],
    };

    schoolInformation.chapterStatistics.forEach((el, index) => {
      graphyc.labels.push(el.chapterTitle);
      graphyc.dataSets[0].points.push([el.studentCount]);
    });

    return (
      <Graphyc graphyc={graphyc} maxNumber={schoolInformation.studentCount} colors={["#F0E42F"]} />
    );
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div className="school-details-container">
      {renderSchoolInformation()}
      <div className="graphyc-container">{renderCompetenciesGraphycs()}</div>
      <div className="graphyc-container">{renderChapterGraphyc()}</div>
    </div>
  );
};

export default SchoolDetails;
