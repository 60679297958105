import React, { useRef, useEffect, useState } from "react";
import LineInput from "./LineInput";

const Row = ({
  text,
  inputValue,
  placeholder,
  className,
  rowCount,
  onInputChange,
  itemIndex,
  stepPosition,
  buttonDisabled,
}) => {
  const [textWidth, setTextWidth] = useState(0);
  const [value, setValue] = useState(inputValue);
  const paragraph = useRef();
  useEffect(() => {
    setTextWidth(paragraph.current.offsetWidth);
  }, [text]);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  return (
    <div className={"row-container " + className}>
      {/* <Paragraph
        ref={paragraph}
        width={textLength * 10}
        height={42}
        className="row-paragraph crimson"
        data={text}
      /> */}

      <div ref={paragraph} style={{height: 42, marginTop: 15}} className={"poem-paragraph  row-paragraph crimson"} dangerouslySetInnerHTML={{__html: text }} />
      <LineInput
        rowCount={rowCount}
        disabled={buttonDisabled}
        textLength={textWidth}
        value={value}
        inputIndex={stepPosition}
        itemIndex={itemIndex}
        onInputChange={ text => onInputChange(text, itemIndex, stepPosition)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Row;
