import React, { useState, useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import {
  workShopAddClasses,
  getWorkShop,
  workShopRemoveClasses,
} from "../../services/workshopServices";
import Select from "react-select";

import {
  getAllSchoolService,
  getSchoolAllClasses,
} from "../../services/schoolServices";

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const WorkShopAddClasses = ({ handleModalOpen, updateInfo, workshop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [classes, setClasses] = useState([]);
  const [classesToAssing, setClassesToAssing] = useState([]);
  const [assignedClasses, setAssignedClasses] = useState([]);
  const [schoolsOptions, setSchoolOptions] = useState([]);
  const [error, setError] = useState("");
  const [schoolSelected, setSchoolSelected] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getWorkShop({ id: workshop.id })
      .then((response) => {
        const workshopAsignedClasses = response.data.body.classes;
        getAllSchoolService().then((res) => {
          const options = [];
          const response = res.data.body;
          const schoolClasses = response.slice();

          response.forEach((element) => {
            if (element.approvalStatus === 3) {
              options.push({ value: element.id, label: element.name });
            }
          });

          setClasses(schoolClasses);
          setSchoolOptions(options);
          setAssignedClasses(workshopAsignedClasses);
          setIsLoading(false);
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setSchoolOptions([]);
      });
  }, []);

  const renderClasses = () => {
    let filteredClasses = classes.filter(
      (item) =>
        item.title?.toLowerCase().includes(searchField.toLowerCase()) ||
        item.personInChargeName?.toLowerCase().includes(searchField)
    );

    const addClass = (data) => {
      const newClasses = classes.slice();
      const newClassesToAssign = classesToAssing.slice();

      const index = newClasses.indexOf(data);

      newClasses.splice(index, 1);
      newClassesToAssign.push(data);

      setClasses(newClasses);
      setClassesToAssing(newClassesToAssign);
    };

    return filteredClasses.length > 0 ? (
      filteredClasses.map((item) => (
        <div className="class-item assign-students">
          <div>
            <div>{item.title}</div>
          </div>

          <div className="add-button" onClick={() => addClass(item)}>
            Add Class
          </div>
        </div>
      ))
    ) : (
      <div className="class-item assign-students">
        There is no classes in this school
      </div>
    );
  };

  const renderClassesToAssign = () => {
    const deleteClass = (data, index, id) => {
      const newClasses = classes.slice();
      const newClassesToAssing = classesToAssing.slice();

      newClassesToAssing.splice(index, 1);
      newClasses.push(data);
      setClasses(newClasses);
      setClassesToAssing(newClassesToAssing);
    };

    return classesToAssing.length > 0 ? (
      classesToAssing.map((item, index) => (
        <div className="class-item assign-students">
          <div>{item.title}</div>
          <div
            className="add-button"
            onClick={() => deleteClass(item, index, item.id)}
          >
            Cancel
          </div>
        </div>
      ))
    ) : (
      <div className="class-item assign-students">No classes to assign</div>
    );
  };

  const renderAssignedClasses = () => {
    const deleteClass = (data, index, id) => {
      setIsLoading(true);
      workShopRemoveClasses({ classesIds: [id], id: workshop.id }).then(
        (res) => {
          if (res.data.success) {
            const newClasses = classesToAssing.slice();
            const newAssignedClasses = assignedClasses.slice();

            newAssignedClasses.splice(index, 1);
            newClasses.push(data);
            setClassesToAssing(newClasses);
            setAssignedClasses(newAssignedClasses);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      );
    };

    return assignedClasses.length > 0 ? (
      assignedClasses.map((item, index) => (
        <div className="class-item assign-students">
          <div>{item.title}</div>
          <div
            className="add-button"
            onClick={() => deleteClass(item, index, item.id)}
          >
            Remove Class
          </div>
        </div>
      ))
    ) : (
      <div className="class-item assign-students">No assigned classes</div>
    );
  };

  const search = (toSearch) => {
    setSearchField(toSearch);
  };

  const addClasses = () => {
    const classesIds = classesToAssing.map((el) => el.id);
    setIsLoading(true);
    workShopAddClasses({ id: workshop.id, classesIds })
      .then((res) => {
        setIsLoading(false);
        if (res.data.success) {
          handleModalOpen({
            title: "Add Class",
            description: `Class added successfully!`,
          });
          const newAssignedClasses = [...assignedClasses, ...classesToAssing];
          setAssignedClasses(newAssignedClasses);
          setClassesToAssing([]);
        } else {
          handleModalOpen({
            title: "Error",
            description: `Try again later`,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleModalOpen({
          title: "Error",
          description: `Try again later`,
        });
      });
  };

  return (
    <div className="add-class-container">
      <div className="register-input-container list-select">
        <Select
          styles={customStyles}
          className="register-select"
          placeholder="School"
          options={schoolsOptions}
          onChange={(item) => {
            setIsLoading(true);

            getSchoolAllClasses({ schoolId: item.value })
              .then((res) => {
                if (res.data.success) {
                  const schoolClasses = res.data.body.slice();
                  const allAsignedClasses = [
                    ...classesToAssing,
                    ...assignedClasses,
                  ];
                  res.data.body.forEach((element) => {
                    allAsignedClasses.forEach((el) => {
                      if (el.id === element.id) {
                        const index = schoolClasses.indexOf(element);
                        if (index > -1) {
                          schoolClasses.splice(index, 1);
                        }
                      }
                    });
                  });
                  setSchoolSelected(true);
                  setIsLoading(false);
                  setClasses(schoolClasses);
                } else {
                  setIsLoading(false);
                  setError("Someting went wrong, try again later");
                }
              })
              .catch((err) => {
                setIsLoading(false);
                setError("Someting went wrong, try again later");
                console.log(err, "err al classs");
              });
          }}
        />
      </div>
      {isLoading ? (
        <div className="loading-container invite-by-mail-modal">
          <CircularProgress color="secondary" />
        </div>
      ) : schoolSelected ? (
        <div>
          <div className="workshop-hint-text">Use the "Add Class" button to add the classes that you want prepare to assign to this workshop and then assign them assign them all with the "Assign Classes" button in the bottom of the page.</div>

          <div className="classes-container workshop-classes">
            <div className="all-classes-container assign-students">
              <div className="class-item assign-students">
                <div>All Classes</div>
                <div className="assign-students-top-bar">
                  <input
                    className="text-input"
                    name={`search`}
                    onChange={(e) => {
                      search(e.target.value);
                    }}
                    placeholder={"Search"}
                  />
                </div>
              </div>
              {renderClasses()}
            </div>
            <div className="all-classes-container assign-students workshop-invited mh-10">
              <div className="class-item assign-students">
                <div>Classes To Assign</div>
              </div>
              {renderClassesToAssign()}
            </div>
            <div className="all-classes-container assign-students workshop-invited">
              <div className="class-item assign-students">
                <div>Assigned Classes</div>
              </div>
              {renderAssignedClasses()}
            </div>
          </div>
        </div>
      ) : (
        <div className="workshop-hint-text"> Select a school to add classes to this workshop</div>
      )}
      <div className="workshop-header-container add-class">
        <div className="assign-students-top-bar">
          <div className="assign-button" onClick={addClasses}>
            Assign Classes
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkShopAddClasses;
