import './Preview.css';
import React from 'react';
import Task from "../../views/Task";
import ModalWrapper from '../ModalWrapper'

const Preview = ({ handleClose, show, children, handlePreviewClose , isPreviewing, isMobile}) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
  
    return (
      <div className={showHideClassName}>
        <section className={isMobile ? "modal-main-mobile" : "modal-main"}>
          <ModalWrapper handlePreviewClose={handlePreviewClose}>
            <Task isPreviewing={isPreviewing} handlePreviewClose={handlePreviewClose} isMobile={isMobile}/>
          </ModalWrapper>
        </section>
      </div>
    );
  };

export default Preview;