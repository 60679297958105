import React from 'react';
import checkVector from "../../../utils/images/check-vector.png";
import nextChapterVector from "../../../utils/images/next-chapter-vector.png";
 
function Chapter (props) {
  return (
    <div className="chapter-container">
        <div className={`chapter-circle ${!props.completed ? 'chapter-incomplete' : ''}` }>
            <div className="chapter-inner-circle">
                <div className="chapter-inner-circle-text">
                    {props.chapterNumber}
                </div>
            </div>
        {props.completed && <CompletedChapterIcon />}
        {props.isNextChapterToBeCompleted && <NextChapterIcon />}
        </div>
        <div className="chapter-text">Chapter {props.chapterNumber}</div>
    </div>
  )
}

function CompletedChapterIcon (props) {
  return (
    <div className="icon-outer">
      <div className="icon-inner">
        <div className="icon-completed">
          <img src={checkVector} alt="check-icon"/>
        </div>
      </div>
    </div>
  )
}

function NextChapterIcon (props) {
  return (
    <div className="icon-outer">
      <div className="icon-inner-incomplete">
          <img src={nextChapterVector} alt="next-chapter-icon"/>
      </div>
    </div>
  )
}
export default Chapter;