import React from "react";
import Image from "react-bootstrap/Image";
import logo from "../../utils/images/footerlogo.png";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import nate from "../../utils/images/nate.png";
import { useSelector, useDispatch } from "react-redux";
import { logOut } from "../../redux/actions/auth";
// Header styles
import "../../views/Home/Home.css";
import "./Header.css";
import useWindowDimensions from "../../utils/resizeHelper";
import { useHistory } from "react-router-dom";

const Header = ({ variant, className, home, whiteBackground }) => {
  const { isLogged, user } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const { width, height } = useWindowDimensions();
  const handleLogout = () => {
    dispatch(logOut());
    history.push("/login");
  };

  return (
    <Navbar bg={whiteBackground ? "white" : "transparent"} expand="lg" className={"px-4 header-container " + className} fixed="top">
      <Navbar.Brand onClick={() => history.push("/")}>
        <Image src={logo} style={{ cursor: 'pointer' }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        {!isLogged ? (
          <Nav className="ml-auto">
            <Button variant={"light"} onClick={() => history.push("/login")} className="mr-4 cl-white header-button">
              Log in
            </Button>
            <Button
              variant={variant ? variant : "outline-light"}
              className="btn-outline-sm font-weigth-bold mr-2 dark-color"
              onClick={() => history.push("/register")}
            >
              Sign up
            </Button>
            <Button
              variant={variant ? variant : "outline-light"}
              className="btn-outline-sm font-weigth-bold mr-2 dark-color dropdown-mobile-button logout-button"
              onClick={() => window.open('https://movementbe-mobile.azurewebsites.net/', "_blank")}
            >
              Play Now!
            </Button>
          </Nav>
        ) : (
          <Nav className="ml-auto">
            {/* <Button variant={"light"} onClick={() => history.push("/")} className="mr-4 cl-white header-button">
                    {user.displayName ? user.displayName : user.userName ? user.userName : ""}
                </Button> */}
            <Dropdown className="mr-4 cl-white header-button dropdown-mobile-button dropdown-header-button" as={ButtonGroup} alignRight>
              <Dropdown.Toggle className="dropdown-toggle header-toggle-button dropdown-mobile-button" variant={"light"} id="user-dropdown">
                {user.displayName ? user.displayName : user.userName ? user.userName : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu className="super-colors">
                {/*user.firstRole === "STUDENT" && <Dropdown.Item onClick={() => history.push("/select-chapter")} eventKey="1">Select Chapter</Dropdown.Item>*/}
                {/* {history.location.pathname !== '/my-answered-tasks' && <Dropdown.Item onClick={() => history.push("/select-chapter")} eventKey="1">Select Chapter</Dropdown.Item>} */}
                {/* {history.location.pathname !== '/select-chapter' && user.firstRole === "STUDENT" && <Dropdown.Item onClick={() => history.push("/my-answered-tasks")} eventKey="2">Answered Tasks</Dropdown.Item>} */}
                {/* {user.firstRole === "SUPER_ADMIN" && <Dropdown.Item onClick={() => history.push("/superadmin")} eventKey="2">Create Task</Dropdown.Item>}  */}
                {/* user.firstRole === "SUPER_ADMIN" && <Dropdown.Item onClick={() => history.push("/select-chapter")} eventKey="1">Select Chapter</Dropdown.Item> */}
                {user.firstRole !== "STUDENT" && <Dropdown.Item onClick={() => history.push("/testadmin")} eventKey="3">Admin Dashboard</Dropdown.Item>}
                {<Dropdown.Item style={user.approvalStatus !== 'APPROVED' ? { color: '#dddddd', cursor: 'auto' } : {}} onClick={() => { if (user.approvalStatus !== 'APPROVED') return; history.push("/land") }} eventKey="4">MovementBe Land</Dropdown.Item>}
                {<Dropdown.Item style={user.approvalStatus !== 'APPROVED' ? { color: '#dddddd', cursor: 'auto' } : {}} onClick={() => { if (user.approvalStatus !== 'APPROVED') return; history.push("/my-tests") }} eventKey="5">My Tests</Dropdown.Item>}
                {<Dropdown.Item onClick={() => history.push("/your-profile")} eventKey="5">Profile</Dropdown.Item>}
                {<Dropdown.Item onClick={() => history.push("/my-workshops")} eventKey="6">My Workshops</Dropdown.Item>}
                {width < 900 && <Dropdown.Item onClick={handleLogout} eventKey="6">Log out</Dropdown.Item>}

                {!home && <Dropdown.Item onClick={() => history.push("/")} eventKey="3">Home</Dropdown.Item>}
                {width < 900 && <Dropdown.Item onClick={() => window.open('https://movementbe-mobile.azurewebsites.net/', "_blank")} eventKey="6">Play Now!</Dropdown.Item>}

              </Dropdown.Menu>
            </Dropdown>
            {width >= 900 &&
            <>
              <Button
                variant={variant ? variant : "outline-light header-button"}
                className="btn-outline-sm font-weigth-bold mr-2 dark-color dropdown-mobile-button logout-button"
                onClick={handleLogout}
              >
                Log out
              </Button>
            <Button
              variant={variant ? variant : "outline-light header-button"}
              className="btn-outline-sm font-weigth-bold mr-2 dark-color dropdown-mobile-button logout-button"
              onClick={() => window.open('https://movementbe-mobile.azurewebsites.net/', "_blank")}
            >
              Play Now!
            </Button>
              </>
            }
          </Nav>

          //   <Dropdown className="ml-auto" as={ButtonGroup} alignRight>
          //     <Dropdown.Toggle id="user-dropdown">
          //       <Image src={nate} className="user-img" />
          //     </Dropdown.Toggle>
          //     <Dropdown.Menu className="super-colors">
          //       <Dropdown.Item eventKey="1">Action</Dropdown.Item>
          //       <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
          //       <Dropdown.Item eventKey="3">Active Item</Dropdown.Item>
          //       <Dropdown.Divider />
          //       <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
          //     </Dropdown.Menu>
          //   </Dropdown>
        )}

        {/* className="ml-auto" ADD THIS CLASSNAME to the dropdown below once the user is logged ↓ */}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
