import React from "react";
import { Line } from "react-chartjs-2";

const Graphyc = ({ graphyc , maxNumber, colors}) => {
  const competencyColors = {
    0: "#F0E42F",
    1: "#FFCA38",
    2: "#ADFF02",
    3: "#91B93E",
    4: "#F07F2F",
  };

  const renderGraphyc = () => {
    const dataSets = graphyc.dataSets.map((el, index) => ({
      label: el.name,
      fill: false,
      backgroundColor: colors[index],
      borderColor: colors[index],
      pointBorderColor: colors[index],
      pointBackgroundColor: "#fff",
      pointHoverBackgroundColor: colors[index],
      pointHoverBorderColor: "rgba(220,220,220,1)",
      lineTension: 0.1,
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderWidth: 0,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: el.points,
    }));

    const data = {
      labels: graphyc.labels,
      datasets: dataSets,
    }; 

    return (
      <Line
      options={{ scales: { yAxes: [{ ticks: { max: maxNumber ? maxNumber: 5, min: 0 } }] } }}
        data={data}
      />
    );
  };

  return renderGraphyc();
};

export default Graphyc;
