import axios from 'axios';
import jwt_decode from "jwt-decode";
import { loginFailed } from "../redux/actions/auth";

const axiosInstance = axios.create({
  //baseURL: `https://movement-be-backend.azurewebsites.net/`,
  //baseURL: 'https://movementbe-dev.azurewebsites.net/',
  // baseURL: 'https://movementbe-staging.azurewebsites.net/',
  baseURL: 'https://movementbe-production.azurewebsites.net/',
  headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
});

export const interceptor = (store) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");

      const newConfig = config;
      if (token) {
        newConfig.headers.Authorization = token;
      }
      return newConfig;
    },
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (next) => {
      return Promise.resolve(next);
    },
    (error) => {
      const token = localStorage.getItem("token");

      if (!token) {
        store.dispatch(loginFailed("Token expired"));
      } else {
        const newDate = new Date();
        const tokenDate = new Date(jwt_decode(token).exp * 1000);
        if(newDate >= tokenDate){
          store.dispatch(loginFailed("Token expired"));
        }
      }
         
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;

  // Axios.interceptors.request.use(
  //   (conf) => {
  //     // you can add some information before send it.
  //     // conf.headers['Auth'] = 'some token'
  //     return conf;
  //   },
  //   (error) => {
  //     return Promise.reject(error);
  //   }
  // );
  // Axios.interceptors.response.use(
  //   (next) => {
  //     return Promise.resolve(next);
  //   },
  //   (error) => {
  //     // You can handle error here and trigger warning message without get in the code inside
  //     store.dispatch({
  //       type: env.actionsTypes.openModal,
  //       message: error.message,
  //     });
  //     return Promise.reject(error);
  //   }
  // );


// export default axiosInstance;
