import {
    CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_ATTEMPT,
    CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_SUCCESS,
CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_FAILED,
} from './action-types/app-action-types';
import { changeSchoolAdminApprovedStatus } from "../../services/schoolAdminServices";

const isChangeSchoolAdminApprovedStatus = () => ({type: CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_ATTEMPT})

export const changeSchoolAdminApprovedStatusFailed = (payload) => ({type: CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_FAILED, payload});
export const changeSchoolAdminApprovedStatusSuccess = (payload) => ({type: CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_SUCCESS, payload});

export const changeSchoolAdminApprovedStatusAttempt = (schoolAdminApprovedStatus_data) => (
    async (dispatch) => {
        try {
            dispatch(isChangeSchoolAdminApprovedStatus());
            const response = await changeSchoolAdminApprovedStatus(schoolAdminApprovedStatus_data);
            if( response.data.success ) {
                dispatch(
                    changeSchoolAdminApprovedStatusSuccess());
             } else {
                dispatch(changeSchoolAdminApprovedStatusFailed(response.data.errorMessage));
             }
        } catch (err) {
            dispatch(changeSchoolAdminApprovedStatusFailed(err))
        }
    }
);



