import React, { useEffect, useState } from "react";
import "./Chapter.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { getAllTest } from "../../../services/schoolServices";
import CircularProgress from "@material-ui/core/CircularProgress";
import { statuses } from "./data";
import Item from "./Item";
import DropWrapper from "./DropWrapper";
import blankImage from "../../../utils/images/blank_user.png";
import {
  createChapter,
  getCompetency,
  editChapter,
} from "../../../services/chapterServices";

const CreateChapter = ({ handleModalOpen, callback , isEdit, data}) => {
  const [name, setName] = useState(data ? data.name :"");
  const [description, setDescription] = useState(data ? data.description :"");
  const [firstTemplates, setFirstTemplates] = useState([]);

  const [picture, setPicture] = useState(null);
  const [competency, setCompetency] = useState([{ name: "Task list" }]);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState(data ? data.chapterTestTemplates : []);
  const [chapterSaved, setChapterSaved] = useState(true);


  const onDrop = (item, monitor, status, statusId) => {
    setItems((prevState) => {
      let items = prevState;
      const statusItems = prevState.filter((i) => i.status === status);


      if (statusItems.length > 0) {
        items = items.map((e, i) => {
          if (e.status === status) {
            items[i].status = "Task list";
          }
          return e;
        });
      }

    

      const newItems = items
        .filter((i) => i.id !== item.id)
        .concat({ ...item, status, statusId: statusId });

      return [...newItems];
    });
    setChapterSaved(false);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const item = items[dragIndex];
    setItems((prevState) => {
      const newItems = prevState.filter((i, idx) => idx !== dragIndex);
      newItems.splice(hoverIndex, 0, item);
      return [...newItems];
    });
  };

  useEffect(() => {
    getTask();
    getCompetency({}).then((res) => {
      let newCompetency = [...competency, ...res.data.body];
      setCompetency(newCompetency);
    });
  }, []);

  const getTask = () => {
    setIsLoading(true);
    getAllTest({})
      .then((res) => {
        setIsLoading(false);
        const listArray = res.data.body;
        let newItems = items;
        let itemsIds = [];
        const firstTemplatesId = [];
        console.log(newItems, "newItems")
        if(items) {
            newItems = newItems.map(el => {
              itemsIds.push(el.testTemplateId);
              firstTemplatesId.push(el.id);
              return {
                ...el,
                type: "ITEM",
                title: el.name,
                status: el.compatencyName,
                isFirst: true,
                statusId: el.competencyId,
                id: el.testTemplateId
              }
            }
          );
        }
        setFirstTemplates(firstTemplatesId);
        const filteredArray = [];
        listArray.forEach((el , index) => {
          let isTrue = true;
          itemsIds.forEach((item) => {
            if(item === el.id) isTrue = false 
          })
          if (isTrue) {
            filteredArray.push(el)
          }
        });

        const newArray = filteredArray.map((item) => (
          item.compatencyName ? 
          {
            ...item,
            status: item.compatencyName,
            type: "ITEM",
          }
            : 
          {
            ...item,
            compatencyName: "Task list",
            status: "Task list",
            statusId: null
          }
        ));
      
        const allTask = [...newItems, ...newArray];
        setItems(allTask);
      })
      .catch((err) => setIsLoading(false));
  };

  const renderFields = () =>
    competency.map((s) => {
      return (
      <div
        key={"statuses-" + s.name}
        className={`col-wrapper ${
          s.name === "Task list" ? "task-list-wrapper" : ""
        }`}
      >
        <DropWrapper key={"statuses-" + s.name} onDrop={(item, monitor, status) => onDrop(item, monitor, status, s.id)} status={s.name}>
          <div
            className={`chapter-field ${
              s.name === "Task list" ? "task-list-container" : ""
            }`}
          >
            <h5>{s.name}</h5>
            {items
              .filter((i) => {
                return i.status === s.name;
              })
              .map((i, idx) => (
                <>
                <Item key={i.id} item={i} index={idx} moveItem={moveItem} showEye={s.name === "Task list"}/>
                </>
              ))}
          </div>
        </DropWrapper>
      </div>
    )});

  const handleSend = () => {
    setIsLoading(true);
    setChapterSaved(true);
    const filteredItems = items.filter(
      (i) => i.status !== "Task list"
    );
    const templates = filteredItems.map((e) => ({TemplateId: e.id, CompetencyId: e.statusId}))
    const createdChapter = {
      name,
      description,
      picture,
      Activate: true,
      chapterTestTemplates: templates,
    };

    if(!picture) {
      delete createdChapter.picture;
    }
    if(!isEdit) {
      if(templates.length === 5){
        createChapter(createdChapter)
        .then((res) => {
          setIsLoading(false);
          if(res.data.success){
            handleModalOpen({
              title: `Create Chapter`,
              description: `Chapter created successfully`,
            });
            callback();
          } else {
            handleModalOpen({
              title: `Create Chapter`,
              description: `Something went wrong , try again lather`,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleModalOpen({
            title: `Create Chapter`,
            description: `Something went wrong , try again lather`,
          });
        });
      } else {
        setIsLoading(false);

        handleModalOpen({
          title: `Create Chapter`,
          description: `You must add the 5 competencies!`,
        });
      }
    } else {
      if(createdChapter.chapterTestTemplates.length === 5){
        let ChapterTestTemplatesToAdd = [];
        const ChapterTestTemplatesToRemoveIds = firstTemplates.slice();
        console.log(ChapterTestTemplatesToAdd,ChapterTestTemplatesToRemoveIds, "ChapterTestTemplatesToRemoveIds d" )
        let indexed = firstTemplates.slice().reduce((acc, el) => ({...acc, [el]: el}), {})
        createdChapter.chapterTestTemplates.forEach((el) => {
          // ChapterTestTemplatesToRemoveIds.forEach((item, index) => {
          //   el.TemplateId === item && ChapterTestTemplatesToRemoveIds.splice(index, 1);
          // });
          ChapterTestTemplatesToAdd.push(el);
        });
  
        // ChapterTestTemplatesToAdd.forEach((el, index) => {
        //   console.log(indexed[el.TemplateId], "redurcer inindex");
        //     firstTemplates.forEach((item) => {
        //       el.TemplateId === item && ChapterTestTemplatesToAdd.splice(index, 1)
        //     });
        // }
          
        //);
  
        delete createdChapter["chapterTestTemplates"];
        console.log(ChapterTestTemplatesToAdd,ChapterTestTemplatesToRemoveIds, "ChapterTestTemplatesToRemoveIds d" )
        editChapter({...createdChapter, ChapterTestTemplatesToAdd, ChapterTestTemplatesToRemoveIds , id: data.id})
        .then((res) => {
          setIsLoading(false);
          if(res.data.success){
            handleModalOpen({
              title: `Edit Chapter`,
              description: `Chapter edited successfully`,
            });
            callback();
          } else {
            handleModalOpen({
              title: `Edit Chapter`,
              description: `Something went wrong , try again lather`,
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          handleModalOpen({
            title: `Edit Chapter`,
            description: `Something went wrong , try again lather`,
          });
        });
      } else {
        setIsLoading(false);

        handleModalOpen({
          title: `Edit Chapter`,
          description: `You must add the 5 competencies!`,
        });
      }
    }
  };

  const handleChangeImage = (value) => {
    if (value) {
      const file = value;
      if (file && file.size > 15 * 1024 * 1024) return false;
      let reader = new FileReader();
      // it's onload event and you forgot (parameters)
      reader.onload = function (e) {
        let image = document.getElementById("main-image");
        image.src = e.target.result;
      };
      reader.readAsDataURL(file);
      setPicture(value);
    }
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div className="create-chapter-container">
      <div className="inputs-container">
        <div className="create-left-container">
          <label className="main-image-container crete-chapter-container">
            <input
              name={"Picture"}
              id={"Picture"}
              accept="image/*"
              type="file"
              onChange={(e) => {
                handleChangeImage(e.target.files[0]);
              }}
            />
            <img
              id="main-image"
              className={`${picture ? "" : "blank-image"} create-chapter-image`}
              src={picture ? picture : data && data.mainPictureUrl ? data.mainPictureUrl : blankImage}
              alt="main"
            />
          </label>
          <div className="text-inputs-container">
            <input
              className="text-input"
              name={"Chapter Title"}
              onChange={(e) => setName(e.target.value)}
              placeholder={"Chapter Title"}
              defaultValue={data ? data.name : ""}
            />
            <input
              className="text-input"
              name={"Chapter Description"}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={"Chapter Description"}
              defaultValue={data ? data.description : ""}
            />
          </div>
        </div>
        <div className="create-chapter-buttons">
          <div
            onClick={handleSend}
            className="create-chapter-button"
          >
            Save
          </div>
          <div
            onClick={() => {
              if (chapterSaved) {
                callback();
              } else {
                handleModalOpen({
                  title: `Create Chapter`,
                  description: `Save your chapter before you leave`,
                });
              }
            }}
            className="create-chapter-button"
          >
            Close
          </div>
        </div>
      </div>

      <div className="drag-chapters-container">
        <div className={"fields-container"}>{renderFields()}</div>
      </div>
    </div>
  );
};

export default CreateChapter;
