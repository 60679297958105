import axios from "./axiosInstance";

export const editUser = (params, ) => {
  const formData = new FormData();
  for (let key in params)
    formData.append(key, params[key]);
  return axios.post("/Security/EditProfile", formData)
};


export const getMyData = (params) => 
 axios.post("/Security/GetMyPersonalData", params)

// /Security/EditProfile
// fromForm:
// DateTime BirthDate 
// string FirstName
// string LastName
// string Phone 
// string DisplayName
// string Country
// string State 
// string City
// string AddressLine1 
// string AddressLine2 
// string ZipCode
// IFormFile ProfilePicture