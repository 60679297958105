import {
  SAVE_STUDENTS_LIST
} from '../actions/action-types/app-action-types.js';

const initState = {
  studentList: [],
}

const studentReducer = (state = initState, { type, payload }) => {
  switch (type) {
      case SAVE_STUDENTS_LIST: {
          return {
              ...state,
              studentList: payload
          }
      }
      default: {
          return state
      }
  }
}

export default studentReducer;