import {
  CREATE_SCHOOL_ATTEMPT,
  CREATE_SCHOOL_FAILED,
  CREATE_SCHOOL_SUCCESS,
  GET_CREATE_TOKEN,
  SAVE_SCHOOL,
  SAVE_SCHOOL_FAILED,
  SET_TASK,
  SAVE_TASK_LIST,
} from "../actions/action-types/app-action-types";

const initState = {
    task: `<step><testtitle> Test Title </testtitle></step>\n`,
    taskList: [],
};

const SchoolReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case CREATE_SCHOOL_ATTEMPT:
      return {
        ...state,
        error: null,
        schoolCreated: false,
      };
    case CREATE_SCHOOL_FAILED:
      return {
        ...state,
        error: payload,
        schoolCreated: false,
      };
    case CREATE_SCHOOL_SUCCESS:
      return {
        ...state,
        error: null,
        schoolCreated: true,
      };
    case GET_CREATE_TOKEN:
      return {
        ...state,
        createToken: payload,
      };
    case SAVE_SCHOOL:
      return {
        ...state,
        school: payload,
        getSchoolError: false,
      };
    case SAVE_SCHOOL_FAILED:
      return {
        ...state,
        getSchoolError: true,
      };

    case SET_TASK:
      return {
        ...state,
        task: payload,
      };

    case SAVE_TASK_LIST:
      return {
        ...state,
        taskList: payload,
      };
    default:
      return state;
  }
};

export default SchoolReducer;
