import React, { useState, useEffect } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

import {
  createWorkshop,
  editWorkshop,
  getWorkShop,
} from "../../services/workshopServices";

import { getSchoolAllClasses } from "../../services/schoolServices";

import DatePicker from "../../components/Admin/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const WorkshopDetails = ({ workshop }) => {
  const [isLoading, setIsLoading] = useState(false);
  const renderInvitedStudents = () =>
    workshop.invitations.length > 0 ? (
      workshop.invitations.map((el) => (
        <div className="class-item assign-students invited">
          <div>{el.targetEmail || el.userName}</div>
        </div>
      ))
    ) : (
      <div className="class-item assign-students invited">
        No student invited
      </div>
    );

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div>
      <div className="workshop-header-container">
        <div className="assign-students-top-bar">
          <div className="workshop-date-container workshop-details-container">
            <div className="workshop-title">Zoom Link:</div>
            <div className="zoom-link">
              <a
                href={workshop.zoomLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                {workshop.zoomLink}
              </a>
            </div>
          </div>

          <div className="workshop-date-container workshop-details-container date">
            <div className="workshop-date">
              Start: {moment(workshop.startingDate).format("YYYY/MM/DD")}{" "}
            </div>
            <div className="workshop-date date-to">
              End: {moment(workshop.endDate).format("YYYY/MM/DD")}
            </div>
          </div>
        </div>
      </div>

      <div className="all-classes-container assign-students invited">
        <div className="class-item assign-students details-invited">
          <div>Invited Students</div>
        </div>
        {renderInvitedStudents()}
      </div>
    </div>
  );
};

export default WorkshopDetails;
