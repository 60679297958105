import React, { Component } from 'react';
import Evaluation from '../../utils/images/selfEvaluation.png';
import { parseSelfEvaluationStatus } from '../../utils/constants.js';
import './MovementBeLand.css';
import Lock from '../../utils/images/Lock.png';
import { Polar } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelfEvaluation } from "../../redux/actions/selfEvaluationActions.js";
import GreenTick from '../../utils/images/green-tick.png';
import { isMobile } from 'react-device-detect';


class SelfEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                labels: ['SELF MANAGEMENT', 'RESPONSIBLE DECISION MAKING', 'RELATIONSHIP SKILLS', 'SOCIAL AWARENESS', 'SELF AWARENESS'],
                datasets: [{
                    label: '# of Votes',
                    data: [],
                    backgroundColor: [
                        '#F07F2F',
                        '#FFCA38',
                        '#91B93E',
                        '#91B93E',
                        '#F07F2F',
                    ],
                    borderColor: [
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                        '#FFFFFF',
                    ],
                    borderWidth: 0.5
                }]
            },
            options: {
                pointLabelFontSize : 20,
                legend: {
                    display: false,
                    labels: {
                        fontSize: 20
                    }
                },
                scale: {
                    ticks: {
                        max: 5,
                        z: 3
                    },
                    pointLabels: {
                        fontSize: 13
                    }
                },
                tooltips: {
                    caretSize: 0,
                    enabled: true,
                    bodyFontSize: 12,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            var label = data.labels[tooltipItem.index] + ' ' + tooltipItem.value;
        
                            return label;
                        }
                    }
                },
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.setState( prevState => {
                let data = { ...prevState.data };
                data.datasets[0].data = (this.props.evaluation && this.props.evaluation !== 'incomplete') ? 
                    this.props.evaluation[0].evaluations.map( e => { return e.personalValue} )
                :
                    [3, 1, 5, 2, 5]
                return data
            })
        }
    }

    redirectToSelfEvaluation = () => {
        //setear el chapter en redux y si es "incomplete" decir que es la primer task.
        this.props.setSelfEvaluation(this.props.chapter);
        console.log('this.props.chapter: ', this.props.chapter)
        const { history } = this.props;
        //aca hay que mandar a redux el capitulo anterior que fue completado
        console.log('chapter: ', this.props.chapter)
        if(history) history.push('/autoevaluation/' +  JSON.stringify({ id: this.props.chapter.id, chapterId: this.props.chapter.chapterId }) );
    }

    _renderDesktop = () => {
        return(
            <div className="self-evaluation">
                {/* Tengo que refactorizar aca porque la evaluacion medio que no tiene mucho que ver */}
                {/* {(this.props.evaluation !== undefined ) ? ( */}
                {(this.props.chapter && this.props.chapter.completed ) ? (
                    this.props.evaluation ? (
                        // El chapter esta completo, y la evaluacion tambien. Mostramos el grafico
                        <div className="background" style={{backgroundColor: this.props.backgroundColor}}>
                            <div className="chart-container">
                                <img className="green-tick-image" src={GreenTick} alt="Done" />
                                {/* El grafico */}
                                <Polar 
                                    data={this.state.data}
                                    width={200}
                                    height={200}
                                    options={this.state.options}
                                    min={5}
                                />
                            </div>
                        </div>
                    ) : (
                        // El chapter esta completo, y la evaluacion tiene que ser completada
                        <div className="self-evaluation-circle self-evaluation-circle-hover" style={{backgroundColor: this.props.backgroundColor}}>
                            <img onClick={this.redirectToSelfEvaluation} className="self-evaluation-img" src={Evaluation} alt="self-evaluation" />
                        </div>
                    )
                ) : (
                    //Si no esta completo el chapter, hay que mostrarlo bloqueado
                    <div className="self-evaluation-circle" style={{backgroundColor: this.props.backgroundColor}}>
                            <img className="lock-image lock-image-left" src={Lock} alt="Lock" />
                        <img className="self-evaluation-img" src={Evaluation} alt="self-evaluation" />
                    </div>
                )}
            </div>
        )
    }

    _renderMobile = () => {
        return(
            <div className="self-evaluation-mobile">
                {/* Tengo que refactorizar aca porque la evaluacion medio que no tiene mucho que ver */}
                {/* {(this.props.evaluation !== undefined ) ? ( */}
                {(this.props.chapter && this.props.chapter.completed ) ? (
                    this.props.evaluation ? (
                        // El chapter esta completo, y la evaluacion tambien. Mostramos el grafico
                        <div className="background" style={{backgroundColor: this.props.backgroundColor}}>
                            <div className="chart-container">
                                <img className="green-tick-image" src={GreenTick} alt="Done" />
                                {/* El grafico */}
                                <Polar 
                                    data={this.state.data}
                                    width={200}
                                    height={200}
                                    options={this.state.options}
                                    min={5}
                                />
                            </div>
                        </div>
                    ) : (
                        // El chapter esta completo, y la evaluacion tiene que ser completada
                        <div className="self-evaluation-circle-mobile" style={{backgroundColor: this.props.backgroundColor}}>
                            <img onClick={this.redirectToSelfEvaluation} className="self-evaluation-img-mobile" src={Evaluation} alt="self-evaluation" />
                        </div>
                    )
                ) : (
                    //Si no esta completo el chapter, hay que mostrarlo bloqueado
                    <div className="self-evaluation-circle-mobile" style={{backgroundColor: this.props.backgroundColor}}>
                            <img className="lock-image lock-image-left" src={Lock} alt="Lock" />
                        <img className="self-evaluation-img-mobile" src={Evaluation} alt="self-evaluation" />
                    </div>
                )}
            </div>
        )
    }

    render() {
        if(isMobile){
            return this._renderMobile()
        } else 
            return this._renderDesktop()
    }
}

const mapStateToProps = (state) =>{
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelfEvaluation: (evaluation) => {dispatch(setSelfEvaluation(evaluation))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SelfEvaluation));