import React, { Component } from 'react';

import CreateTest from '../../components/CreateTest';

class SuperAdmin extends Component {
    
    render() {
            return (
                <CreateTest />
            )
    }
}

export default SuperAdmin;