import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './AutoEvaluation.css';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CloseImage from '../../utils/images/close.png';
import RedBubble from "../../utils/images/red_bubble.png";
import Vector3 from '../../utils/images/Vector3.png';
import AutoEvaluationModal from '../../components/AutoEvaluationModal';
import ModalWrapper from '../../components/ModalWrapper';
import { connect } from 'react-redux';
import { getSelfEvaluation } from '../../redux/actions/selfEvaluationActions';
import { isMobile } from 'react-device-detect';

import { createChapterEvaluation, createMyAutoEvaluation } from '../../services/schoolServices'

import { getQuestions, getAnswers, getPreQuestions, getChapter, getAllAnswers } from '../../services/chapterServices';

class AutoEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answerValues: [0, 0, 0, 0, 0],
            isPreviewing: false,
            isPreviewingMobile: false,
            competencies: [
                'd90e220b-6c67-4282-e652-08d8631f2d99',
                'c2898edd-17e3-423b-e653-08d8631f2d99',
                'b09c4cfb-d05d-48b3-e654-08d8631f2d99',
                'ff331c09-4da4-4d8a-e655-08d8631f2d99',
                '6f6977bd-dffb-4b25-e656-08d8631f2d99'
            ],
            answers: [],
            chapterName: null, 
            params: null
        }
    }

    setAnswerValue = (index, value) => {
        let answerValues = this.state.answerValues;
        answerValues[index-1] = value;
        this.setState({
            answerValues: answerValues
        })
    }

    shuffle = (array) => {
        array.sort(() => Math.random() - 0.5);
    }

    async componentDidMount() {
        window.scrollTo(0, 0)
        const params = JSON.parse(this.props.match.params.id);
        console.log('chapterId param: ', params.chapterId)
        const { chapterId } = params;
        this.setState({ params });
        try {
            // let responseGetQuestions = await getQuestions({id: '9c416da9-a8a0-4173-a783-08d8647986f6'});
            let responseGetQuestions;
            // const chapterId = this.props.evaluation.evaluation.chapterId;
            if(chapterId !== '0') {
                // responseGetQuestions = await getQuestions({id: this.props.evaluation.evaluation.chapterId });
                responseGetQuestions = await getQuestions({id: chapterId });
                const chapterResponse = await getChapter({ id: chapterId });
                this.setState({ chapterName: chapterResponse.data.body.name });
            }
            else {
                responseGetQuestions = await getPreQuestions();
                this.setState({ chapterName: "Initial Self Assessment"});
            }
            // let responseGetQuestions = await getQuestions({id: this.props.evaluation.evaluation.chapterId });
            console.log('responseGetQuestions', responseGetQuestions)
            if(responseGetQuestions.data.success) {
                let questions = [...responseGetQuestions.data.body];
                console.log('questions: ', questions)
                this.shuffle(questions);
                this.setState({
                    competencies: questions
                })
            }

            let responseGetAnswers = await getAllAnswers({chapterId: chapterId === '0' ? null : chapterId, isBaseAnswer: chapterId === '0' ? true : false});
            console.log('responseGetAnswers: ', responseGetAnswers);
            if(responseGetAnswers.data.success) {
                let answersNotFiltered = responseGetAnswers.data.body;
                let answers;
                if (chapterId !== '0') {
                    answers = answersNotFiltered.filter( e => e.chapterId === chapterId)
                } else {
                    answers = answersNotFiltered;
                }
                console.log('answers: ', answers)
                this.setState({
                    answers: answers
                })
            }
        } catch (error) {
            console.error(error);
        }
    }

    handlePreviewClose = () => {
		this.setState({
			isPreviewing: false,
			isPreviewingMobile: false
        })
        if(this.props.history) this.props.history.push('/land');
    }
    
    createChapterEvaluation = async () => {
        this.setState({isPreviewing: true})
        //the first autoevaluation is the only one that can be incomplete
        if(this.state.params.chapterId === '0') {
            let EditedValues = this.state.competencies.map( (element, index) => {
                if (element && element.competencyId) {
                    return {Value: parseInt(this.state.answerValues[index]), CompetencyId: element.competencyId}
                } else {
                    return {Value: parseInt(this.state.answerValues[index]), CompetencyId: element}
                }
            })
            let data = {
                EditedValues
            }
            let responseCreateMyFirstAutoEvaluation = await createMyAutoEvaluation(data)
            console.log('responseCreateMyFirstAutoEvaluation: ', responseCreateMyFirstAutoEvaluation);
        } else {
                let EditedValues = this.state.competencies.map( (element, index) => {
                    return {Value: parseInt(this.state.answerValues[index]), CompetencyId: element.competencyId}
                })
                let data = {
                    ChapterProgressId: this.state.params.id,
                    EditedValues
                }
                let responseCreateChapterEvaluation = await createChapterEvaluation(data);
                console.log('responseCreateChapterEvaluation', responseCreateChapterEvaluation);
        }
    }

    _renderDesktop() {
        return (
            <div className="bg-grid">
                <Header />
                <div>
                    <div className="autoEvaluation-background">
                        <div className="autoEvaluation-container">
                            <div className="close-modal" onClick={() => {if(this.props.history) this.props.history.push('/land') }}>
                                <img src={CloseImage} alt={'close'}/>
                            </div>
                            <div style={{height: 150}}></div>
                            <div className="autoEvaluation-title">
                                { this.state.chapterName && this.state.chapterName}
                            </div>
                            <div className="under-title-line">
                                <img src={Vector3} alt="Underline" />
                            </div>
                            { this.state.competencies && this.state.competencies.map( (e, index) => {
                                return <CompetencyEvaluation
                                                setAnswerValue={this.setAnswerValue}
                                                competencyNumber={index+1}
                                                question={e.question}
                                                competencyId={e.competencyId}
                                                answers={this.state.answers.filter( answer => e.competencyId === answer.competencyId)}
                                        />
                                })
                            }
                        </div>
                        <div className="autoEvaluation-next-button" onClick={ () => this.createChapterEvaluation()}>
                            <span>Next</span>
                            <img src={RedBubble} alt="next"/>
                        </div>
                    </div>
                </div>
                {this.state.isPreviewing &&
                    <ModalWrapper greyBackground handlePreviewClose={this.handlePreviewClose}>
                        <AutoEvaluationModal chapterId={this.state.params.chapterId} chapterProgressId={this.state.params.id} competencies={this.state.competencies} answerValues={this.state.answerValues} />
                    </ModalWrapper>
                }
                <Footer />
            </div>
        )
    }

    _renderMobile() {
        return (
          <>
            <Header />
            <div>
              <div
                className="close-modal"
                onClick={() => {
                  if (this.props.history) this.props.history.push("/land");
                }}
              >
                <img src={CloseImage} alt={"close"} />
              </div>
              <div style={{ height: 150 }}></div>
              <div className="autoEvaluation-title">
                Title of the Self Assesment
              </div>
              <div className="under-title-line">
                <img src={Vector3} alt="Underline" />
              </div>
              {this.state.competencies &&
                this.state.competencies.map((e, index) => {
                  return (
                    <CompetencyEvaluation
                      setAnswerValue={this.setAnswerValue}
                      competencyNumber={index + 1}
                      question={e.question}
                      competencyId={e.competencyId}
                      answers={this.state.answers.filter(
                        (answer) => e.competencyId === answer.competencyId
                      )}
                    />
                  );
                })}
            </div>
            <div
              className="autoEvaluation-next-button-mobile"
              onClick={() => this.createChapterEvaluation()}
            >
              <span>Next</span>
              <img src={RedBubble} alt="next" />
            </div>
            {this.state.isPreviewing && (
              <ModalWrapper
                greyBackground
                handlePreviewClose={this.handlePreviewClose}
              >
                <AutoEvaluationModal
                  chapterProgressId={this.state.params.id}
                  chapterId={this.state.chapterId}
                  competencies={this.state.competencies}
                  answerValues={this.state.answerValues}
                />
              </ModalWrapper>
            )}
            <Footer />
          </>
        );
    }

    render() {
        if (isMobile) {
            return this._renderMobile();
        } else {
            return this._renderDesktop();
        }
    }
}

class CompetencyEvaluation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCheckbox: -1
        }
        this.setSelectedRadio = this.setSelectedRadio.bind(this);
    }

    setSelectedRadio (event) {
        this.setState({
            selectedCheckbox: event.target.value
        })
        this.props.setAnswerValue(this.props.competencyNumber, event.target.value)
    }

    _renderDesktop() {
        let answers = this.props.answers;
        return (
            <div>
                <div className="radio-title">{this.props.question ? `${this.props.competencyNumber}. ${this.props.question}` : `${this.props.competencyNumber}. ` } </div>
                <div onChange={this.setSelectedRadio} className="radio-container">
                    <div className="radio-item">
                        <input type="radio" value="1" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 1).length !== 0 ? (
                                    answers.filter( e => e.value == 1)[0].text
                                ) :
                                    'Strongly Disagree'}
                        </span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="2" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 2).length !== 0 ? (
                                    answers.filter( e => e.value == 2)[0].text
                                ) :
                                    'Disagree'}
                        </span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="3" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 3).length !== 0 ? (
                                    answers.filter( e => e.value == 3)[0].text
                                ) :
                                    'Neutral'}
                        </span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="4" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 4).length !== 0 ? (
                                    answers.filter( e => e.value == 4)[0].text
                                ) :
                                    'Agree'}
                        </span>
                    </div>
                    <div className="radio-item">
                        <input type="radio" value="5" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 5).length !== 0 ? (
                                    answers.filter( e => e.value == 5)[0].text
                                ) :
                                    'Strongly Agree'}
                        </span>
                    </div>
                </div>
                <div className="radio-line"></div>
            </div>
        )
    }

    _renderMobile() {
        let answers = this.props.answers;
        return (
            <div>
                <div className="radio-title">{this.props.question ? `${this.props.competencyNumber}. ${this.props.question}` : `${this.props.competencyNumber}. ` } </div>
                <div onChange={this.setSelectedRadio} className="radio-container-mobile">
                    <div className="radio-item-mobile">
                        <input type="radio" value="1" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 1).length !== 0 ? (
                                    answers.filter( e => e.value == 1)[0].text
                                ) :
                                    'Strongly Disagree'}
                        </span>
                    </div>
                    <div className="radio-item-mobile">
                        <input type="radio" value="2" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 2).length !== 0 ? (
                                    answers.filter( e => e.value == 2)[0].text
                                ) :
                                    'Disagree'}
                        </span>
                    </div>
                    <div className="radio-item-mobile">
                        <input type="radio" value="3" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 3).length !== 0 ? (
                                    answers.filter( e => e.value == 3)[0].text
                                ) :
                                    'Neutral'}
                        </span>
                    </div>
                    <div className="radio-item-mobile">
                        <input type="radio" value="4" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 4).length !== 0 ? (
                                    answers.filter( e => e.value == 4)[0].text
                                ) :
                                    'Agree'}
                        </span>
                    </div>
                    <div className="radio-item-mobile">
                        <input type="radio" value="5" name={`assesment${this.props.competencyNumber}`} className={`competencyEvaluation-radio`}/>
                        <span> {answers.filter( e => e.value == 5).length !== 0 ? (
                                    answers.filter( e => e.value == 5)[0].text
                                ) :
                                    'Strongly Agree'}
                        </span>
                    </div>
                </div>
                <div className="radio-line-mobile"></div>
            </div>
        )
    }

    render() {
        if (isMobile) {
            return this._renderMobile();
        } else {
            return this._renderDesktop();
        }
    }
}


const mapStateToProps = (state) =>{
    return {
      evaluation: state.selfEvaluationReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AutoEvaluation));