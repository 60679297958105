import axios from "./axiosInstance";
import axiosTemp from "./axiosTempToken";
import queryString from "query-string";

export const createSchoolService = (params, token) => {
  const formData = new FormData();
  for (let key in params) formData.append(key, params[key]);
  return axiosTemp.post("/School/Create", formData, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const getCreateTokenService = (params) =>
  axios.post("/School/GetCreateOrEditToken", { ...params });

export const getSchoolService = (params) =>
  axios.post(`/School/GetSchool?${queryString.stringify(params)}`);

export const createTest = (params) =>
  axios.post("/TestTemplate/Create", params);

export const getAllTest = (params) => {
  let newParams;
  if (params.IsPrimary) {
    newParams = {
      ...params,
    };
  } else {
    newParams = {
      Title: params.Title,
      TimeFrom: params.TimeFrom,
      TimeTo: params.TimeTo,
      SchoolId: params.SchoolId,
      ClassId: params.ClassId,
    };
  }

  return axios.post("/TestTemplate/GetAll", newParams);
};

export const getTest = (params) =>
  axios.post(`/TestTemplate/Get?${queryString.stringify(params)}`);

export const getAllAnsweredTest = (params) =>
  axios.post("/UserTest/GetAll", params);

export const getAnsweredTest = (params) =>
  axios.post(`/UserTest/Get?${queryString.stringify(params)}`);

export const testSendTo = (params) =>
  axios.post("/TestTemplate/SendTo", params);

export const editTest = (params) => axios.post("/TestTemplate/Edit", params);

export const removeTest = (params) =>
  axios.post(`/TestTemplate/Remove?${queryString.stringify(params)}`);

export const schoolAddClass = (params) =>
  axios.post("/School/AddClass", params);

export const schoolRemoveClass = (params) =>
  axios.post("/School/RemoveClass", params);

export const studentSetSchool = (params) =>
  axios.post(`/Student/SetSchool?${queryString.stringify(params)}`);

export const studentAddClass = (params) =>
  axios.post(`/Student/AddClass?${queryString.stringify(params)}`);

export const studentAddClassAdmin = (params) =>
  axios.post(`/Student/AddClassAdmin`, params);

export const studentRemoveClassAdmin = (params) =>
  axios.post(`/Student/RemoveClassAdmin`, params);

export const studentRemoveClass = (params) =>
  axios.post(`/Student/RemoveClass?${queryString.stringify(params)}`);

export const answerChapterCompentencyTest = (params) =>
  axios.post(`/Student/AnswerChapterCompetencyTest`, params);

export const studentEditClass = (params) =>
  axios.post("/School/EditClass", params);

export const getSchoolAllClasses = (params) =>
  axios.post(`/School/GetClassSelector?${queryString.stringify(params)}`);

export const answerTest = (params) =>
  axios.post("/Student/ResolvePrimaryTest", params);

export const getAllSchoolService = (params) =>
  axios.post("/School/GetSchoolsSelector", params);

export const approveSchool = (params) =>
  axios.post("school/ApproveSchool", params);

export const removeSchool = (params) =>
  axios.post(`school/Delete?${queryString.stringify(params)}`);

export const editSchool = (params, token) => {
  const formData = new FormData();
  for (let key in params)
    formData.append(key === "id" ? "SchoolId" : key, params[key]);
  return axiosTemp.post("/School/Edit", formData, {
    headers: { Authorization: "Bearer " + token },
  });
};

export const createStudent = (params) => {
  const formData = new FormData();
  for (let key in params) formData.append(key, params[key]);
  return axios.post("/Student/Create", formData);
};

export const createCounselor = (params) => {
  const formData = new FormData();
  for (let key in params) formData.append(key, params[key]);
  return axios.post("/Counselor/Create", formData);
};

export const createSchoolAdmin = (params) =>
  axios.post("/SchoolAdmin/Create", params);

export const createClass = (params) => axios.post("/School/AddClass", params);

export const studentGetAll = (params) => axios.post("/Student/GetAll", params);

export const approveStudent = (params) =>
  axios.post("/Student/ApproveStudent", params);

export const deleteStudent = (params) =>
  axios.post(`/student/Delete?${queryString.stringify(params)}`);

export const getStudent = (params) =>
  axios.post(`/student/get?${queryString.stringify(params)}`);

export const getStudentProgress = (params) =>
  axios.post(
    `/Student/GetStudentChapterProgress?${queryString.stringify(params)}`
  );

export const getStudentClasses = (params) =>
  axios.post(`/Student/GetStudentClasses?${queryString.stringify(params)}`);

export const editStudent = (params, token) => {
  const formData = new FormData();
  for (let key in params)
    formData.append(key === "id" ? "StudentId" : key, params[key]);
  return axios.post("/student/Edit", formData);
};

export const getMyChaptersProgress = (params) =>
  axios.post("/Student/GetMyChaptersProgress", params);

export const startANewChapter = (params) =>
  axios.post("/Student/StartANewChapter", params);

export const getMyFirstAutoEvaluation = (params) =>
  axios.post("/Student/GetMyFirstAutoEvaluation");

export const getMyAutoEvaluations = (params) =>
  axios.post("/Student/GetMyAutoEvaluations");

export const getChapterCompetencyTest = (params) =>
  axios.post(
    `/Student/GetChapterCompetencyTest?chapterProgressId=${params.chapterProgressId}`
  );

export const counselorGetAll = (params) =>
  axios.post("/counselor/GetAll", params);

export const approveCounselor = (params) =>
  axios.post("counselor/ApproveCounselor", params);

export const removeCounselor = (params) =>
  axios.post(`counselor/Delete?${queryString.stringify(params)}`);

export const editCounselor = (params, token) => {
  const formData = new FormData();
  
  params.schoolsIds && params.schoolsIds.forEach((item, index) => {
    formData.append(`schoolsIds[${index}]`, item);
  });

  for (let key in params) {
    if(key !== "schoolsIds") {
      formData.append(key === "id" ? "CounselorId" : key, params[key]);
    }
  }

  return axios.post("/counselor/Edit", formData);
};

export const counselorRemoveSchool = (params) => {
  console.log(params, "params addschool");
  return axios.post("counselor/RemoveSchoolAdmin", params);
};

export const counselorAddSchool = (params) => {
  console.log(params, "params addschool");
  return axios.post("counselor/AddSchoolAdmin", params);
};

export const studentGetMyTest = (params) =>
  axios.post("/Student/GetMyTests", params);

export const getCounselor = (params) =>
  axios.post(`counselor/Get?${queryString.stringify(params)}`);

export const schoolAdminGetAll = (params) =>
  axios.post("/SchoolAdmin/GetAll", params);

export const approveSchoolAdmin = (params) =>
  axios.post("/SchoolAdmin/ChangeSchoolAdminApprovedStatus", params);

export const removeSchoolAdmin = (params) =>
  axios.post(`SchoolAdmin/Delete?${queryString.stringify(params)}`);

export const getSchoolAdmin = (params) =>
  axios.post(`/SchoolAdmin/Get?${queryString.stringify(params)}`);

export const editSchoolAdmin = (params) => {
  return axios.post("/SchoolAdmin/Edit", params);
};

export const assingSchoolAdmin = (params) => {
  return axios.post("/School/AssignAdmin", params);
};

export const removeAssignedSchoolAdmin = (params) => {
  return axios.post("/School/removeAdmin", params);
};

export const getMySchools = (params) =>
  axios.post("school/GetMySchool", params);

//   /SchooldAdmin/Edit
// Body:
// string Id
// string FirstName
// string LastName
// string Phone
// string DisplayName
// DateTime BirthDate
// string Country
// string State
// string City
// string AddressLine1
// string AddressLine2
// string ZipCode

export const saveChaptersOrder = (params) =>
  axios.post(`/SchoolAdmin/GetAll`, params);

export const createChapterEvaluation = (params) =>
  axios.post(`/Student/CreateChapterEvaluation`, params);

export const createMyAutoEvaluation = (params) =>
  axios.post(`/Student/CreateMyAutoEvaluation`, params);

export const getStudentAutoEvaluations = (params) =>
  axios.post(`/Student/GetAutoEvaluations?${queryString.stringify(params)}`);

export const getFirstAutoEvaluation = (params) =>
  axios.post(
    `/Student/GetFirstAutoEvaluation?${queryString.stringify(params)}`
  );

export const getAnalitycsInformation = (params) => 
    axios.post(`/school/GetSchoolAnylitics?${queryString.stringify(params)}`);

