
//Types should be in const to avoid typos and duplication since it's a string and could be easily miss spelled
export const APP_ACTION = 'APP_ACTION';
export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SET_LOG_OUT = "SET_LOG_OUT";
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const SET_TASK = 'SET_TASK';

export const SAVE_TASK_LIST = 'SAVE_TASK_LIST';

export const CREATE_SCHOOL_ATTEMPT = 'CREATE_SCHOOL_ATTEMPT';
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS';
export const CREATE_SCHOOL_FAILED = 'CREATE_SCHOOL_FAILED';
export const GET_CREATE_TOKEN = 'GET_CREATE_TOKEN';
export const SAVE_SCHOOL_FAILED = 'SAVE_SCHOOL_FAILED';
export const SAVE_SCHOOL = 'SAVE_SCHOOL';
export const CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_ATTEMPT = 'CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_ATTEMPT';
export const CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_SUCCESS = 'CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_SUCCESS';
export const CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_FAILED = 'CHANGE_SCHOOL_ADMIN_APPROVED_STATUS_FAILED';

export const SET_SELF_EVALUATION = 'SET_SELF_EVALUATION';
export const GET_SELF_EVALUATION = 'GET_SELF_EVALUATION';

export const SAVE_STUDENTS_LIST = 'SAVE_STUDENTS_LIST';