import {
    SET_SELF_EVALUATION
} from '../actions/action-types/app-action-types.js';

const initState = {
    evaluation: undefined,
}

const selfEvaluationReducer = (state = initState, { type, payload }) => {
    switch (type) {
        case SET_SELF_EVALUATION: {
            return {
                ...state,
                evaluation: payload
            }
        }
        default: {
            return state
        }
    }
}

export default selfEvaluationReducer;