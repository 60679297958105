import axios from "./axiosInstance";
import axiosTemp from "./axiosTempToken";

export const checkInvitationToken = (token) =>
  axiosTemp.post(
    "/Security/CheckJwtEmail",
    {},
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

export const acceptInvitation = ( token) =>
  axiosTemp.post("/workshop/AcceptInvitation", {} , {
    headers: { Authorization: "Bearer " + token },
  });

export const workShopRegister = (params, token) =>
  axiosTemp.post("/Security/WorkShopRegister", params, {
    headers: { Authorization: "Bearer " + token },
  });

// Call /Security/CheckJwtEmail
// Use received token as Baerer Token
// Call it will answer if user is registered or not
// If user is registered use /workshop/AcceptInvitation
// again use received token as Baerer Token
// If user is not registered use /Security/WorkShopRegister
// Body:
// [Required]
// string Password
// [Required]
// string ConfirmPassword
// [Required]
// string UserName
