import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Register from "../../views/Register";

class RegisterRoute extends React.Component {

    render() {
        return this.props.isLogged ? (
                <Redirect to={{ pathname: '/' }} />
                ) : (
                <Register />
            )
    }
}

const mapStateToProps = (state) =>{
    return {
      isLogged: state.loginReducer.isLogged
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RegisterRoute)