import React, { useState, useEffect } from "react";

import { studentGetAll } from "../../services/schoolServices";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addUserToWorkshop } from "../../services/workshopServices";
import InviteStudentModal from "./InviteStudentModal";
import moment from "moment";

const AssignStudents = ({ item, handleModalOpen }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [assignedStudents, setAssignedStudents] = useState([]);
  const [students, setStudents] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [invitedStudents, setInvitedStudents] = useState(item.invitations);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);
  const PageSize = 50;
  
  useEffect(() => {
    studentGetAll({ PageSize, PageNum: selectedPage }).then((res) => {
      const response = res.data.body.slice();
      res.data.body.forEach((el) => {
        invitedStudents.forEach((item) => {
          if (el.userName === item.targetEmail) {
            const index = response.indexOf(el);
            if (index > -1) {
              response.splice(index, 1);
            }
          }
        });
      });
      console.log(res.data, "res.data get all");
      setHasNextPage(res.data.hasNextPage);
      setSelectedPage(1);
      setIsLoading(false);
      setStudents(response);
    });
  }, []);

  const handleInviteStudents = () => {
    setIsLoading(true);
    const createInvitationsToSend = assignedStudents.map((el) => ({
      WorkShopId: item.id,
      Email: el.email,
      Role: 1,
    }));
    addUserToWorkshop({
      id: item.id,
      invitationsToSend: createInvitationsToSend,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.data.success) {
          setAssignedStudents([]);
          setInvitedStudents((state) => [...state, ...assignedStudents]);
          handleModalOpen({
            title: "Invite Students",
            description: `Invitations sent successfully!`,
          });
        } else {
          handleModalOpen({
            title: "Error",
            description: `Try again later`,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleModalOpen({
          title: "Error",
          description: `Try again later`,
        });
      });
  };

  const renderStudents = () => {
    let filteredStudents = students.filter(
      (item) =>
        item.email?.toLowerCase().includes(searchField.toLowerCase()) ||
        item.userName?.toLowerCase().includes(searchField.toLowerCase()) ||
        item.firstName?.toLowerCase().includes(searchField.toLowerCase()) ||
        item.lastName?.toLowerCase().includes(searchField.toLowerCase())
    );

    const addStudent = (data) => {
      const newStudents = students.slice();
      const newAssignedStudents = assignedStudents.slice();

      const index = newStudents.indexOf(data);

      newStudents.splice(index, 1);
      newAssignedStudents.push(data);

      setStudents(newStudents);
      setAssignedStudents(newAssignedStudents);
    };

    return filteredStudents.map((item) => (
      <div className="class-item assign-students">
        <div>
          <div>{item.userName}</div>
          <div>{item.email}</div>
        </div>

        <div className="add-button" onClick={() => addStudent(item)}>
          Invite Student
        </div>
      </div>
    ));
  };

  const debounce = (func, wait, immediate = false) => {
    let timeout;
    return function (...args) {
      const later = () => {
        timeout = null; // added this to set same behaviour as ES5
        if (!immediate) func.apply(this, args); // this is called conditionally, just like in the ES5 version
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(this, args);
    };
  };

  const renderInvitedStudents = () =>
    invitedStudents.map((el) => (
      <div className="class-item assign-students invited">
        <div>{el.targetEmail || el.userName}</div>
        <div className="add-button invited">Invited</div>
      </div>
    ));

  const renderAssignedStudents = () => {
    const deleteStudent = (data, index, id) => {
      const newStudents = students.slice();
      const newAssignedStudents = assignedStudents.slice();

      newAssignedStudents.splice(index, 1);
      newStudents.push(data);
      setStudents(newStudents);
      setAssignedStudents(newAssignedStudents);
    };

    return assignedStudents.map((item, index) => (
      <div className="class-item">
        <div>{item.userName}</div>
        <div
          className="add-button"
          onClick={() => deleteStudent(item, index, item.id)}
        >
          Remove Student
        </div>
      </div>
    ));
  };

  const search = debounce((toSearch) => {
    setSearchWord(toSearch);
    setListLoading(true);
    studentGetAll({ ImportantWord: toSearch, PageSize }).then((res) => {
      const response = res.data.body.slice();
      res.data.body.forEach((el) => {
        invitedStudents.forEach((item) => {
          if (el.userName === item.targetEmail) {
            const index = response.indexOf(el);
            if (index > -1) {
              response.splice(index, 1);
            }
          }
        });
      });
      setHasNextPage(res.data.hasNextPage);
      setSelectedPage(1);
      setListLoading(false);
      setStudents(response);
    });

    setSearchField(toSearch);
  }, 1000);

  const updateAssignStudents = (emailToInvite) => {
    setInvitedStudents((state) => [
      ...state,
      ...assignedStudents,
      { targetEmail: emailToInvite },
    ]);
    setAssignedStudents([]);
  };

  const handleInviteByMail = () => {
    handleModalOpen({
      title: "Invite Student",
      descriptionComponent: (
        <InviteStudentModal
          id={item.id}
          handleModalOpen={handleModalOpen}
          updateInfo={updateAssignStudents}
        />
      ),
      witouthButtons: true,
    });
  };

  const handleChangePage = (type) => {
    setListLoading(true);

    studentGetAll({
      ImportantWord: searchWord,
      PageSize,
      PageNum: type === "Prev" ? selectedPage - 1 : selectedPage + 1,
    }).then((res) => {
      const response = res.data.body.slice();
      res.data.body.forEach((el) => {
        invitedStudents.forEach((item) => {
          if (el.userName === item.targetEmail) {
            const index = response.indexOf(el);
            if (index > -1) {
              response.splice(index, 1);
            }
          }
        });
      });
      setHasNextPage(res.data.hasNextPage);
      setListLoading(false);
      setStudents(response);
      setSelectedPage(type === "Prev" ? selectedPage - 1 : selectedPage + 1);
    });
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div>
      <div className="workshop-header-container">
        <div className="assign-students-top-bar">
          <div className="workshop-date-container">
            <div className="workshop-title">Zoom Link:</div>
            <div className="zoom-link">
              <a href={item.zoomLink} rel="noopener noreferrer" target="_blank">
                {item.zoomLink}
              </a>
            </div>
          </div>

          <div className="workshop-date-container">
            <div className="workshop-date">
              Start: {moment(item.startingDate).format("YYYY/MM/DD")}{" "}
            </div>
            <div className="workshop-date date-to">
              End: {moment(item.endDate).format("YYYY/MM/DD")}
            </div>
            {selectedPage}
          </div>
        </div>

        <div className="assign-students-top-bar">
          <input
            className="text-input"
            name={`search`}
            onChange={(e) => {
              search(e.target.value);
            }}
            placeholder={"Search"}
          />
          <div className="assign-button" onClick={handleInviteByMail}>
            Add Students by mail
          </div>
          <div className="assign-button" onClick={handleInviteStudents}>
            Send Invitations
          </div>
        </div>
      </div>

      {listLoading ? (
        <div className="loading-container">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className="classes-container">
          <div className="assign-students-container">
            <div className="assign-students-buttons-container">
              {selectedPage > 1 && (
                <div
                  onClick={() => handleChangePage("Prev")}
                  className="assign-students-buttons"
                >
                  Prev
                </div>
              )}

              {hasNextPage && (
                <div
                  onClick={() => handleChangePage("Next")}
                  className="assign-students-buttons next-button"
                >
                  Next
                </div>
              )}
            </div>
            <div className="all-classes-container assign-students">
              <div className="class-item assign-students">
                <div>All Students</div>
              </div>
              {renderStudents()}
            </div>
          </div>

          <div className="all-classes-container assign-students invited">
            <div className="class-item assign-students">
              <div>Students to invite</div>
            </div>
            {renderInvitedStudents()}
            {renderAssignedStudents()}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignStudents;
