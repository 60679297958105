import React, { useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { getAllChapters } from "../../services/chapterServices";
import { useSelector } from "react-redux";

import {
  approveStudent,
  deleteStudent,
  editStudent,
  getStudent,
  getStudentProgress,
  getSchoolAllClasses,
  getStudentClasses,
  studentRemoveClassAdmin,
  studentAddClassAdmin,
  getAllSchoolService,
  getCounselor,
  getSchoolAdmin,
  getMySchools,
} from "../../services/schoolServices";

import { forceStartChapter } from "../../services/chapterServices";
import Select from "react-select";

import blankImage from "../../utils/images/blank_user.png";
import DatePicker from "../../components/Admin/DatePicker";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import StudentProgress from "./StudentProgress";

let token = localStorage.getItem("token");

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const StudentList = ({
  dataList,
  updateInfo,
  handleModalOpen,
  setInformation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userInformation, setUserInformation] = useState(null);
  const [classes, setClasses] = useState([]);
  const [studentClasses, setStudentClasses] = useState([]);
  const [studentSchool, setStudentSchool] = useState([]);
  const [schools, setSchools] = useState([]);
  const [data, setData] = useState(dataList);
  const [searchField, setSearchField] = useState("");

  const { isLogged, user } = useSelector((state) => state.loginReducer);

  const [renderType, setRenderType] = useState("userInformation");

  const handleDataInformation = (data) => {
    const orderedData = {
      profilePictureUrl: data.profilePictureUrl,
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.displayName,
      userName: data.userName,
      email: data.email,
      phone: data.phone,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      country: data.country,
      state: data.state,
      city: data.city,
      zipCode: data.zipCode,
      birthDate: data.birthDate,
    };

    const newData = Object.keys(orderedData).map((key) => {
      return (
        key !== "studentId" &&
        key !== "approvalStatus" &&
        key !== "profilePictureUrl" && (
          <div
            key={key}
            className={`input-container student-container w-50 ${
              key === "birthDate" || key === "profilePictureUrl" ? "w-100" : ""
            }`}
          >
            <div className="edit-input-label">{key}</div>
            {typeof data[key] === "boolean" ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={data[key]}
                      onChange={() => handleChange(key, !data[key], data)}
                    />
                  }
                />
              </FormGroup>
            ) : // ) : key === "profilePictureUrl" ? (
            //   <label className="main-image-container">
            //     <input
            //       name={key}
            //       id={key}
            //       accept="image/*"
            //       type="file"
            //       onChange={(e) => {
            //         handleChange(key, e.target.files[0], data);
            //       }}
            //     />
            //     <img
            //       id="main-image"
            //       className={`${data[key] ? "" : "blank-image"}`}
            //       src={data[key] ? data[key] : blankImage}
            //       alt="main"
            //     />
            //   </label>
            key === "birthDate" ? (
              <DatePicker
                initialDate={new Date(data[key])}
                setDate={(date) => handleChange(key, date, data)}
              />
            ) : (
              <input
                className="text-input"
                name={key}
                onChange={(e) => handleChange(key, e.target.value, data)}
                placeholder={key}
                defaultValue={data[key]}
              />
            )}
          </div>
        )
      );
    });
    return (
      <div className="edit-container edit-container-student">
        <div className="profile-image-url-container">
          <div className="user-image-container">
            <label className="main-image-container">
              <input
                name={"profilePictureUrl"}
                id={"profilePictureUrl"}
                accept="image/*"
                type="file"
                onChange={(e) => {
                  handleChange("profilePictureUrl", e.target.files[0], data);
                }}
              />
              <img
                id="main-image"
                className={`${
                  userInformation["profilePictureUrl"] ? "" : "blank-image"
                }`}
                src={
                  data["profilePictureUrl"]
                    ? data["profilePictureUrl"]
                    : blankImage
                }
                alt="main"
              />
            </label>
          </div>
        </div>
        <div className="data-edit-container">{newData}</div>
      </div>
    );
  };

  const handleChange = (item, value, data) => {
    const newEdit = Object.assign(data);

    if (item === "profilePictureUrl") {
      if (value) {
        const file = value;
        if (file && file.size > 15 * 1024 * 1024) return false;
        let reader = new FileReader();
        // it's onload event and you forgot (parameters)
        reader.onload = function (e) {
          let image = document.getElementById("main-image");
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
        newEdit["ProfilePicture"] = value;
      }
    } else {
      newEdit[item] = value;
    }
  };

  const displayEditModal = (item) => {
    setInformation({
      title: "Student Edit",
      descriptionComponent: handleDataInformation(item),
      acceptAction: (e) => {
        e.target.disabled = true;
        const parsedItem = Object.assign({}, item);
        delete parsedItem["profilePictureUrl"];

        editStudent({
          ...parsedItem,
          birthDate: moment.utc(parsedItem.birthDate).format(),
          email: parsedItem.userName,
          schoolId: parsedItem.schoolId
            ? parsedItem.schoolId
            : "78cb7966-d9e3-46cb-06a7-08d850e9d4e2",
        })
          .then((res) => {
            if (res.data.success) {
              handleModalOpen({
                title: `Student Edit`,
                description: `The Student ${item.userName} was edited`,
              });
            } else {
              handleModalOpen({
                title: "There was an error while editing",
                description: `Try again later`,
              });
            }
            updateInfo();
          })
          .catch((err) => {
            handleModalOpen({
              title: "There was an error while editing",
              description: `Try again later`,
            });
          });
      },
    });
  };

  const renderUserInformation = (type) => {
    console.log(
      userInformation,
      type,
      "userInformation desde el render user information"
    );
    const orderedData = {
      profilePictureUrl: userInformation.profilePictureUrl,
      firstName: userInformation.firstName,
      lastName: userInformation.lastName,
      displayName: userInformation.displayName,
      userName: userInformation.userName,
      email: userInformation.email,
      phone: userInformation.phone,
      addressLine1: userInformation.addressLine1,
      addressLine2: userInformation.addressLine2,
      country: userInformation.country,
      state: userInformation.state,
      city: userInformation.city,
      zipCode: userInformation.zipCode,
      birthDate: userInformation.birthDate,
    };
    const information = Object.keys(orderedData);
    console.log(information, "information student edit");
    const typeToRender = {
      userInformation: (
        <div className="user-information-container">
          {information.map((key) => {
            console.log(key, key === "profilePictureUrl", "key information");
            return key === "profilePictureUrl" ? (
              <div className="profile-image-url-container">
                <div className="user-image-container">
                  <img
                    id="main-image"
                    className={`${userInformation[key] ? "" : "blank-image"}`}
                    src={
                      userInformation[key] ? userInformation[key] : blankImage
                    }
                    alt="main"
                  />
                </div>
              </div>
            ) : (
              <div
                key={key}
                className={`input-container student-container w-50`}
              >
                <div className="edit-input-label">{key}</div>
                {key === "approvalStatus" ? (
                  <div>{userInformation[key] < 3 ? "Denied" : "Approved"}</div>
                ) : (
                  <div>
                    {userInformation[key] !== "null"
                      ? userInformation[key]
                      : ""}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ),
      classes: (
        <div className="classes-container">
          <div className="all-classes-container">
            <div className="class-item">
              <div>All Classes</div>
            </div>
            {renderClasses()}
          </div>
          <div className="all-classes-container">
            <div className="class-item">
              <div>Student Classes</div>
            </div>
            {renderStudentClasses()}
          </div>
        </div>
      ),
      schools: (
        <div className="classes-container">
          <div className="all-classes-container">
            <div className="class-item">
              <div>All Schools</div>
            </div>
            {renderSchools()}
          </div>
          <div className="all-classes-container">
            <div className="class-item">
              <div>Asigned School</div>
            </div>
            {renderStudentSchool()}
          </div>
        </div>
      ),
    };
    return typeToRender[type];
  };

  const renderClasses = () => {
    const addClass = (data, index, id) => {
      studentAddClassAdmin({ studentId: userInformation.id, classId: id })
        .then((res) => {
          const newClasses = classes.slice();
          const newStudentClasses = studentClasses.slice();
          newClasses.splice(index, 1);
          newStudentClasses.push(data);
          setClasses(newClasses);
          setStudentClasses(newStudentClasses);
          handleModalOpen({
            title: `Student Add Class`,
            description: "Class removed successfully",
          });
        })
        .catch((err) => {
          handleModalOpen({
            title: `Student Add Class`,
            description: "Something went wrong, try again later",
          });
        });
    };

    return classes.map(
      (item, index) =>
        item && (
          <div className="class-item">
            <div>{item.title}</div>
            <div
              className="add-button"
              onClick={() => addClass(item, index, item.id)}
            >
              Add
            </div>
          </div>
        )
    );
  };

  const renderSchools = () => {
    const addSchool = (data, index, id) => {
      const parsedItem = Object.assign({}, userInformation);
      delete parsedItem["profilePictureUrl"];
      editStudent({
        ...parsedItem,
        birthDate: moment.utc(parsedItem.birthDate).format(),
        email: parsedItem.userName,
        schoolId: id,
      })
        .then((res) => {
          const newSchools = schools.slice();
          newSchools.splice(index, 1);
          setSchools([...newSchools, ...studentSchool]);
          setStudentSchool([data]);
          handleModalOpen({
            title: `Student Add School`,
            description: "School added successfully",
          });
        })
        .catch((err) => {
          handleModalOpen({
            title: `Student Add School`,
            description: "Something went wrong, try again later",
          });
        });
    };

    return schools.map(
      (item, index) =>
        item &&
        item.approvalStatus > 2 && (
          <div className="class-item">
            <div>{item.name}</div>
            <div
              className="add-button"
              onClick={() => addSchool(item, index, item.id)}
            >
              AssignSchool
            </div>
          </div>
        )
    );
  };

  const renderStudentSchool = () => {
    return studentSchool.map(
      (item, index) =>
        item &&
        item.approvalStatus > 2 && (
          <div key={"student-" + index} className="class-item">
            <div>{item.name}</div>
          </div>
        )
    );
  };

  const renderStudentClasses = () => {
    const removeFromStudent = (data, index, id) => {
      studentRemoveClassAdmin({ classId: id, studentId: userInformation.id })
        .then((res) => {
          const newClasses = classes.slice();
          const newStudentClasses = studentClasses.slice();
          newStudentClasses.splice(index, 1);
          newClasses.push(data);
          setClasses(newClasses);
          setStudentClasses(newStudentClasses);
          handleModalOpen({
            title: `Student Remove Class`,
            description: "Class removed successfully",
          });
        })
        .catch((err) => {
          handleModalOpen({
            title: `Student Remove Class`,
            description: "Something went wrong, try again later",
          });
        });
    };

    return studentClasses.map(
      (item, index) =>
        item && (
          <div key={"student-" + index} className="class-item">
            <div>{item.title}</div>
            <div
              className="add-button"
              onClick={() => removeFromStudent(item, index, item.id)}
            >
              Remove
            </div>
          </div>
        )
    );
  };

  const renderChapters = (chapters, chaptersToFilter) => {
    let newChapters = [];
    const orderedChapters = chapters.slice();
    const newAllchapters = chaptersToFilter.slice();

    chaptersToFilter.forEach((el, index) => {
      for (let index = 0; index < chapters.length; index++) {
        const element = chapters[index];
        if (element.chapterId === el.id) {
          const i = newAllchapters.indexOf(el);
          const order = newAllchapters[i].order;
          orderedChapters[index].order = order;
          if (i > -1) {
            newAllchapters.splice(i, 1);
          }
        }
      }
    });

    newChapters = [...orderedChapters, ...newAllchapters];
    newChapters.sort((a, b) => a.order - b.order);

    return (
      <div className={`student-chapter-containers`}>
        {newChapters.map((item) => (
          <div
            className={`student-chapter-row ${
              item.state === 1
                ? "chapter-inprogress"
                : item.state === 2
                ? "chapter-completed"
                : "chapter-bloqued"
            }`}
          >
            <div className="student-chapter-text-container">
              <div className="student-chapter-label">Chapter Name :</div>
              <div className="student-chapter-text">
                {item.name ? item.name : item.chapterName}
              </div>
            </div>
            <div className="student-chapter-text-container">
              <div className="student-chapter-label">
                Completed Competencies :
              </div>
              <div className="student-chapter-text">
                {item.completedCompetencies ? item.completedCompetencies : 0}
              </div>
            </div>
            {!item.state ? (
              <div
                onClick={() =>
                  forceStartChapter({
                    chapterId: item.id,
                    studentId: userInformation.id,
                  })
                    .then((res) => {
                      setIsLoading(true);
                      if (res.data.success) {
                        handleModalOpen({
                          title: `Unlock Chapter`,
                          description: "Chapter unblocked successfully",
                        });
                      } else {
                        handleModalOpen({
                          title: `Unlock Chapter`,
                          description: "There was an error, try again later",
                        });
                      }

                      getStudentProgress({
                        studentId: userInformation.id,
                      }).then((res) => {
                        getAllChapters({})
                          .then((response) => {
                            setIsLoading(false);

                            setInformation({
                              title: "Unlock Chapter",
                              descriptionComponent: renderChapters(
                                res.data.body,
                                response.data.body
                              ),
                              witouthButtons: true,
                            });
                          })
                          .catch((err) => console.log(err));
                      });
                    })

                    .catch(() =>
                      handleModalOpen({
                        title: `Unlock Chapter`,
                        description: "There was an error, try again later",
                      })
                    )
                }
                className="student-chapter-text-container unblock-chapter-button"
              >
                Unlock Chapter
              </div>
            ) : (
              <div className="student-chapter-text-container chapter-void"></div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const search = (toSearch) => {
    let filteredStudents = dataList.filter(
      (item) =>
        item.userName?.toLowerCase().includes(toSearch.toLowerCase()) ||
        item.firstName?.toLowerCase().includes(toSearch.toLowerCase()) ||
        item.email?.toLowerCase().includes(toSearch.toLowerCase())
    );
    setData(filteredStudents);
    setSearchField(toSearch);
  };

  const sortBy = (type) => {
    const dataToSort = data.slice();
    dataToSort.sort((a, b) => {
      if (b[type] == null) {
        return -1;
      }
      return a[type]?.toLowerCase() > b[type]?.toLowerCase()
        ? 1
        : b[type]?.toLowerCase() > a[type]?.toLowerCase()
        ? -1
        : 0;
    });

    setData(dataToSort);
  };

  const updateProgress = (chapters, chaptersToFilter) => {
    setInformation({
      title: "Student Progress",
      descriptionComponent: (
        <StudentProgress
          updateProgress={updateProgress}
          userInformation={userInformation}
          handleModalOpen={handleModalOpen}
          chapters={chapters}
          chaptersToFilter={chaptersToFilter}
          setInformation={setInformation}
        />
      ),
      witouthButtons: true,
    });
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : !userInformation ? (
    <>
      <input
        className="text-input search-field"
        name={`search`}
        onChange={(e) => {
          search(e.target.value);
        }}
        placeholder={"Search"}
      />
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={"dashboard-list-container"}
      >
        {data.length === 0 && <div>No information available</div>}
        {data.length > 0 && (
          <ListItem className={`dashboard-list-item`}>
            <ListItemText
              className="list-item-text list-item-button"
              onClick={() => sortBy("userName")}
              primary={"Username"}
            />
            <ListItemText
              className="list-item-text list-item-button"
              onClick={() => sortBy("firstName")}
              primary={"First Name"}
            />
            <ListItemText
              className="list-item-text list-item-button"
              onClick={() => sortBy("email")}
              primary={"Email"}
            />

            <ListItemText
              className="list-item-icon color-transparent"
              primary={"Approved"}
            />
            <ListItemText
              className="list-item-icon color-transparent"
              primary={"Delete"}
              button
            />
            <ListItemText
              className="list-item-icon color-transparent"
              primary={"Edit"}
              button
            />
          </ListItem>
        )}
        {data.map((item) => (
          <ListItem
            onClick={() => {
              setIsLoading(true);
              getStudent({ studentId: item.studentId })
                .then((res) => {
                  setIsLoading(false);
                  setUserInformation(res.data.body);
                  setStudentSchool([res.data.body.schoolId]);
                })
                .catch(() => {
                  handleModalOpen({
                    title: "Error",
                    description: `Try again later`,
                  });
                  setIsLoading(false);
                });
            }}
            className={`dashboard-list-item list-item-button`}
          >
            <ListItemText className="list-item-text" primary={item.userName} />
            <ListItemText className="list-item-text" primary={item.firstName} />
            <ListItemText className="list-item-text" primary={item.email} />
            <ListItemText className="list-item-text" primary={""} />
          </ListItem>
        ))}
      </List>
    </>
  ) : (
    <div className="information-container">
      <div className="action-buttons">
        {user.firstRole === "SUPER_ADMIN" && (
          <>
            <div
              className={`user-action-buttons ${
                userInformation.approvalStatus === 2 ? "disabled" : ""
              }`}
              onClick={() => {
                handleModalOpen({
                  title: "Are you sure?",
                  description: `You want to ${
                    userInformation.approvalStatus < 3
                      ? "Approve"
                      : "Disapprove"
                  } ${userInformation.userName}?`,
                  acceptAction: (e) => {
                    e.target.disabled = true;
                    approveStudent({
                      studentId: userInformation.id,
                      Approved: userInformation.approvalStatus < 3,
                    })
                      .then((res) => {
                        updateInfo();
                        if (res.data.success) {
                          handleModalOpen({
                            title: `Student ${
                              userInformation.approvalStatus < 3
                                ? "Approved"
                                : "Disapproved"
                            }`,
                            description: `The Student ${
                              userInformation.userName
                            } was ${
                              userInformation.approvalStatus < 3
                                ? "approved"
                                : "disapproved"
                            }`,
                          });
                        } else {
                          handleModalOpen({
                            title: "There was an error while approving",
                            description: `Try again later`,
                          });
                        }
                      })
                      .catch((err) => {
                        handleModalOpen({
                          title: "There was an error while approving",
                          description: `Try again later`,
                        });
                      });
                  },
                });
              }}
            >
              {userInformation && userInformation.approvalStatus < 3
                ? "Approve"
                : "Disapprove"}
            </div>
            <div
              className="user-action-buttons"
              onClick={() => {
                setIsLoading(false);
                displayEditModal(userInformation);
              }}
            >
              Edit
            </div>
            <div
              className="user-action-buttons"
              onClick={() =>
                handleModalOpen({
                  title: "Are you sure?",
                  description: `You want to delete ${userInformation.displayName} student?`,
                  acceptAction: (e) => {
                    e.target.disabled = true;
                    deleteStudent({ studentId: userInformation.id })
                      .then((res) => {
                        if (res.data.success) {
                          handleModalOpen({
                            title: "Student Deleted",
                            description: `The student ${userInformation.displayName} was deleted`,
                          });
                        } else {
                          handleModalOpen({
                            title: "There was an error while deleting",
                            description: `Try again later`,
                          });
                        }
                        updateInfo();
                      })
                      .catch((err) => {
                        handleModalOpen({
                          title: "There was an error while deleting",
                          description: `Try again later`,
                        });
                      });
                  },
                })
              }
            >
              Delete
            </div>
            <div
              className="user-action-buttons"
              onClick={() => {
                setRenderType("userInformation");
                setIsLoading(true);
                if (userInformation.approvalStatus > 2) {
                  getStudentProgress({ studentId: userInformation.id }).then(
                    (res) => {
                      console.log(
                        res.data.body,
                        " res.data.body student progress"
                      );
                      getAllChapters({})
                        .then((response) => {
                          setIsLoading(false);
                          setInformation({
                            title: "Student Progress",
                            descriptionComponent: (
                              <StudentProgress
                                updateProgress={updateProgress}
                                userInformation={userInformation}
                                handleModalOpen={handleModalOpen}
                                chapters={res.data.body}
                                chaptersToFilter={response.data.body}
                                setInformation={setInformation}
                              />
                            ),
                            witouthButtons: true,
                          });
                        })
                        .catch((err) => console.log(err));
                    }
                  );
                } else {
                  setInformation({
                    title: "Student Progress",
                    descriptionComponent: (
                      <div>This student is not approved yet</div>
                    ),
                    witouthButtons: true,
                  });
                }
              }}
            >
              Progress
            </div>
          </>
        )}
        <div
          className="user-action-buttons"
          onClick={() => {
            setIsLoading(true);
            setRenderType("classes");

            if (userInformation.approvalStatus > 2) {
              getStudentClasses({ studentId: userInformation.id })
                .then((res) => {
                  setStudentClasses(res.data.body);
                  getSchoolAllClasses({
                    schoolId: userInformation.schoolId,
                  })
                    .then((response) => {
                      setIsLoading(false);
                      const studentClasses = res.data.body;
                      const allClasses = response.data.body.slice();
                      response.data.body.forEach((el) => {
                        studentClasses.forEach((item) => {
                          if (el.id === item.id) {
                            const index = allClasses.indexOf(el);
                            if (index > -1) {
                              allClasses.splice(index, 1);
                            }
                          }
                        });
                      });
                      setClasses(allClasses);
                    })
                    .catch((err) => {
                      setIsLoading(false);
                    });
                })
                .catch((err) => setIsLoading(false));
            } else {
              setInformation({
                title: "Student Progress",
                descriptionComponent: (
                  <div>This student is not approved yet</div>
                ),
                witouthButtons: true,
              });
            }
          }}
        >
          Add Classes
        </div>

        <div
          className="user-action-buttons"
          onClick={() => {
            setIsLoading(true);
            setRenderType("schools");

            if (userInformation.approvalStatus > 2) {
              if (user.firstRole === "SUPER_ADMIN") {
                getAllSchoolService()
                  .then((res) => {
                    const response = res.data.body.slice();
                    const studentSchools = [];
                    console.log(userInformation, "userInformation");
                    res.data.body.forEach((el) => {
                      if (el.id === userInformation.schoolId) {
                        const index = response.indexOf(el);
                        if (index > -1) {
                          studentSchools.push(el);
                          response.splice(index, 1);
                        }
                      }
                    });

                    setIsLoading(false);
                    setSchools(response);
                    console.log(studentSchools, "student schools");
                    setStudentSchool(studentSchools);
                  })
                  .catch((err) => setIsLoading(false));
              } else {
                getMySchools()
                  .then((res) => {
                    const response = res.data.body.slice();
                    const studentSchools = [];

                    res.data.body.forEach((el) => {
                      if (el.id === userInformation.schoolId) {
                        const index = response.indexOf(el);
                        if (index > -1) {
                          studentSchools.push(el);
                          response.splice(index, 1);
                        }
                      }
                    });

                    setIsLoading(false);
                    setSchools(response);
                    console.log(studentSchools, "student schools");

                    setStudentSchool(studentSchools);
                  })
                  .catch((err) => setIsLoading(false));
              }
              //           STUDENT:1,
              // SCHOOL_COUNSELOR: 2,
              // SCHOOL_ADMINISTRATOR: 3,
              // SUPER_ADMIN: 4,
            } else {
              setInformation({
                title: "Assign School",
                descriptionComponent: (
                  <div>This student is not approved yet</div>
                ),
                witouthButtons: true,
              });
            }
          }}
        >
          Assign School
        </div>
      </div>
      {renderUserInformation(renderType)}
    </div>
  );
};

export default StudentList;
