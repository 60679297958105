import React, { useEffect, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import Task from "../Task";
import {
  getAllSchoolService,
  studentGetAll,
  getStudent,
  getStudentAutoEvaluations,
  getFirstAutoEvaluation,
} from "../../services/schoolServices";
import { getAllChapters } from "../../services/chapterServices";

import { getStudentChapterProgress } from "../../services/chapterServices";
import { reviewTest } from "../../services/counselorService";
import { getCompetency } from "../../services/chapterServices";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { setTask as setTaskAction } from "../../redux/actions/schoolActions";
import { useDispatch, useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import blankImage from "../../utils/images/blank_user.png";
import useWindowDimensions from "../../utils/resizeHelper";

const customStyles = {
  control: (styles) => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    height: "100%",
    paddingLeft: 20,
  }),
};

const CreateReport = ({ type, handleModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schoolsOptions, setSchoolOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [task, setTask] = useState([]);
  const [selectedTaskInfo, setSelectedTaskInfo] = useState(false);

  const taskSelected = useSelector(
    (state) => state.schoolReducer?.task?.template
  );
  const [alertStudent, setAlertStudent] = useState(false);
  const [counselorComment, setCounselorComment] = useState("");
  const [competencies, setCompetencies] = useState([]);
  const [graphyc, setGraphyc] = useState(null);

  const dispatch = useDispatch();
  const [allChapters, setAllChapters] = useState([]);

  const { width } = useWindowDimensions();

  const renderGraphyc = () => {
    const dataSets = graphyc.dataSets.map((el, index) => ({
      label: el.name,
      fill: false,
      lineTension: 0.1,
      backgroundColor: competencyColors[index],
      borderColor: competencyColors[index],
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: competencyColors[index],
      pointBackgroundColor: "#fff",
      pointBorderWidth: 0,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: competencyColors[index],
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: el.points,
    }));

    const data = (canvas) => {
      var ctx = canvas.getContext("2d");
      ctx.font = "50px Helvetica Neue";

      console.log(ctx, "ctx data canvas antes del return");
      return {
        labels: graphyc.labels,
        datasets: dataSets,
      };
    };

    return (
      <Line
        options={{
          devicePixelRatio: 3,
          legend: {
           
            labels: {
              fontSize: width > 3000 ? 35 : 12,
            },
          },
          // scale: {
            
          // },
          scales: {
            scaleLabel: {
              fontSize: 50,
            },
            yAxes: [
              {
                scaleLabel: {
                  fontSize: 50,
                },
                ticks: { max: 5, min: 0 },
              },
            ],
          },
        }}
        data={data}
      />
    );
  };

  const competencyColors = {
    0: "#F0E42F",
    1: "#FFCA38",
    2: "#ADFF02",
    3: "#91B93E",
    4: "#F07F2F",
  };

  useEffect(() => {
    getAllSchoolService()
      .then((res) => {
        const options = [];
        const response = res.data.body;
        response.forEach((element) => {
          if (element.approvalStatus === 3) {
            options.push({ value: element.id, label: element.name });
          }
        });
        setSchoolOptions(options);
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));

    getCompetency({})
      .then((res) => {
        const response = res.data.body;
        const competenciesResponse = {};
        response.forEach((el) => (competenciesResponse[el.id] = el.name));
        setCompetencies(competenciesResponse);
      })
      .catch((err) => {});

    getAllChapters({})
      .then((res) => {
        setAllChapters(res.data.body);
      })
      .catch((err) => {});
  }, []);

  const handleSendReport = () => {
    reviewTest({
      answerTestId: selectedTaskInfo.id,
      ReviewedTemplate: selectedTaskInfo.template,
      notes: counselorComment,
    })
      .then((res) => {
        if (res.data.success) {
          handleModalOpen({
            title: "Report",
            description: `Report sent successfully!`,
          });
        } else {
          handleModalOpen({
            title: "Report",
            description: `Something went wrong, try again later!`,
          });
        }
      })
      .catch((err) => {
        handleModalOpen({
          title: "Report",
          description: `Something went wrong, try again later!`,
        });
      });
  };

  const renderTasks = () => {
    return task.map((item, index) => {
      return (
        <div key={`${item?.title} ${index}`} className="report-task-item">
          {item.chapterName}
          <div className="option-menu">
            {item.completedCompetencies === 0 && (
              <div className="option-menu-item">
                The student has not yet completed any task
              </div>
            )}
            {item.competenciesProgress?.map((el, index) => {
              return (
                el.userTest && (
                  <div
                    className="option-menu-item"
                    key={`${el?.testTemplateName} ${index}`}
                    onClick={() => {
                      dispatch(
                        setTaskAction({
                          template: el.userTest.answeredTest,
                          templateId: null,
                          isComplete: true,
                        })
                      );

                      setSelectedTaskInfo({
                        id: el.userTest.answerId,
                        template: el.userTest.answeredTest,
                      });
                    }}
                    dangerouslySetInnerHTML={{
                      __html: el.userTest.testTemplateName,
                    }}
                  />
                )
              );
            })}
          </div>
        </div>
      );
    });
  };

  const handleCounselorComment = (e) => {
    const value = e.target.value;
    setCounselorComment(value);
  };

  const onStudentSelected = async (id) => {
    try {
      setIsLoading(true);

      const { data: studentProgress } = await getStudentChapterProgress({
        studentId: id,
      });
      const { data: studentAutoEvaluations } = await getStudentAutoEvaluations({
        id: id,
      });
      const { data: studentFirstEvaluation } = await getFirstAutoEvaluation({
        id: id,
      });

      const { data: student } = await getStudent({ studentId: id });
      const { data: answeredTest } = await getStudentChapterProgress({
        studentId: id,
      });

      const graphyc = {
        labels: ["First Evaluation"],
        dataSets: [
          {
            name: "",
            points: [],
          },
        ],
      };

      const points = {};

      studentFirstEvaluation.body.evaluations &&
        studentFirstEvaluation.body.evaluations.forEach((el) => {
          points[competencies[el.competencyId]] = [el.personalValue];
        });
      studentAutoEvaluations.body.evaluations &&
        studentAutoEvaluations.body.evaluations.forEach((el) => {
          el.evaluations &&
            el.evaluations.forEach((evaluation) => {
              points[competencies[evaluation.competencyId]] = [
                ...points[competencies[evaluation.competencyId]],
                evaluation.personalValue,
              ];
            });
        });

      allChapters.forEach((el) => {
        graphyc.labels = [...graphyc.labels, el.name];
      });

      graphyc.dataSets = Object.keys(points).map((key) => ({
        name: key,
        points: points[key],
      }));
      setTask(answeredTest.body);
      setSelectedStudent(student.body);
      setGraphyc(graphyc);
      setIsLoading(false);
    } catch (err) {
      console.log(err, "error try catch select student");
    }
  };

  return (
    <div>
      <div className="register-input-container list-select">
        <Select
          styles={customStyles}
          className="register-select"
          placeholder="Search Schools"
          options={schoolsOptions}
          onChange={(item) => {
            setIsLoading(true);
            studentGetAll({ schoolId: item.value })
              .then((res) => {
                const options = [];
                const response = res.data.body;
                console.log(response, "sresponse get aall students");
                response.forEach((element) => {
                  if (element.approvalStatus === 3) {
                    options.push({
                      value: element.studentId,
                      label: `${element.firstName} ${element.lastName}`,
                    });
                  }
                });

                setStudentOptions(options);
                setIsLoading(false);

                console.log(res, "get school all clases");
              })
              .catch((err) => {
                setIsLoading(false);
              });
          }}
        />
      </div>
      {studentOptions.length > 0 && (
        <div className="register-input-container list-select student-report-select">
          <Select
            styles={customStyles}
            className="register-select"
            placeholder="Search Students"
            options={studentOptions}
            blurInputOnSelect={true}
            isSearchable
            onChange={(item) => {
              setIsLoading(true);
              onStudentSelected(item.value);
            }}
          />
        </div>
      )}

      {isLoading ? (
        <div className="loading-container">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        selectedStudent && (
          <div className="chapter-report-student">
            <div className="profile-info-container">
              <img
                id="profile-image"
                className={`student-image`}
                src={
                  selectedStudent.profilePictureUrl
                    ? selectedStudent.profilePictureUrl
                    : blankImage
                }
                alt="profile"
              />
              <div className="profile-info-content">
                <div className="profile-info-item">
                  Name: {selectedStudent.firstName}
                </div>
                <div className="profile-info-item">
                  Last Name: {selectedStudent.lastName}
                </div>
                <div className="profile-info-item">
                  Phone: {selectedStudent.phone}
                </div>
                <div className="profile-info-item">
                  Email: {selectedStudent.email ? selectedStudent.email : ""}
                </div>
              </div>
            </div>
            <div className="competencies-grapic">
              {graphyc && renderGraphyc()}
            </div>
            <div className="chapter-report-student-content">
              <div className="list-chapters-container">{renderTasks()}</div>
              <div className="report-task-container">
                {taskSelected && <Task isReport={true} isPreviewing={true} />}
              </div>
            </div>
            {taskSelected && (
              <div className="report-footer">
                <div className="report-alert-container">
                  <div className="report-alert-button-container">
                    <div
                      onClick={() => setAlertStudent((state) => !state)}
                      className={`report-alert-button ${
                        alertStudent ? "selected" : ""
                      }`}
                    />
                    <div>Alert</div>
                  </div>
                </div>

                <div className="counselor-comment-container">
                  <textarea
                    onChange={handleCounselorComment}
                    className="counselor-comment-input"
                    placeholder="Professional counselor comments"
                  />
                  <div
                    className="send-review-button"
                    onClick={handleSendReport}
                  >
                    Send Review
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default CreateReport;
