import React, { Component } from "react";
import { connect } from "react-redux";
import { loginAttempt, setError } from "../../redux/actions/auth";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMobile } from 'react-device-detect'
import MobileLogo from '../../utils/images/BE-Texture-White2-1.png';

import "./Login.css";

// const Schema = Yup.object().shape({
//   Email: Yup.string().email("Email invalid!").required("You must add a email!"),
//   Password: Yup.string().required("You must add a password!"),
// });

const Schema = Yup.object().shape({
  Email: Yup.string().email("Email invalid!").required("You must add your Email!"),
  Password: Yup.string().required("You must add your Password!"),
});

const initialValues = {
  Email: "",
  Password: "",
};

class Login extends Component {
  handleLogin = (values) => {
    this.props.loginAttempt(values);
  };

  handleInputs = (
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit
  ) => {
    const valuesArray = Object.keys(initialValues);
    return valuesArray.map((item) => (
      <div key={item} className={isMobile ? "login-input-container-mobile" : "login-input-container"}>
        <input
          className="login-text-input"
          name={`${item}`}
          onChange={(e) => {
            this.props.setError(null);
            setFieldValue(`${item}`, e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSubmit();
            }
          }}
          onBlur={handleBlur}
          value={values[item]}
          type={item === "Password" ? "password" : "text"}
          placeholder={item === "Email" ? "Email" : "Password"}
        />
        <div className="error-span">
          {this.props.login.error && this.props.login.error.type === item
            ? this.props.login.error.message
            : errors[item] && touched[item] && errors[item]}
        </div>
      </div>
    ));
  };

  renderDesktop = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={this.handleLogin}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="login-form">
            <div className="login-content-container">
              <div className="login-left-container"></div>
              <div className="login-rigth-container">
                {this.props.login.isLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <>
                    <div className="login-title-container">
                      <div className="login-title">Welcome back!</div>
                      <p className="login-paragraph">
                        Type your Email and Password to log in
                      </p>
                    </div>
                    {this.handleInputs(
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleBlur,
                      handleSubmit
                    )}
                    {this.props.login.error &&
                      this.props.login.error.type !== "Email" &&
                      this.props.login.error.type !== "Password" && (
                        <div className="error-container">
                          <div className="error-span">
                            {this.props.login.error.message}
                          </div>
                        </div>
                      )}
                    <div className="login-forgot-password">
                      Forgot password?
                    </div>
                    <button
                      className="login-submit-button"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Log in
                    </button>

                    <p className="login-first-time">
                      First time?
                      <Link className="sign-up" to="/register">
                        Sign up now
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </div>
            <Link className="home-button" to="/"></Link>
          </form>
        )}
      </Formik>
    )
  }

  renderMobile = () => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={this.handleLogin}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form className="login-form">
            <div className="login-content-container">
              <div className="login-mobile-container">
                {this.props.login.isLoading ? (
                  <CircularProgress className="loading-mobile" color="secondary" />
                  ) : (
                    <>
                    <img className="mobile-logo" src={MobileLogo} alt="Logo" />
                    <div className="login-title-container-mobile">
                      <div className="login-title">Welcome back!</div>
                      <p className="login-paragraph">
                        Type your Email and Password to log in
                      </p>
                    </div>
                    {this.handleInputs(
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      handleBlur,
                      handleSubmit
                    )}
                    {this.props.login.error &&
                      this.props.login.error.type !== "Email" &&
                      this.props.login.error.type !== "Password" && (
                        <div className="error-container login-content-center">
                          <div className="error-span">
                            {this.props.login.error.message}
                          </div>
                        </div>
                      )}
                    <div className="login-forgot-password login-forgot-password-mobile">
                      Forgot password?
                    </div>
                    <button
                      className="login-submit-button"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Log in
                    </button>

                    <p className="login-first-time">
                      First time?
                      <Link className="sign-up" to="/register">
                        Sign up now
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </div>
            <Link className="home-button" to="/"></Link>
          </form>
        )}
      </Formik>
    )
  }

  render() {
      if (isMobile) {
        return this.renderMobile()
      } else {
        return this.renderDesktop()
      }
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
    login: state.loginReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginAttempt: (user) => {
      dispatch(loginAttempt(user));
    },

    setError: (error) => {
      dispatch(setError(error));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
