import React, { Component } from 'react';
import { connect } from 'react-redux'
import { getSchool } from '../../redux/actions/schoolActions';
import {loginAttempt, registerAttempt} from "../../redux/actions/auth";
import logo from "../../utils/images/footerlogo.png";
import hand from "../../utils/images/Hand.png";
import nate from "../../utils/images/nate.png";
import socialJustice from "../../utils/images/socialjustice.png";
import students from "../../utils/images/forstudents.png";
import students2 from "../../utils/images/forstudents2.png";
import check from "../../utils/images/check.png";
import onedrive from "../../utils/images/onedrive.png";
import respondus from "../../utils/images/respondus.png";
import nearpoad from "../../utils/images/nearpoad.png";
import khan from "../../utils/images/khan.png";
import googledrive from "../../utils/images/googledrive.png";
import education from "../../utils/images/education.png";
import brainpop from "../../utils/images/brainpop.png";
import discovery from "../../utils/images/discovery.png";
import classphoto from "../../utils/images/classphoto.jpg";
import toDoList from "../../utils/images/todolist.png";
import toDoList2 from "../../utils/images/todolist2.png";
import facebook from "../../utils/images/facebook.png";
import instagram from "../../utils/images/instagram.png";
import sanDiegoUnifiedSchoolDistrict from '../../utils/images/sanDiegoUnifiedSchoolDistrict.png';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import siatech from "../../utils/images/siatech.png";
import seal from "../../utils/images/seal.png";
import office from "../../utils/images/office.jpeg";
import national from "../../utils/images/national.jpg";
import kipp from "../../utils/images/kipp.png";
import funders from "../../utils/images/funders.png";
import education2 from "../../utils/images/education2.png";




import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'



import Header from "../../components/Header";
import Footer from "../../components/Footer";


import {
    getTest,
  } from "../../services/schoolServices";

import {setTask} from "../../redux/actions/schoolActions";

class Home extends Component {
    state = {
        schoolId: "",
        task: "",
    }
    
    handleClick = () => {
        this.props.getSchool(this.state.schoolId);
    }
    handleChange = (e) => {
        const value = e.target.value;
        this.setState({schoolId: value})
    }
   
    render() {
        console.log(this.props.school);
            return (
                <>
                    <Header variant={"light"} home={true}/>
                    {/* <Navbar bg="transparent" expand="lg" className="px-4" fixed="top">
                        <Navbar.Brand href="/">
                            <Image src={logo} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-nav" />
                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="ml-auto">
                                <Nav.Link href="/login" className="mr-4 cl-white">Log in</Nav.Link>
                                <Button variant="outline-light" className="btn-outline-sm font-weigth-bold mr-2" href="/register">Sign up</Button>
                            </Nav>
                            {/* className="ml-auto" ADD THIS CLASSNAME to the dropdown below once the user is logged ↓ */}
                               {/*<Dropdown as={ButtonGroup} alignRight >
                                <Dropdown.Toggle id="user-dropdown">
                                    <Image src={nate} className="user-img" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="super-colors">
                                <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                                <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                                <Dropdown.Item eventKey="3">Active Item</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Navbar.Collapse>
                    </Navbar> */}
                    <section className="background-section">
                        <div className="h-100 w-100 bg-nate-container">
                            <div className="position-relative h-100 w-100">
                                <div className="bg-grid"></div>
                                <div className="bg-gang"></div>
                            </div>
                        </div>
                        <Container fluid>
                            <Row>
                                <Col xs={12} md={8} lg={{ span: 3, offset: 2 }}>
                                    <h1 className="title-text font-crim-italic mb-3">Tell your story before they do</h1>
                                    <p className="subtitle-text mb-5">
                                        Movement BE is a web-based, mobile friendly platform for students, educators,
                                        and professional counselors to connect and interact via the poetry and prose of students using their own,
                                        authentic voice.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="movement-be">
                        <Container fluid className="h-100">
                            <Row className="h-100 hand-container">
                                <Col xs={12} md={12} lg={{ span: 4, offset: 1 }}>
                                    <Image src={hand} className="hand-position"/>
                                </Col>
                                <Col xs={12} md={12} lg={{ span: 4, offset: 1 }} className="d-flex justify-content-center flex-column">
                                    <h2 className="title-text mb-3 font-crim-italic">Movement BE</h2>
                                    <p className="subtitle-text mb-4">
                                        Today’s students experience anxiety as a result of social media,
                                        unstable environments, and increased expectations placed on them on who they should BE.
                                        The social emotional impacts of the pandemic will have a significant and lasting impact on all
                                        of our schools and we know that professional counselors are often limited.
                                    </p>
                                    <p className="subtitle-text">
                                        The platform supplies students with a social-emotional based curriculum through various writing exercises.
                                        Students score their self reported emotional state before and after each exercise,
                                        and upon completion- receive a report card on their well being.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="sel background-section">
                        <Container fluid className="bg-trace social-banner">
                            <div className="text-center h-100">  
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                    <Row className="justify-content-center">
                                        <Col xs={12} md={8} lg={{span:8}}>
                                            <h3 className="title-text font-crim-italic mb-4">Social Emotional Learning</h3>
                                            <p className="subtitle-text font-weight-bold mb-4">
                                                Social and emotional learning (SEL) enhances students’ capacity to integrate skills,
                                                attitudes, and behaviors to deal effectively and ethically with daily tasks and challenges.
                                            </p>
                                            <p className="subtitle-text">
                                                Transformative SEL is anchored in the notion of justice-oriented citizenship, with issues of culture, identity,
                                                agency, belonging, and engagement explored as relevant expressions of the five core SEL competencies.
                                            </p>     
                                        </Col>
                                    </Row>
                                </div>                               
                            </div>
                            <Image src={socialJustice} className="justice-position" />
                        </Container>
                    </section>
                    <section className="transformative-sel">
                        <h4 className="first-title text-center mb-5">
                            Our work is at the center of Transformative SEL as creative writing becomes a form of narrative therapy. Here’s how:
                        </h4>
                        <Container fluid className="px-lg-5">
                            <Row>
                                <Col xs={12} md={12} lg={4}>
                                    <Card className="sel-card">
                                        <span className="title text-center">For Students</span>
                                        <Card.Img variant="top" src={students2} />
                                        <Card.Body className="px-0">
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        I respond to questions with my own, authentic voice
                                                    </Col>
                                                </Row>                                                
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        A safe palace where I can "BE."
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Feedback and guidance from professional counselors
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Healing through writing
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Improved social-emotional abilities
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Improved literacy
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Reports that show I am improving
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={12} lg={4}>
                                    <Card className="sel-card">
                                        <span className="title text-center">For School Administrators</span>
                                        <Card.Img variant="top" src={classphoto} />
                                        <Card.Body className="px-0">                                           
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Students will improve their social emotional abilities
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Students will increase their literacy skills
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Students will have access to professional counseling
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Easy integration into the class day for all staff 
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        A turn key curriculum that is still flexible and customizable to my student and school’s need
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Valuable reporting showing student improvement
                                                    </Col>
                                                </Row>
                                            </Card.Text>                    
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={12} lg={4}>
                                    <Card className="sel-card">
                                        <span className="title text-center">For Professional Counselors</span>
                                        <Card.Img variant="top" src={toDoList2} />
                                        <Card.Body className="px-0">
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Access to student writings easily from multiple devices
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        A safe place to encourage students to BE 
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Secure communication with students and school administrators
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Workflow tracking systems so I can administer my work 
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Flagging system to highlight critical issues 
                                                    </Col>
                                                </Row>
                                            </Card.Text>
                                            <Card.Text className="tip-text">
                                                <Row>
                                                    <Col xs={1} md={1} lg={1}>
                                                        <Image src={check} className="mr-2 icon-image"/>
                                                    </Col>
                                                    <Col xs={11} md={11} lg={11}>
                                                        Reports on Student progress that I can edit, review, and customize
                                                    </Col>
                                                </Row>
                                            </Card.Text>  
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <p class="last-title">*Because of the limits of professional counselors at schools— Movement BE has partnered with local nonprofits to support schools and students if need BE.</p>
                        </Container>
                    </section>
                    {/* <section className="book">
                        <div className="bg-book">
                            <Container className="h-100 ">
                                <div className="text-center h-100 d-flex justify-content-center flex-column">
                                    <h5 className="first-title cl-white mb-4">
                                        The activities are based on Nate Howard’s book: <b>“Tell Your Story Before They Do: A Practical Guide to Just BE.”</b> Students engage in each chapter as they write their own. Each lesson is gamified with cool rewards giving students incentive to tell their true story. There are 10 chapters and 50 lessons.
                                    </h5>
                                    <div>
                                        <Button variant="primary" className="btn-title font-crim-italic">Learn More</Button>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </section> */}
                    <section className="nate-section">
                        <Container fluid className="h-100">
                            <Row className="h-100 justify-content-sm-center">
                                <Col xs={12} md={8} lg={{ span: 4, offset: 1 }} className="d-flex justify-content-center flex-column">
                                    <Image src={nate}/>
                                </Col>
                                <Col xs={12} md={8} lg={{ span: 4, offset: 1 }} className="d-flex justify-content-center flex-column">
                                    <h3 className="title-text font-crim-italic">Distance Learning Pricing</h3>
                                    <h3 className="title-text font-crim-italic cl-dark-blue mb-4">11% Increase & 11:1 Return on Investment</h3>
                                    <p className="subtitle-text mb-4 cl-dark-blue">
                                        Those who participated in evidence based SEL programs have shown a 11% gain in academic achievement &
                                    </p>
                                    <p className="subtitle-text cl-dark-blue mb-4">
                                        The average return on investment for six evidence-based programs is 11 to 1, meaning for every dollar invested there is an $11 return. Read the 2015 review from Columbia University.
                                    </p>
                                    <div className="anchor-container">
                                        <Button variant="primary" className="btn-title font-crim-italic" href="https://calendly.com/movementbe/15min?month=2020-09">Schedule Free Consultation</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="yellow-section">
                        <div className="h-100 d-flex justify-content-center align-items-center flex-column">
                            <div class="text-center mb-4">
                                <h3><b>Our purpose</b> is to help change the narrative of underserved communities.</h3>
                            </div>
                            <div class="text-center mb-4">
                                <h3><b>Our vision</b> is that all youth have the confidence to determine their own destiny.</h3>
                            </div>
                            <div class="text-center mb-4">
                                <h3><b>Our mission</b> is to help youth develop a growth mindset through creative storytelling.</h3>
                            </div>
                        </div>
                    </section>
                    <section className="partners-section">
                        <h4 className="font-crim-italic text-center mb-5">Partners</h4>
                        <Container>
                            <Row className="mb-5 text-center align-items-center">
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={onedrive}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={googledrive}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={khan}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={brainpop}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={education}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={discovery}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={respondus}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={nearpoad}/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={sanDiegoUnifiedSchoolDistrict} className="width-130 height-130"/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={siatech} className="width-130 height-130"/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={seal} className="width-130 height-130"/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={office} className="width-130 height-130"/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={national} className="width-130 height-130"/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={kipp} className="width-130 height-130"/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={funders} className="width-130"/>
                                </Col>
                                <Col xs={3} md={4} lg={3} className="padding-20 partner-icon-container">
                                    <Image src={education2} className="width-130 height-130"/>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <Footer />
                    {/* <footer>
                        <Container fluid className="px-4">
                            <Row>
                                <Col xs={12} md={12} lg={3} >
                                    <Image src={logo} className="mb-4"/>
                                </Col>
                                <Col xs={6} md={6} lg={2}>
                                    <ul className="unstyled-list">
                                        <li>
                                            <a href="#!">The Team</a>
                                        </li>
                                        <li>
                                            <a href="#!">Store</a>
                                        </li>
                                        <li>
                                            <a href="#!">Blog</a>
                                        </li>
                                        <li>
                                            <a href="#!">Donate</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xs={6} md={6} lg={3}>
                                    <ul className="unstyled-list">
                                        <li>
                                            <a href="#!">Contact</a>
                                        </li>
                                        <li>
                                            <a href="#!">Careers</a>
                                        </li>
                                        <li>
                                            <a href="#!">Resources</a>
                                        </li>
                                        <li>
                                            <a href="#!">Staff Login</a>
                                        </li>
                                    </ul>
                                </Col>
                                <Col xs={6} md={6} lg={3}>
                                    <p className="mb-0 owner-text"><b>Nate Howard</b></p>
                                    <p className="owner-text">Founder & Executive Director</p>
                                </Col>
                                <Col className="align-items-end d-flex" lg={1}>
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <a href="#!">
                                                <Image src={facebook}/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="#!">
                                                <Image src={instagram}/>
                                            </a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <p className="footer-text text-center mt-5">©2020 MOVEMENT BRAVE ENTREPRENEUR. ALL RIGHTS RESERVED. MOVEMENT BRAVE ENTREPRENEUR IS A NONPROFIT, TAX-EXEMPT CHARITABLE ORGANIZATION (TAX IDENTIFICATION NUMBER 81-3315935) UNDER SECTION 501(C)(3) OF THE INTERNAL REVENUE CODE. DONATIONS ARE TAX-DEDUCTIBLE AS ALLOWED BY LAW.</p>
                        </Container>
                    </footer> */}
                        {/* <div className="input-container">
                            <input
                                className="text-input"
                                onChange={this.handleChange}
                                placeholder="School id"
                                value={this.state.schoolId}
                            />
                        </div>   
                        <button onClick={() => { this.handleClick() }}> Get School </button> */}
       
                </>
            )
    }
}

const mapStateToProps = (state) =>{
    return {
      appState: state.appState,
      login: state.loginReducer,
      school: state.schoolReducer,
    }
  }
const mapDispatchToProps= (dispatch)=>{
    return{
        getSchool: (params) => {dispatch(getSchool(params))},
        loginAttempt: (user) => {dispatch(loginAttempt(user))},
        registerAttempt: (user) => {dispatch(registerAttempt(user))},
        setTask: (task) =>  {dispatch(setTask(task))},
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Home)