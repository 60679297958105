import CircularProgress from "@material-ui/core/CircularProgress";
import React from 'react'
import './Spinner.css'

const Spinner = (props) => {
    return (
        <div className="loading-container">
				  <CircularProgress color="secondary" />
        </div>
    )
}

export default Spinner;