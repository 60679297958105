import React, { useState } from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import blankImage from "../../utils/images/blank_user.png";

import {
  removeSchoolAdmin,
  approveSchoolAdmin,
  getSchoolAdmin,
  editSchoolAdmin,
  assingSchoolAdmin,
  getAllSchoolService,
  removeAssignedSchoolAdmin,
} from "../../services/schoolServices";
import DatePicker from "../../components/Admin/DatePicker";

const SchoolAdminList = ({
  dataList,
  updateInfo,
  handleModalOpen,
  setInformation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [counselorSelected, setSchoolAdminSelected] = useState(null);
  const [schoolAdminInformation, setSchoolAdminInformation] = useState(null);
  const [schoolAdminSchool, setSchoolAdminSchool] = useState([]);
  const [schools, setSchools] = useState([]);
  const [renderType, setRenderType] = useState("schoolAdminInformation");

  const handleDataInformation = (data) => {
    const orderedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: data.displayName,
      phone: data.phone,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      country: data.country,
      state: data.state,
      city: data.city,
      zipCode: data.zipCode,
      birthDate: data.birthDate,
    };

    const newData = Object.keys(orderedData).map((key) => {
      return (
        key !== "id" &&
        key !== "schoolId" &&
        key !== "approvalStatus" && (
          <div
            key={key}
            className={`input-container w-50 ${
              key === "birthDate" || key === "profileImageUrl" ? "w-100" : ""
            }`}
          >
            <div className="edit-input-label">{key}</div>
            {typeof data[key] === "boolean" ? (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={data[key]}
                      onChange={() => handleChange(key, !data[key], data)}
                    />
                  }
                />
              </FormGroup>
            ) : key === "profileImageUrl" ? (
              <label className="main-image-container">
                <input
                  name={key}
                  id={key}
                  accept="image/*"
                  type="file"
                  onChange={(e) => {
                    handleChange(key, e.target.files[0], data);
                  }}
                />
                <img id="main-image" src={data[key]} alt="main" />
              </label>
            ) : key === "birthDate" ? (
              <DatePicker
                initialDate={new Date(data[key])}
                setDate={(date) => handleChange(key, date, data)}
              />
            ) : (
              <input
                className="text-input"
                name={key}
                onChange={(e) => handleChange(key, e.target.value, data)}
                placeholder={key}
                defaultValue={data[key]}
              />
            )}
          </div>
        )
      );
    });
    return <div className="edit-container">{newData}</div>;
  };

  const handleChange = (item, value, data) => {
    const newEdit = Object.assign(data);
    if (item === "profileImageUrl") {
      if (value) {
        const file = value;
        if (file && file.size > 15 * 1024 * 1024) return false;
        let reader = new FileReader();
        // it's onload event and you forgot (parameters)
        reader.onload = function (e) {
          let image = document.getElementById("main-image");
          image.src = e.target.result;
        };
        reader.readAsDataURL(file);
        newEdit["ProfilePicture"] = value;
      }
    } else {
      newEdit[item] = value;
    }
  };

  const displayEditModal = (item) => {
    setInformation({
      title: "SchoolAdmin Edit",
      descriptionComponent: handleDataInformation(item),
      acceptAction: (e) => {
        e.target.disabled = true;
        const parsedItem = Object.assign({}, item);
        delete parsedItem["profileImageUrl"];
        editSchoolAdmin(parsedItem)
          .then((res) => {
            updateInfo();
            if (res.data.success) {
              handleModalOpen({
                title: `School Admin Edit`,
                description: `The School Admin ${item.userName} was edited`,
              });
            } else {
              handleModalOpen({
                title: "There was an error while editing",
                description: `Try again later`,
              });
            }
          })
          .catch((err) => {
            handleModalOpen({
              title: "There was an error while editing",
              description: `Try again later`,
            });
          });
      },
    });
  };

  console.log(schoolAdminInformation, "school admin information al time");

  const renderUserInformation = (type) => {
    const orderedData = schoolAdminInformation && {
      profilePictureUrl: schoolAdminInformation.profilePictureUrl,
      firstName: schoolAdminInformation.firstName,
      lastName: schoolAdminInformation.lastName,
      displayName: schoolAdminInformation.displayName,
      userName: schoolAdminInformation.userName,
      schoolsIds: schoolAdminInformation.schoolsIds,
      email: schoolAdminInformation.email,
      phone: schoolAdminInformation.phone,
      addressLine1: schoolAdminInformation.addressLine1,
      addressLine2: schoolAdminInformation.addressLine2,
      country: schoolAdminInformation.country,
      state: schoolAdminInformation.state,
      city: schoolAdminInformation.city,
      zipCode: schoolAdminInformation.zipCode,
      birthDate: schoolAdminInformation.birthDate,
    };

    const information = schoolAdminInformation && Object.keys(orderedData);

    const typeToRender = {
      schoolAdminInformation: (
        <div className="user-information-container">
          {information &&
            information.map((key) => {
              return key === "profilePictureUrl" ? (
                <div className="counselor-prifle-image-container">
                  <div className="user-image-container">
                    <img
                      id="main-image"
                      className={`${
                        schoolAdminInformation[key] ? "" : "blank-image"
                      }`}
                      src={
                        schoolAdminInformation[key]
                          ? schoolAdminInformation[key]
                          : blankImage
                      }
                      alt="main"
                    />
                  </div>
                </div>
              ) : key === "schoolsIds" ? (
                <div key={key} className={`input-container w-50`}>
                  <div className="edit-input-label">Assigned Schools</div>
                  {schoolAdminInformation[key].map((element) => (
                    <div>{element}</div>
                  ))}
                </div>
              ) : (
                <div key={key} className={`input-container w-50`}>
                  <div className="edit-input-label">{key}</div>
                  {key === "approvalStatus" ? (
                    <div>
                      {schoolAdminInformation[key] < 3 ? "Denied" : "Approved"}
                    </div>
                  ) : (
                    <div>
                      {schoolAdminInformation[key] !== "null"
                        ? schoolAdminInformation[key]
                        : ""}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      ),
      schools: (
        <div className="classes-container">
          <div className="all-classes-container">
            <div className="class-item">
              <div>All Schools</div>
            </div>
            {renderSchools()}
          </div>
          <div className="all-classes-container">
            <div className="class-item">
              <div>Asigned School</div>
            </div>
            {renderSchoolAdminSchools()}
          </div>
        </div>
      ),
    };
    return typeToRender[type];
  };
  const renderSchoolAdminSchools = () => {
    const removeSchool = (data, index, id) => {
      const parsedItem = Object.assign({}, schoolAdminInformation);
      delete parsedItem["profilePictureUrl"];

      removeAssignedSchoolAdmin({
        schoolId: id,
        UserId: schoolAdminInformation.id,
      })
        .then((res) => {
          const newSchools = schools.slice();
          const newCounselorsSchools = schoolAdminSchool.slice();
          newSchools.push(data);
          newCounselorsSchools.splice(index, 1);
          setSchools(newSchools);
          setSchoolAdminSchool(newCounselorsSchools);

          handleModalOpen({
            title: `School Admin remove School`,
            description: "School removed successfully",
          });
        })
        .catch((err) => {
          handleModalOpen({
            title: `School Admin remove School`,
            description: "Something went wrong, try again later",
          });
        });
    };

    return schoolAdminSchool.map(
      (item, index) =>
      item && item.approvalStatus > 2 && (
          <div key={"student-" + index} className="class-item">
            <div>{item.name}</div>
            <div
              className="add-button"
              onClick={() => removeSchool(item, index, item.id)}
            >
              Remove School
            </div>
          </div>
        )
    );
  };

  const renderSchools = () => {
    const addSchool = (data, index, id) => {
      const parsedItem = Object.assign({}, schoolAdminInformation);
      delete parsedItem["profilePictureUrl"];
      assingSchoolAdmin({
        schoolId: id,
        UserId: schoolAdminInformation.id,
      })
        .then((res) => {
          const newSchools = schools.slice();
          const newCounselorsSchools = schoolAdminSchool.slice();
          newCounselorsSchools.push(data);
          newSchools.splice(index, 1);
          setSchools(newSchools);
          setSchoolAdminSchool(newCounselorsSchools);
          handleModalOpen({
            title: `School Admin Add School`,
            description: "School added successfully",
          });
        })
        .catch((err) => {
          handleModalOpen({
            title: `School Admin Add School`,
            description: "Something went wrong, try again later",
          });
        });
    };

    return schools.map(
      (item, index) =>
        item &&
        item.approvalStatus > 2 && (
          <div className="class-item">
            <div>{item.name}</div>
            <div
              className="add-button"
              onClick={() => addSchool(item, index, item.id)}
            >
              Assign School
            </div>
          </div>
        )
    );
  };

  return isLoading ? (
    <div className="loading-container">
      <CircularProgress color="secondary" />
    </div>
  ) : !counselorSelected && !schoolAdminInformation ? (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={"dashboard-list-container"}
    >
      {dataList.length === 0 && <div>No information available</div>}
      {dataList.length > 0 && (
        <ListItem className={`dashboard-list-item`}>
          <ListItemText className="list-item-text" primary={"Username"} />
          <ListItemText className="list-item-text" primary={"Last Name"} />

          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Approved"}
          />
          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Delete"}
            button
          />
          <ListItemText
            className="list-item-icon color-transparent"
            primary={"Edit"}
            button
          />
        </ListItem>
      )}
      {dataList.map((item) => (
        <ListItem
          onClick={() => {
            setSchoolAdminSelected(item.id);
            setIsLoading(true);
            getSchoolAdmin({ id: item.id })
              .then((res) => {
                setIsLoading(false);
                console.log(res.data.body, "es.data.body get school admin");
                setSchoolAdminInformation(res.data.body);
                setSchoolAdminSchool(res.data.body.schoolsIds);
              })
              .catch(() => {
                handleModalOpen({
                  title: "Error",
                  description: `Try again later`,
                });
                setIsLoading(false);
              });
          }}
          className={`dashboard-list-item`}
        >
          <ListItemText className="list-item-text" primary={item.userName} />
          <ListItemText className="list-item-text" primary={item.lastName} />

          <ListItemText className="list-item-icon" primary={""} />
          <ListItemText className="list-item-icon" primary={""} />

          <ListItemText primary={""} />
        </ListItem>
      ))}
    </List>
  ) : (
    <div className="information-container">
      <div className="action-buttons">
        <div
          className="user-action-buttons"
          onClick={() => {
            handleModalOpen({
              title: "Are you sure?",
              description: `You want to ${
                schoolAdminInformation.approvalStatus < 3
                  ? "Approve"
                  : "Disapprove"
              } ${schoolAdminInformation.userName}?`,
              acceptAction: (e) => {
                e.target.disabled = true;
                approveSchoolAdmin({
                  id: schoolAdminInformation.id,
                  approved: schoolAdminInformation.approvalStatus < 3,
                })
                  .then((res) => {
                    updateInfo();
                    if (res.data.success) {
                      handleModalOpen({
                        title: `School Admin ${
                          schoolAdminInformation.approvalStatus < 3
                            ? "Approved"
                            : "Disapproved"
                        }`,
                        description: `The School Admin ${
                          schoolAdminInformation.userName
                        } was ${
                          schoolAdminInformation.approvalStatus < 3
                            ? "approved"
                            : "disapproved"
                        }`,
                      });
                    } else {
                      handleModalOpen({
                        title: "There was an error while approving",
                        description: `Try again later`,
                      });
                    }
                  })
                  .catch((err) => {
                    handleModalOpen({
                      title: "There was an error while approving",
                      description: `Try again later`,
                    });
                  });
              },
            });
          }}
        >
          {schoolAdminInformation && schoolAdminInformation.approvalStatus < 3
            ? "Approve"
            : "Disapprove"}
        </div>
        <div
          className="user-action-buttons"
          onClick={() => {
            setIsLoading(true);
            getSchoolAdmin({ id: schoolAdminInformation.id })
              .then((res) => {
                setIsLoading(false);
                displayEditModal(res.data.body);
              })
              .catch(() => {
                handleModalOpen({
                  title: "Error",
                  description: `Try again later`,
                });
                setIsLoading(false);
              });
          }}
        >
          Edit
        </div>
        <div
          className="user-action-buttons"
          onClick={() => {
            handleModalOpen({
              title: "Are you sure?",
              description: `You want to delete ${schoolAdminInformation.userName} School Admin?`,
              acceptAction: (e) => {
                e.target.disabled = true;

                removeSchoolAdmin({ id: schoolAdminInformation.id })
                  .then((res) => {
                    updateInfo();
                    if (res.data.success) {
                      handleModalOpen({
                        title: "School Admin Deleted",
                        description: `The School Admin ${schoolAdminInformation.userName} was deleted`,
                      });
                    } else {
                      handleModalOpen({
                        title: "There was an error while deleting",
                        description: `Try again later`,
                      });
                    }
                  })
                  .catch((err) => {
                    handleModalOpen({
                      title: "There was an error while deleting",
                      description: `Try again later`,
                    });
                  });
              },
            });
          }}
        >
          Delete
        </div>
        <div
          className="user-action-buttons"
          onClick={() => {
            setIsLoading(true);
            setRenderType("schools");

            if (schoolAdminInformation.approvalStatus > 2) {
              getAllSchoolService()
                .then((res) => {
                  // const options = [];

                  const response = res.data.body.slice();
                  const schoolAdminScholls = [];

                  res.data.body.forEach((el) => {
                    schoolAdminInformation.schoolsIds.forEach((item) => {
                      if (el.id === item) {
                        const index = response.indexOf(el);
                        if (index > -1) {
                          schoolAdminScholls.push(el);
                          response.splice(index, 1);
                        }
                      }
                    });
                  });
                  console.log(res.data.body, "all schoollsdsd");

                  setIsLoading(false);
                  setSchools(response);
                  setSchoolAdminSchool(schoolAdminScholls);
                })
                .catch((err) => setIsLoading(false));
            } else {
              setInformation({
                title: "Assign School",
                descriptionComponent: (
                  <div>This counselor is not approved yet</div>
                ),
                witouthButtons: true,
              });
            }
          }}
        >
          Assign School
        </div>
      </div>
      {renderUserInformation(renderType)}
    </div>
  );
};

export default SchoolAdminList;
