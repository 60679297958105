import React, { Component, createRef } from "react";

import Modal from "@material-ui/core/Modal";

import {
  getAllTest,
  getAllSchoolService,
  studentGetAll,
  counselorGetAll,
  schoolAdminGetAll,
} from "../../services/schoolServices";
import { getAllWorkShops } from "../../services/workshopServices";

import { getAllChapters } from "../../services/chapterServices";

import { saveStudentList } from "../../redux/actions/studentActions";

import { saveTaskList } from "../../redux/actions/schoolActions";


import { connect } from "react-redux";

import LateralMenu from "../../components/Admin/LateralMenu";
import "./AdminDashboard.css";

import StudentList from "./StudentList";
import SchoolList from "./SchoolList";
import CounselorList from "./CounselorList";
import ChapterList from "./ChapterList";

import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "../../components/Header";
import CreateTest from "../../components/CreateTest";
import SelectChapter from "../SelectChapter";
import TaskList from "./TaskList";
import SchoolAdminList from "./SchoolAdminList";
import CreateChapter from "../Chapter/CreateChapter/CreateChapter";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import OrderChapter from "../../components/OrderChapters";
import CreateUsers from "./CreateUsers";
import ClassesList from "./ClassesList";
import CreateClass from "./CreateClass";
import CreateWorkshop from "./CreateWorkshop";
import WorkShopList from "./WorkShopList";
import CreateReport from "./CreateReport";

class AdminDashboard extends Component {
  state = {
    schoolId: "",
    apiSelected: "",
    arrayInput: "",
    data: {},
    dataList: null,
    userList: [],
    isLoading: false,
    modalBody: {},
    modalOpen: false,
    taskToEdit: null,
    itemToRender: null,
  };

  taskButtonRef = createRef();
  chapterButtonRef = createRef();

  counselorMenu = [
    {
      name: "Student",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getStudents();
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create Student",
              descriptionComponent: (
                <CreateUsers
                  handleModalOpen={this.handleModalOpen}
                  type="Student"
                />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "Reports",
      childrens: [
        {
          name: "Create",
          onClick: () => {
            this.setState({
              apiSelected: "Create Report",
              dataList: null,
              itemToRender: null,
            });
          },
        },
      ],
    },
  ];

  schoolAdminMenu = [
    {
      name: "Student",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getStudents();
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create Student",
              descriptionComponent: (
                <CreateUsers
                  handleModalOpen={this.handleModalOpen}
                  type="Student"
                />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "Counselors",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getCounselors();
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create Counselor",
              descriptionComponent: (
                <CreateUsers
                  handleModalOpen={this.handleModalOpen}
                  type="Counselor"
                />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "Reports",
      childrens: [
        {
          name: "Create",
          onClick: () => {
            this.setState({
              apiSelected: "Create Report",
              dataList: null,
              itemToRender: null,
            });
          },
        },
      ],
    },
  ];

  superAdminMenu = [
    {
      name: "Student",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getStudents();
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create Student",
              descriptionComponent: (
                <CreateUsers
                  handleModalOpen={this.handleModalOpen}
                  type="Student"
                />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "Counselors",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getCounselors();
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create Counselor",
              descriptionComponent: (
                <CreateUsers
                  handleModalOpen={this.handleModalOpen}
                  type="Counselor"
                />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "School Admin",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getSchoolAdmins();
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create School Admin",
              descriptionComponent: (
                <CreateUsers
                  handleModalOpen={this.handleModalOpen}
                  type="School Admin"
                />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "Schools",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getSchools();
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create School",
              descriptionComponent: (
                <CreateUsers
                  handleModalOpen={this.handleModalOpen}
                  type="School"
                />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "Classes",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.setState({
              apiSelected: "List Classes",
              itemToRender: null,
              dataList: [{ class1: "1" }],
            });
          },
        },
        {
          name: "Create",
          onClick: () => {
            this.setInformation({
              title: "Create Class",
              descriptionComponent: (
                <CreateClass handleModalOpen={this.handleModalOpen} />
              ),
              witouthButtons: true,
            });
          },
        },
      ],
    },
    {
      name: "Tasks",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getTask();
          },
        },
        {
          name: "Create Task",
          onClick: () => {
            this.setState({
              apiSelected: "Create Task",
              dataList: null,
              itemToRender: null,
            });
          },
        },
        {
          name: "Answered Tasks",
          onClick: () => {
            this.setState({
              apiSelected: "Answered Tasks",
              dataList: null,
              itemToRender: null,
            });
          },
        },
      ],
    },
    {
      name: "Chapters",
      childrens: [
        {
          name: "List",
          onClick: () => {
            this.getChapters("Chapter List");
          },
        },
        {
          name: "Create Chapter",
          onClick: () => {
            this.setState({
              apiSelected: "Create Chapter",
              dataList: null,
              itemToRender: null,
            });
          },
        },
        {
          name: "Chapter Order",
          onClick: () => {
            this.setState({
              apiSelected: "Chapter Order",
              dataList: null,
              itemToRender: null,
            });
          },
        },
      ],
    },
    {
      name: "Self Assessment",
      childrens: [
        {
          name: "List",
          onClick: () => this.getChapters("Self Assessment"),
        },
      ],
    },
    {
      name: "Workshop",
      childrens: [
        {
          name: "List",
          onClick: () => this.getWorkshops("Workshop List"),
        },
        {
          name: "Create",
          onClick: () => {
            this.setState({
              apiSelected: "Create Workshop",
              dataList: null,
              itemToRender: null,
            });
          },
        },
      ],
    },
    {
      name: "Reports",
      childrens: [
        {
          name: "Create",
          onClick: () => {
            this.setState({
              apiSelected: "Create Report",
              dataList: null,
              itemToRender: null,
            });
          },
        },
      ],
    },
  ];

  componentDidMount(){
    studentGetAll({})
      .then((res) => {
        console.log(res.data.body , "res.data.body didmount student")
        this.props.saveStudentList(res.data.body)
      })
      .catch((err) => {});

    getAllTest({})
      .then((res) => {
        this.props.saveTaskList(res.data.body)
      })
      .catch((err) => {});
  };

  renderMenu = (user) => {
    const menu = {
      SUPER_ADMIN: this.superAdminMenu,
      SCHOOL_COUNSELOR: this.counselorMenu,
      SCHOOL_ADMINISTRATOR: this.schoolAdminMenu,
    };

    return user ? menu[user] : [];
  };

  getStudents = () => {
    this.setState({
      apiSelected: "Student List",
      isLoading: true,
      itemToRender: null,
    });
    studentGetAll({})
      .then((res) => {
        this.setState({
          dataList: res.data.body,
          isLoading: false,
        });
        console.log(res.data.body);
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  };

  getSchoolAdmins = () => {
    this.setState({
      apiSelected: "School Admin List",
      isLoading: true,
      itemToRender: null,
    });

    schoolAdminGetAll({})
      .then((res) => {
        this.setState({
          dataList: res.data.body,
          isLoading: false,
        });
        console.log(res.data.body);
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  };

  getCounselors = () => {
    this.setState({
      apiSelected: "Counselors List",
      isLoading: true,
      itemToRender: null,
    });
    counselorGetAll({})
      .then((res) => {
        this.setState({
          dataList: res.data.body,
          isLoading: false,
        });
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  };

  getTask = () => {
    this.setState({ isLoading: true, itemToRender: null });
    getAllTest({})
      .then((res) => {
        console.log(res.data.body);
        this.setState({
          apiSelected: "Task List",
          isLoading: false,
          dataList: res.data.body,
        });
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  };

  getSchools = () => {
    this.setState({
      apiSelected: "Schools List",
      isLoading: true,
      itemToRender: null,
    });
    getAllSchoolService()
      .then((res) => {
        this.setState({
          dataList: res.data.body,
          isLoading: false,
        });
        console.log(res.data.body);
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  };

  getChapters = (apiSelected) => {
    this.setState({
      apiSelected: apiSelected,
      isLoading: true,
      itemToRender: null,
    });
    getAllChapters({})
      .then((res) => {
        this.setState({
          dataList: res.data.body,
          isLoading: false,
        });
        console.log(res.data.body);
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  };

  getWorkshops = (apiSelected) => {
    console.log("sending get workshopss");
    this.setState({
      apiSelected: apiSelected,
      isLoading: true,
      itemToRender: null,
    });
    getAllWorkShops({})
      .then((res) => {
        this.setState({
          dataList: res.data.body,
          isLoading: false,
        });
        console.log(res.data.body);
      })
      .catch((err) => this.setState({ isLoading: false, dataList: [] }));
  };

  handleCreateList = () => {
    if (this.state.apiSelected === "Student List") {
      return (
        <StudentList
          className={`${this.state.itemToRender ? "display-none" : ""}`}
          updateInfo={this.getStudents}
          handleModalOpen={this.handleModalOpen}
          dataList={this.state.dataList}
          setInformation={this.setInformation}
        />
      );
    } else if (this.state.apiSelected === "Counselors List") {
      return (
        <CounselorList
          className={`${this.state.itemToRender ? "display-none" : ""}`}
          updateInfo={this.getCounselors}
          handleModalOpen={this.handleModalOpen}
          dataList={this.state.dataList}
          setInformation={this.setInformation}
        />
      );
    } else if (this.state.apiSelected === "Task List") {
      return (
        <TaskList
          className={`${this.state.itemToRender ? "display-none" : ""}`}
          updateInfo={this.getTask}
          handleModalOpen={this.handleModalOpen}
          dataList={this.state.dataList}
        />
      );
    } else if (this.state.apiSelected === "School Admin List") {
      return (
        <SchoolAdminList
          className={`${this.state.itemToRender ? "display-none" : ""}`}
          updateInfo={this.getSchoolAdmins}
          handleModalOpen={this.handleModalOpen}
          dataList={this.state.dataList}
          setInformation={this.setInformation}
        />
      );
    } else if (this.state.apiSelected === "Chapter List") {
      return (
        <ChapterList
          className={`${this.state.itemToRender ? "display-none" : ""}`}
          updateInfo={this.getChapters}
          handleModalOpen={this.handleModalOpen}
          dataList={this.state.dataList}
          setInformation={this.setInformation}
          callback={() => this.chapterButtonRef.current.click()}
        />
      );
    } else if (this.state.apiSelected === "List Classes") {
      return <ClassesList />;
    } else if (this.state.apiSelected === "Self Assessment") {
      return (
        <ChapterList
          className={`${this.state.itemToRender ? "display-none" : ""}`}
          updateInfo={this.getChapters}
          handleModalOpen={this.handleModalOpen}
          dataList={this.state.dataList}
          isSelfAssessment
          setInformation={this.setInformation}
          callback={() => this.chapterButtonRef.current.click()}
        />
      );
    } else if (this.state.apiSelected === "Workshop List") {
      return (
        <WorkShopList
          className={`${this.state.itemToRender ? "display-none" : ""}`}
          updateInfo={() => this.getWorkshops("Workshop List")}
          handleModalOpen={this.handleModalOpen}
          dataList={this.state.dataList}
          setInformation={this.setInformation}
        />
      );
    }
    return (
      <SchoolList
        className={`${this.state.itemToRender ? "display-none" : ""}`}
        updateInfo={this.getSchools}
        handleModalOpen={this.handleModalOpen}
        dataList={this.state.dataList}
        setInformation={this.setInformation}
      />
    );
  };

  handleModalOpen = (modalBody) => {
    this.setState({ modalOpen: true, modalBody: modalBody });
  };

  setInformation = ({
    title,
    descriptionComponent,
    acceptAction,
    witouthButtons,
  }) => {
    this.setState({
      apiSelected: "",
      itemToRender: {
        title,
        descriptionComponent,
        acceptAction,
        witouthButtons,
      },
    });
  };

  renderItem = () => (
    <div>
      <h3>{this.state.itemToRender.title}</h3>
      {this.state.itemToRender.descriptionComponent}
      {this.state.itemToRender.witouthButtons ? null : (
        <button
          className="modal-button edit-button"
          onClick={this.state.itemToRender.acceptAction}
        >
          Save
        </button>
      )}
    </div>
  );

  rendenModalBody = ({
    title,
    description,
    descriptionComponent,
    acceptAction,
    cancelAction,
    witouthButtons,
  }) =>
    descriptionComponent ? (
      <div className="modal-body-container">
        {descriptionComponent}
        {!witouthButtons && (
          <div className="modal-button-container">
            {acceptAction && (
              <button className="modal-button" onClick={acceptAction}>
                yes
              </button>
            )}
            <div
              className="modal-button"
              onClick={() => {
                cancelAction && cancelAction();
                this.setState({ modalOpen: false });
              }}
            >
              close
            </div>
          </div>
        )}
      </div>
    ) : (
      <div className="modal-body">
        <div className="modal-title">{title}</div>
        <div>{description}</div>
        <div className="modal-button-container">
          {acceptAction && (
            <button className="modal-button" onClick={acceptAction}>
              yes
            </button>
          )}
          <div
            className="modal-button"
            onClick={() => {
              cancelAction && cancelAction();
              this.setState({ modalOpen: false });
            }}
          >
            close
          </div>
        </div>
      </div>
    );

  render() {
    return (
      <div className="admin-container">
        <DndProvider backend={HTML5Backend}>
          <Header />
          <LateralMenu
            taskRef={this.taskButtonRef}
            chapterRef={this.chapterButtonRef}
            data={this.renderMenu(this.props.userFirstRole)}
            title="Lateral menu"
          />
          {this.state.isLoading ? (
            <div className="loading-container">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <div className="right-container">
              <h3>
                {this.state.apiSelected !== "Task List" &&
                  this.state.apiSelected}
              </h3>
              {/* {this.state.renderData} */}
              {this.state.apiSelected === "Create Task" && (
                <CreateTest
                  callback={() => this.taskButtonRef.current.click()}
                  handleModalOpen={this.handleModalOpen}
                />
              )}
              {this.state.apiSelected === "Create Chapter" && (
                <CreateChapter
                  callback={() => this.chapterButtonRef.current.click()}
                  handleModalOpen={this.handleModalOpen}
                />
              )}
              {this.state.apiSelected === "Chapter Order" && (
                <OrderChapter
                  callback={() => this.chapterButtonRef.current.click()}
                />
              )}
              {this.state.apiSelected === "Answered Tasks" && (
                <SelectChapter fromAdmin={true} />
              )}

              {this.state.apiSelected === "Create Workshop" && (
                <CreateWorkshop handleModalOpen={this.handleModalOpen} />
              )}

              {this.state.apiSelected === "Create Report" && <CreateReport handleModalOpen={this.handleModalOpen} />}

              {this.state.itemToRender
                ? this.renderItem()
                : this.state.dataList && this.handleCreateList()}
                
              {this.state.dataItem &&
                this.state.apiSelected === this.state.dataItem.button &&
                this.handleDataInformation(
                  this.state.data,
                  this.state.dataIndex
                )}
            </div>
          )}

          <Modal
            open={this.state.modalOpen}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={() => this.setState({ modalOpen: false })}
            className="modal-container"
          >
            {this.rendenModalBody(this.state.modalBody)}
          </Modal>
        </DndProvider>
      </div>
    );
  }
}

const mapToStateToProps = () => ({});

export default connect(mapToStateToProps, { saveStudentList , saveTaskList })(AdminDashboard);
