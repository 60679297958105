import React from "react";
import RowBoostrap from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import facebook from "../../utils/images/facebook.png";
import instagram from "../../utils/images/instagram.png";
import logo from "../../utils/images/footerlogo.png";

// Footer styles
import "../../views/Home/Home.css";

const Footer = () => (
  <footer className="footer-container">
    <Container fluid className="px-4">
      <RowBoostrap>
        <Col xs={12} md={12} lg={3}>
          <Image src={logo} className="mb-4" />
        </Col>
        <Col className="footer-selection" xs={6} md={6} lg={2}>
          <ul className="unstyled-list">
            <li>
              <a href="#!">The Team</a>
            </li>
            <li>
              <a href="https://movementbeyou.com/store">Store</a>
            </li>
            <li>
              <a href="https://movementbeyou.com/blog">Blog</a>
            </li>
            <li>
              <a href="https://movementbeyou.com/donate">Donate</a>
            </li>
          </ul>
        </Col>
        <Col className="footer-selection" xs={6} md={6} lg={3}>
          <ul className="unstyled-list">
            <li>
              <a href="https://movementbeyou.com/contact">Contact</a>
            </li>
            <li>
              <a href="#!">Careers</a>
            </li>
            <li>
              <a href="#!">Resources</a>
            </li>
            <li>
              <a href="http://movementbe.org/">Staff Login</a>
            </li>
          </ul>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <a
            className="mb-0 owner-text"
            href="http://www.natehowardspeaks.com/"
          >
            <b>Nate Howard</b>
          </a>
          <p className="owner-text">Founder & Executive Director</p>
        </Col>
        <Col className="align-items-end d-flex social-networks" lg={1}>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="#!">
                <Image className="social-network-image" src={facebook} />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#!">
                <Image className="social-network-image" src={instagram} />
              </a>
            </li>
          </ul>
        </Col>
      </RowBoostrap>
      <p className="footer-text text-center mt-5">
        ©2020 MOVEMENT BRAVE ENTREPRENEUR. ALL RIGHTS RESERVED. MOVEMENT BRAVE
        ENTREPRENEUR IS A NONPROFIT, TAX-EXEMPT CHARITABLE ORGANIZATION (TAX
        IDENTIFICATION NUMBER 81-3315935) UNDER SECTION 501(C)(3) OF THE
        INTERNAL REVENUE CODE. DONATIONS ARE TAX-DEDUCTIBLE AS ALLOWED BY LAW.
      </p>
    </Container>
  </footer>
);

export default Footer;
