import axios from "./axiosInstance";
import queryString from "query-string";

export const createWorkshop = (params) =>
  axios.post("/Workshop/Create", params);

export const editWorkshop = (params) => axios.post("/Workshop/Edit", params);

export const deleteWorkShop = (params) =>
  axios.post(`/Workshop/Delete?${queryString.stringify(params)}`);

export const getWorkShop = (params) =>
  axios.post(`/Workshop/Get?${queryString.stringify(params)}`);

export const addUserToWorkshop = (params) =>
  axios.post("/Workshop/AddUserRange", params);

export const getAllWorkShops = (params) =>
  axios.post("/Workshop/GetAll", params);

export const getMyWorkShops = (params) =>
  axios.post("/Workshop/GetMyWorkShops", params);

export const workShopAddClasses = (params) =>
  axios.post("/Workshop/AddClasses", params);

export const workShopRemoveClasses = (params) =>
  axios.post("/Workshop/RemoveClasses", params);
