import React, { useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { addUserToWorkshop } from "../../services/workshopServices";

const InviteStudentModal = ({ id, handleModalOpen, updateInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailToInviteList, setEmailToInviteList] = useState([]);
  const handleChange = (text) => {
    setEmailToInvite(text);
    if (emailError) {
      setEmailError(false);
    }
  };

  const inviteByMail = () => {
    setIsLoading(true);
    addUserToWorkshop({
      id,
      invitationsToSend: emailToInviteList,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.data.success) {
          updateInfo(emailToInvite);

          handleModalOpen({
            title: "Invite Students",
            description: `Invitations sent successfully!`,
          });
        } else {
          handleModalOpen({
            title: "Error",
            description: `Try again later`,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        handleModalOpen({
          title: "Error",
          description: `Try again later`,
        });
      });
  };

  const addInviteList = () => {
    const re = /\S+@\S+\.\S+/;
    const isValid = re.test(emailToInvite);
    if (isValid) {
      setEmailToInviteList((state) => [
        ...state,
        {
          WorkShopId: id,
          Email: emailToInvite,
          Role: 1,
        },
      ]);
      setEmailToInvite("");
    } else {
      setEmailError(true);
    }
  };

  return isLoading ? (
    <div className="loading-container invite-by-mail-modal">
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div className="invite-by-mail-modal">
      <div className="invite-title">Invite Student</div>
      <div className="invite-input-container">
        <input
          className={`text-input`}
          name={"Email"}
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          placeholder={"Email"}
          value={emailToInvite}
        />
        <div className="invite-add-button" onClick={addInviteList}>
          ADD
        </div>
      </div>
      {emailError && <div className="error-label">Email invalid</div>}
      <div className="email-list-container">
        {emailToInviteList.map((item) => (
          <div key={item.Email}>{item.Email}</div>
        ))}
      </div>

      <div className="invite-button" onClick={inviteByMail}>
        Invite
      </div>
    </div>
  );
};

export default InviteStudentModal;
