import React, { Component } from "react";
import RichTextEditor from "react-rte";
import './TestEditor.css'

class TestEditor extends Component {
  state = {
    // value: RichTextEditor.createEmptyValue()
    value: RichTextEditor.createValueFromString(this.props.value, 'html')
  };

  onChange = value => {
    this.setState({ value });

    
    this.props.handler(value.toString("html"), this.props.index)
  };

  render() {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
      ]
    };

    return (
      <div  style={{marginBottom: '10px', marginTop: '10px'}}>
        <RichTextEditor autoFocus={this.props.autoFocus} toolbarClassName="toolbar-styling" toolbarConfig={toolbarConfig} value={this.state.value} onChange={this.onChange} onClick={this.props.onClick}/>
      </div>
    );
  }
}

export default TestEditor;
