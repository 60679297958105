import React, { Fragment, useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ITEM_TYPE from "./types";
import eye from "../../../utils/images/eye.png";
import { useDispatch } from "react-redux";
import { setTask } from "../../../redux/actions/schoolActions";
import Modal from "@material-ui/core/Modal";
import Task from "../../Task";

const Item = ({ item, index, moveItem, showEye }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalBody, setModalBody] = useState(null);

    const dispatch = useDispatch();
    const ref = useRef(null);

    const handleModalOpen = (modalBody) => {
        setModalBody(modalBody);
        setModalOpen(true);
    };

    const renderModalBody = ({
        title,
        description,
        descriptionComponent,
        acceptAction,
        cancelAction,
        witouthButtons,
    }) =>
        descriptionComponent ?
            <div className="modal-body-container">
                {descriptionComponent}
                {!witouthButtons && (
                    <div className="modal-button-container">
                        {acceptAction && (
                            <button className="modal-button" onClick={acceptAction}>
                                yes
                            </button>
                        )}
                        <div
                            className="modal-button"
                            onClick={() => {
                                cancelAction && cancelAction();
                                setModalOpen(false);
                            }}
                        >
                            close
                        </div>
                    </div>
                )}
            </div> :
            <div className="modal-body">
                <div className="modal-title">{title}</div>
                <div>{description}</div>
                <div className="modal-button-container">
                    <div
                        className="modal-button"
                        onClick={() => {
                            cancelAction && cancelAction();
                            this.setState({ modalOpen: false });
                        }}
                        >
                        close
                    </div>
                </div>
            </div>

    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return
            }

            const hoveredRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
            const mousePosition = monitor.getClientOffset();
            const hoverClientY = mousePosition.y - hoveredRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: ITEM_TYPE, ...item, index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    return (
        <Fragment>
            <div
                ref={ref}
                style={{ opacity: isDragging ? 0 : 1, display: 'flex', justifyContent: 'space-between' }}
                className={"task-item"}
            >
                <div className={"item-title"} >{item.title.replace(/<[^>]+>/g, '')}</div>
                {
                    showEye &&
                    <img
                        src={eye}
                        style={{ width: '20px', height: '20px' }}
                        alt="eye"
                        onClick={() => {
                            dispatch(
                                setTask({
                                    template: item.template,
                                    templateId: item.id,
                                    isComplete: true,
                                })
                            );
                            handleModalOpen({
                                title: "Task",
                                descriptionComponent: <Task fromAdmin={true} />,
                            });
                        }} />
                }
            </div>

            <Modal
                open={modalOpen}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                onClose={() => setModalOpen(false)}
                className="modal-container"
            >
                {modalOpen && renderModalBody(modalBody)}
            </Modal>
        </Fragment>
    );
};

export default Item;