import { Button, Modal, ModalBody } from "react-bootstrap";
import { useState } from "react";
import React from "react";
import "./AcceptInvitation.css";

const AcceptInvitationModal = ({
  show,
  title,
  description,
  onAccept,
}) => (
  <Modal size="lg" show={show} onHide={onAccept}>
    <Modal.Header className="invitation-modal-header" closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  
    <Modal.Footer>
      <Button variant="light" onClick={onAccept}>
        Accept
      </Button>
    </Modal.Footer>
  </Modal>
);
export default AcceptInvitationModal;
