import {
  CREATE_SCHOOL_ATTEMPT,
  CREATE_SCHOOL_FAILED,
  CREATE_SCHOOL_SUCCESS,
  GET_CREATE_TOKEN,
  SAVE_SCHOOL,
  SAVE_SCHOOL_FAILED,
  SET_TASK,
  SAVE_TASK_LIST
} from "./action-types/app-action-types";
import {
  createSchoolService,
  getCreateTokenService,
  getSchoolService
} from "../../services/schoolServices";

const createSchool = () => ({ type: CREATE_SCHOOL_ATTEMPT });
const createToken = (payload) => ({ type: GET_CREATE_TOKEN, payload });

const getSchoolFailed = () => ({ type: SAVE_SCHOOL_FAILED });
const saveSchool = (payload) => ({ type: SAVE_SCHOOL, payload });

export const setTask = (payload) => ({ type: SET_TASK, payload });
export const saveTaskList = (payload) => ({ type: SAVE_TASK_LIST, payload });

export const createFailed = (payload) => ({
  type: CREATE_SCHOOL_FAILED,
  payload,
});

export const createSuccess = (payload) => ({
  type: CREATE_SCHOOL_SUCCESS,
  payload,
});

export const createSchoolAttempt = (params, token) => async (dispatch) => {
  try {
    dispatch(createSchool());
    const response = await createSchoolService(params, token);
    const userResponse = response.data.body;

    if (response.data.success) {
      localStorage.setItem("token", userResponse.token);
      dispatch(
        createSuccess({
          approvalStatus: userResponse.approvalStatus,
          displayName: userResponse.displayName,
          firstName: userResponse.firstName,
          lastName: userResponse.lastName,
          phone: userResponse.phone,
        })
      );
    } else {
      dispatch(createFailed(response.data.errorMessage));
    }
  } catch (err) {
    dispatch(createFailed(err));
  }
};

export const getCreateToken = (params) => async (dispatch) => {
  try {
    const response = await getCreateTokenService(params);
    if(response.data.success) {
      dispatch(createToken(response.data.body));
    } else {
      dispatch(createFailed(response.data.errorMessage));
    }
  } catch (err) {
    dispatch(createFailed(err));
  }
};

export const getSchool = (params) => async (dispatch) => {
  try {
    const response = await getSchoolService(params);
    if(response.data.success) {
      dispatch(saveSchool(response.data.body));
    } else {
      dispatch(getSchoolFailed(response.data.errorMessage));
    }
  } catch (err) {
    dispatch(getSchoolFailed(err));
  }
};
