import React, { Component } from 'react';
import { Route, Switch} from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import LoginRoute from './components/LoginRoute'
import RegisterRoute from './components/RegisterRoute';
import SchoolAdmin from './views/SchoolAdmin'
import Register from "./views/Register";
import Login from "./views/Login";
import CreateSchool from "./views/CreateSchool";
import Task from "./views/Task";
import SelectChapter from "./views/SelectChapter";
import OrderChapters from './components/OrderChapters';
import Chapters from './components/Chapters';
import MovementBeLand from './views/MovementBeLand';
import AutoEvaluation from './views/AutoEvaluation';
import Profile from './views/Profile';
import AcceptInvitation from "./views/AcceptInvitation";
import MyTests from './views/MyTests';

import MyWorkShops from "./views/MyWorkShops";

import SuperAdmin from './views/SuperAdmin';
import Apis from './views/Apis';
import { connect } from 'react-redux';

import "./App.css";
import { Redirect } from 'react-router-dom';
import Home from "./views/Home";
import AdminDashboard from "./views/AdminDashboard";

class App extends Component {
  render() {
    return (
          <Switch>
              <LoginRoute exact path="/login" component={Login}/>
              <RegisterRoute exact path="/register" component={Register}/>
              <Route exact path="/" component={Home}/>
              {/* <Route exact path="/my-answered-tasks" component={Component}/> */}

              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/task" component={Task}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/task" component={Task}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} path="/task/:id" component={Task}/>
              {/* This route should only be seen by the super admin */}
              <Route exact path="/superadmin" component={SuperAdmin}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/select-chapter" component={SelectChapter}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/chapters" component={Chapters}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/land" component={MovementBeLand}/>
              {/* <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/autoevaluation/:id" component={AutoEvaluation}/> */}
              <Route exact path="/autoevaluation/:id" component={AutoEvaluation}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/order-chapters" component={OrderChapters}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/your-profile" component={Profile}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/my-workshops" component={MyWorkShops}/>
              <ProtectedRoute admitedUsers={['STUDENT', 'SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/my-tests" component={MyTests}/>

              <ProtectedRoute admitedUsers={['SUPER_ADMIN']} exact path="/answered-tasks" component={SelectChapter}/>
              <Route exact path="/apis" component={Apis}/>
              <Route exact path="/schooladmin" component={SchoolAdmin}/>
              <Route exact path="/createSchool" component={CreateSchool}/>
              <Route exact path="/invitation/:token" component={AcceptInvitation}/>
              <ProtectedRoute admitedUsers={['SCHOOL_COUNSELOR', 'SCHOOL_ADMINISTRATOR', 'SUPER_ADMIN']} exact path="/testadmin" component={AdminDashboard}/>

              <ProtectedRoute component={Login} />
          </Switch>
    );
  }
}

class Empty extends Component {
  render() {
    return (
      <>
        <Redirect to={{ pathname: '/task' }} />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state.appState,
    login: state.loginReducer,
  };
};

const mapDispatchToProps= (dispatch)=>{
  return{
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
